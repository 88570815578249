import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SizeTableContainer from "../../../components/Common/SizeTableContainer"
import {
  Card,
  CardBody,
  Col,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Row,
  Button,
  Label,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import DeleteModel from "common/deleteModel/deleteModel"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"

function Plan() {
  document.title = `Plans | ${localStorage.getItem("ShopName")}`
  const { deleteApi, getApi, postApi, putApi } = useApi()
  // useStates
  let { pathname } = useLocation()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState("")
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [planId, setPlanId] = useState()

  // plan list api
  const planApi = () => {
    setIsLoading(true)
    getApi("plan/list", "").then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // plan list api useEffect
  useEffect(() => {
    planApi()
  }, [])

  // Delete plan handler
  const handleDelete = planId => {
    if (planId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this plan permanently? ",
      }).then(response => {
        if (response == true) {
          deleteApi(`plan/delete?id=${planId}`).then(({ data, status }) => {
            if (status === 200) {
              const updatedArray = tableData
                .map(obj => (obj.Id !== planId ? obj : null))
                .filter(Boolean)
              setTableData(updatedArray)
              toast.success("Sucessfully deleted", {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              ErrorModel.errormodel({
                title: "Verify Plan",
                text: data,
              })
            }
          })
        }
      })
    }
  }

  // cancle handler
  const handleCancle = () => {
    toggle()
  }

  // Update plan handler
  const handleEditPlan = planId => {
    if (planId) {
      setIsEdit(true)
      setModal(!modal)
      setPlanId(planId)
      getApi(`Plan/details?id=${planId}`, "").then(({ data, status }) => {
        setEditData(data)
      })
    }
  }

  // Create plan handler
  const handlePlanClick = () => {
    setIsEdit(false)
    toggle()
  }

  // plan Validations
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: isEdit ? editData.Name : "",
      days: isEdit ? editData.Days : "",
      amount: isEdit ? editData.Amount : "",
      remarks: isEdit ? editData.Remarks : "",
      discount: isEdit ? editData.Discount : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("The Name is required"),
      days: Yup.number().required("The days is required"),
      amount: Yup.number().required("The amount is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (values) {
        isEdit
          ? putApi("plan/update", {
            id: planId,
            name: values.name,
            days: values.days,
            amount: values.amount,
            discount: values.discount ? values.discount : null,
            remarks: values.remarks ? values.remarks : "",
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("Sucessfully updated", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              planApi()
              validation.handleReset()
            }
          })
          : postApi("plan/create", {
            name: values.name,
            days: values.days,
            amount: values.amount,
            discount: values.discount ? values.discount : null,
            remarks: values.remarks,
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("Sucessfully created", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              planApi()
              validation.handleReset()
            }
          })
      }
    },
  })

  // popup box handler
  const toggle = () => {
    setModal(!modal)
    validation.handleReset()
  }

  // plan active handler
  const handleSwitch = id => {
    if (id) {
      getApi(`Plan/active?id=${id}`).then(({ data, status }) => {
        if (status === 200) {
          toast.success("Sucessfully Changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Days",
        accessor: "Days",
        width: "6%",
      },
      {
        Header: "Amount",
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Amount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Discount",
        accessor: "Discount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Discount == null ? "0.00" : row.original.Discount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Active
          </div>
        ),
        width: "7%",
        accessor: "IsActive",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              defaultChecked={row.original.IsActive}
              onClick={e => {
                handleSwitch(row.original.Id)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "7%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li>
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEditPlan(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`edittooltip${row.original.Id}`}
                >
                  Edit
                </UncontrolledTooltip>
              </li>

              <li>
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.Id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SizeTableContainer
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isPlanList={true}
              handlePlanClick={handlePlanClick}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="small"
              style={{ width: "350px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {isEdit ? "Update Plan" : "Create Plan"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Name
                      </Label>
                      <Input
                        name="name"
                        placeholder="Name"
                        type="text"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <Row>
                      <Col md={4}>
                        <FormGroup className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Days
                          </Label>
                          <Input
                            name="days"
                            placeholder="Days"
                            type="number"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.days || ""}
                            invalid={
                              validation.touched.days && validation.errors.days
                                ? true
                                : false
                            }
                          />
                          {validation.touched.days && validation.errors.days ? (
                            <FormFeedback type="invalid">
                              {validation.errors.days}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Amount
                          </Label>
                          <Input
                            name="amount"
                            placeholder="Amount"
                            type="number"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            invalid={
                              validation.touched.amount &&
                                validation.errors.amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amount &&
                            validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                          >
                            Discount
                          </Label>
                          <Input
                            name="discount"
                            placeholder="discount"
                            type="number"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.discount || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03">Remark</Label>
                      <Input
                        name="remarks"
                        placeholder="Type your remak here"
                        type="textarea"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.remarks || ""}
                        invalid={
                          validation.touched.remarks &&
                            validation.errors.remarks
                            ? true
                            : false
                        }
                      />
                      {validation.touched.remarks &&
                        validation.errors.remarks ? (
                        <FormFeedback type="invalid">
                          {validation.errors.remarks}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>

                    <div className="text-end">
                      {isEdit ? (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          onClick={handleCancle}
                        >
                          Cancle
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          type="reset"
                        >
                          Reset
                        </Button>
                      )}

                      <>
                        {formSumitLoading ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary btn-sm "
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

Plan.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Plan
