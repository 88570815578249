// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
} from "reactstrap"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import ProductVerificationTable from "./ProductVerificationTable"

function ProductVerificationList() {
    let { pathname } = useLocation()
    const { postApi, getApi } = useApi()
    document.title = `Products | ${localStorage.getItem("ShopName")}`
    const [TotalRecords, setTotalRecords] = useState(0)

    const navigate = useNavigate()

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [displayStart, setDisplayStart] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [categoryId, setCategoryId] = useState(0)
    const [verificationStatus, setVerificationStatus] = useState('0')
    const [screeLoad, setscreeLoad] = useState(true)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getApi(`api/Categories/all`).then(({ data, status }) => {
            if (status === 200) {
                setCategories(data)
            }
        })
    }, [])

    // filterData for Product
    const filterData = textFilter => {
        setSearchText(textFilter)
    }

    // pagination
    const pagePerSize = (pageSize, pagePerIndex, serchValue, statusChange, categoryChange) => {
        setSearchText(serchValue == undefined ? "" : serchValue)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setRowsPerPage(pageSize)
        setCategoryId(categoryChange)
        setVerificationStatus(statusChange)
    }

    const productVerificationList = () => {
        setIsLoading(true)
        setscreeLoad(true)
        postApi("api/Product/product-verification", {
            categoryId: categoryId,
            verificationStatus: verificationStatus,
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText,
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.productList)
                setTotalRecords(data.totalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })
    }

    useEffect(() => {
        productVerificationList()
    }, [categoryId, displayStart, verificationStatus, rowsPerPage, searchText])

    // User address redirect google map
    const handleRedirectGoogleMap = (id, latitude, longitude) => {
        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(url, '_blank');
    }

    // product edit Id handler
    const editProductVerification = productId => {
        if (productId) {
            navigate(routes.productVerificationUpdate, {
                state: {
                    productId,
                },
            })
        }
    }

    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Picture",
                accessor: "mainImageUrl",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                className=""
                                style={{ textAlign: 'center' }}
                                src={row.original.mainImageUrl}
                                height={40}
                                width={40}
                            />
                        </div>
                    )
                },
            },
            {
                Header: "Title",
                accessor: "title",
                Cell: ({ row }) => (
                    <span>
                        {row.original.title ? row.original.title : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Full Name",
                accessor: "fullName",
                Cell: ({ row }) => (
                    <span>
                        {row.original.fullName ? row.original.fullName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Contact#",
                accessor: "contactNo",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.contactNo ? row.original.contactNo : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Category",
                accessor: "categoryName",
                width: "10%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.categoryName ? row.original.categoryName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Shop Name",
                accessor: "shopdName",
                Cell: ({ row }) => (
                    <span>{row.original.shopdName ? row.original.shopdName : "N/A"}</span>
                ),
            },
            {
                Header: "Product For",
                width: "5%",
                Cell: ({ row }) => (
                    <span>{row.original.forRent ? "Rent" : "Sale"}</span>
                ),
            },
            {
                Header: "Amount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div>
                            <i className="mdi mdi-currency-inr"></i>
                            {row?.original?.forRent ? row?.original?.rent?.toFixed(2) || "0.00" : row?.original?.salePrice?.toFixed(2) || "0.00"}
                        </div>
                    )
                },
            },
            {
                Header: "Status",
                accessor: "verificationStatus",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div>
                            <div style={{ color: row.original.verificationStatus == "Approved" ? "#5D9C59" : row.original.verificationStatus == "Rejected" ? "#F24C3D" : "#d5993d", fontWeight: "600" }}>
                                {row.original.verificationStatus}
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            {/* {row.original.verificationStatus == "Approved" ?
                                (
                                    <li className="mytooltip">
                                        <i
                                            className="far fa-check-square"
                                            style={{ cursor: "not-allowed", color: "#808080b3", fontSize: "14px" }}
                                            id={`verificationtooltip${row.original.id}`}
                                        >
                                        </i>

                                    </li>
                                ) : ( */}
                            <li className="mytooltip1">
                                <i
                                    className="fas fa-map-marker-alt"
                                    style={{ color: "#1f3fd2e8", cursor: "pointer", fontSize: "15px" }}
                                    id={`verificationtooltip${row.original.id}`}
                                    onClick={() => handleRedirectGoogleMap(row.original.id, row.original.latitude, row.original.longitude)}
                                >
                                    <span className="mytext1">{row.original?.address}</span>
                                </i>

                            </li>
                            <li className="mytooltip">
                                <i
                                    className="  fas fa-edit btn-edit"
                                    style={{ fontSize: "15px" }}
                                    id={`edittooltip${row.original.id}`}
                                    onClick={() => editProductVerification(row.original.id)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )
    // redirect create product handler
    const handleUserClicks = () => {
        navigate(routes.productVerificationUpdate)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screeLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <ProductVerificationTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCreateProduct={false}
                            handleUserClick={handleUserClicks}
                            customPageSize={10}
                            className="custom-header-css"
                            filterData={filterData}
                            pagePerSize={pagePerSize}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            categories={categories}
                        />
                    </CardBody>
                </Card>
            </div>
        </div >
    )
}
ProductVerificationList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default ProductVerificationList