import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { dateFormat } from "pages/Common"
import CartTable from "pages/Transiction/CartTable"
import useApi from "common/ApiMiddlewere"
import toast, { Toaster } from "react-hot-toast"

function AddCartPage({ isChangeTab }) {
  // useStates
  const { getApi } = useApi()

  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  // cart list API useEffect
  useEffect(() => {
    setIsLoading(true)
    getApi(`Booking/cart-list`, "").then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [isChangeTab])

  const handleDelete = id => {
    deleteApi(`Booking/delete-cart?id=${id}`).then(({ data, status }) => {
      if (status === 200) {
        const updatedArray = tableData
          .map(obj => (obj.Id !== id ? obj : null))
          .filter(Boolean)
        setTableData(updatedArray)
      }
    })
  }

  // Cart table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "Name",
      },
      {
        Header: "Product Code",
        accessor: "ProductCode",
        width: "6%",
      },
      {
        Header: "Delivery Date",
        accessor: "DeliveryDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.DeliveryDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return Date",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "Action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li>
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.Id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  return (
    <>
      <CartTable
        columns={columns}
        data={tableData}
        customPageSize={10}
        isLoading={isLoading}
        className="custom-header-css"
      />
    </>
  )
}
AddCartPage.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AddCartPage
