import { saveAs } from 'file-saver';
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export function exportDataExcel(sheetData, mainTitle, widthObj, extention, totalAmount,TotalDiscount) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });

        sheet1.cell("A1").value(mainTitle);
        const titleRange = sheet1.range(`A1:${endColumn}1`);
        titleRange.merged(true);
        titleRange.value(mainTitle);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });
        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })
        if (totalAmount != "" && totalAmount > 0) {
            const totalAmountRow = sheetData.length + 2;
            sheet1.cell(`D${totalAmountRow}`).value("Total").style({
                bold: true,
            });
            sheet1.cell(`E${totalAmountRow}`).value(TotalDiscount).style({
                bold: true,
            });
            sheet1.cell(`F${totalAmountRow}`).value(totalAmount);
            sheet1.cell(`F${totalAmountRow}`).value(totalAmount).style({
                bold: true,
            });
        }



        // for (let i = 1; i < sheetData.length; i++) {
        //     const row = sheetData[i];
        //     const totalAmount = row.slice(1).reduce((sum, value) => sum + value, 0);
        //     const totalAmountColumn = String.fromCharCode(65 + totalColumns); // Next column after the data columns
        //     sheet1.cell(`${totalAmountColumn}${i + 2}`).value(totalAmount);
        // }

        // for (let i = 1; i < sheetData.length; i++) {
        //     const row = sheetData[i];
        //     const totalAmount = row.slice(1).reduce((sum, value) => sum + value, 0);
        //     sheet1.cell(`${endColumn}${i + 2}`).value(totalAmount);
        // }


        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}