// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import TableList from "../../components/Common/TableList"

import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"

function SubscriptionViewTable({ userId }) {
  // useState
  const [tableData, setTableData] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { getApi } = useApi()

  // useEffect product List
  useEffect(() => {
    setIsLoading(true)
    if (userId) {
      getApi(`SubscriptionLogs/subscription-logs?userId=${userId}`).then(
        ({ data, status }) => {
          if (status === 200) {
            setIsLoading(false)
            setTableData(data)
          } else {
            setIsLoading(false)
            setError(data)
          }
        }
      )
    }
  }, [])

  // product view table
  const columns = useMemo(
    () => [
      {
        Header: "Amount",
        accessor: "Amount",
        width: "6%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Amount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Discount",
        accessor: "Discount",
        width: "6%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Discount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Activation Date",
        accessor: "ActivationDate",
        width: "6%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ActivationDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.ActivationDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
          </p>
        ),
      },
      {
        Header: "Start Date",
        accessor: "StartDate",
        width: "5%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.StartDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.StartDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "End Date",
        accessor: "EndDate",
        width: "6%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.EndDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.EndDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks}`}
          >
            {row.original.Remarks == null || row.original.Remarks == ""
              ? "N/A"
              : row.original?.Remarks?.length > 30
              ? `${row.original.Remarks.substr(0, 30)}...`
              : row.original.Remarks}
          </p>
        ),
      },
    ],
    []
  )

  //meta title

  return (
    <div>
      <div>
        <TableList
          columns={columns}
          data={tableData}
          isGlobalFilter={true}
          isCategoryList={true}
          customPageSize={10}
          isLoading={isLoading}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
SubscriptionViewTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SubscriptionViewTable
