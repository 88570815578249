import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { routes } from "routes/path"

const Breadcrumb = props => {
  const { parent, breadcrumbItem } = props
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18 text-capitalize">
            {breadcrumbItem}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbItem == "Dashboard" || breadcrumbItem == 'Available' || breadcrumbItem == "User Profile" ? null :
                <BreadcrumbItem>
                  <Link to={routes.dashboard}>Dashboard</Link>
                </BreadcrumbItem>}
              {parent && (
                <BreadcrumbItem>
                  <Link to={props.parentLink}>{parent}</Link>
                </BreadcrumbItem>
              )}
              {breadcrumbItem == "Dashboard" || breadcrumbItem == 'Available' || breadcrumbItem == "User Profile" ? null :
                <BreadcrumbItem active>
                  <Link to={""}>{breadcrumbItem}</Link>
                </BreadcrumbItem>}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
