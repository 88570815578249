import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import TableList from "components/Common/TableList"
import useApi from "common/ApiMiddlewere"
import { dateFormat } from "pages/Common"

const FollowupDetails = ({ id }) => {
  const { getApi } = useApi()
  const [followData, setfollowData] = useState([])
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    setisLoading(true)
    getApi(`Inquiry/view-followup?id=${id}`).then(({ data, status }) => {
      setisLoading(false)
      if (status === 200) {
        setfollowData(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "Date",
        // Cell: ({ row }) => (
        //   <p className=" mb-0">{row.original.date.split("T")[0]}</p>
        // ),
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.Date == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.Date.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "Next Followup",
        accessor: "NextFollowup",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.NextFollowup == null
              ? "N/A"
              : dateFormat(
                row.original.NextFollowup.split("T")[0],
                "dd/MM/yyyy"
              )}
          </p>
        ),
      },
      {
        Header: "Comment",
        accessor: "Comment",
        width: 250,
      },
    ],
    [followData]
  )

  return (
    <>
      <TableList
        isLoading={isLoading}
        columns={columns}
        data={followData}
        isGlobalFilter={true}
        isCategoryList={true}
        customPageSize={10}
        className="custom-header-css"
      />
    </>
  )
}

FollowupDetails.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default FollowupDetails
