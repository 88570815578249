/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import TableListProduct from "./TableListBooking"
import { dateFormat } from "../Common"

import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import jsPDF from "jspdf"
import { font } from "pages/Reports/Booking/font"
import { InvoiceHtml } from "pages/Reports/Booking/invoicePDF"
import BookingBreadcrumb from "components/Common/BookingBreadcrumb"
import breadcrumbs from "common/Breadcrumbs"
import { routes } from "routes/path"
import TableList from "components/Common/TableList"

let advance = 0
let discount = 0
function CreateBooking() {
  document.title = `Booking | ${localStorage.getItem("ShopName")}`

  const { deleteApi, getApi, postApi, putApi } = useApi()
  let { pathname } = useLocation()
  const [flag, setFlag] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [allowPrintButton, setAllowPrintButton] = useState(false)
  const { state } = useLocation() // navigate data
  const [screeLoad, setscreeLoad] = useState(true)
  const [ProductDetails, setProductDetails] = useState([]) // product lookup
  const [TimeSolteDetails, setTimeSolteDetails] = useState([]) // timeslote lookup
  const [isLoading, setisLoading] = useState(false)
  const [tableRent, setTableRent] = useState(0)
  const [totalRent, settotalRent] = useState(0)
  const [remarkProductId, setRemarkProductId] = useState(0)
  const [productNote, setProductNote] = useState("")
  const [cartList, setCartList] = useState([])
  const [cartStatus, setCartStatus] = useState([])
  const [cartConfirm, setCartConfirm] = useState(false)

  // table state management
  const [tableData, setTableData] = useState(
    state ? state.data.BookingProductList : []
  )
  const [rentList, setRentList] = useState(
    state ? state.data.BookingProductList : []
  )

  useEffect(() => {
    setscreeLoad(true)
    getApi("TimeSlot/time-slot-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        setTimeSolteDetails(data)
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
    getApi("Product/product-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        setProductDetails(data)
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
    postApi("Setting/setting-by-keys", {
      Key: ["Save_And_Print_Booking"],
    }).then(({ data, status }) => {
      setscreeLoad(false)
      if (status === 200) {
        data.map(item => {
          if (item.Key === "Save_And_Print_Booking" && item.Value === "1") {
            setAllowPrintButton(true)
          } else {
            setAllowPrintButton(false)
          }
        })
      } else {
        setscreeLoad(false)
      }
    })

    advance = validation.values.Advance
    discount = validation.values.discount
    handleTotalRent(advance, discount, null)
  }, [])

  const toggle = () => {
    setModal(!modal)
  }
  const toggle1 = () => {
    setModal1(!modal1)
  }

  const handaleConfirm = () => {
    setCartConfirm(true)
    toggle1()
  }

  // Booking Validations
  const validation = useFormik({
    enableReinitialize: false,

    initialValues: {
      id: state ? state?.data.Id : 1,
      BillNo: state ? state?.data.BillNo : "",
      Name: state ? state?.data.Name : "",
      remark: state ? state?.data.Remarks : "",
      Rent: state
        ? state?.data.TotalRent - state?.data.Advance - state?.data.Discount
        : 0,
      Advance: state ? state?.data.Advance : 0,
      ContactNo1: state ? state?.data.ContactNo1 : "",
      ContactNo2: state ? state?.data.ContactNo2 : "",
      discount: state ? state?.data.Discount : 0,
      Address: state ? state?.data.Address : "",
      totalAmount: state
        ? state?.data.TotalRent - state?.data.Advance - state?.data.Discount
        : 0,
    },
    validationSchema: Yup.object({
      ContactNo1: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
        .required("Contact Number is required"),
      ContactNo2: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
        .required("Contact Number is required"),
      Name: Yup.string().required("The Name is required"),
      Address: Yup.string().required("The Address is required"),
      Rent: Yup.number().min(1, "Value must be greater than zero"),
      totalAmount: Yup.string().required("The Rent is required"),
    }),
    onSubmit: values => {
      let bookingItem = []
      rentList.map(item => {
        bookingItem.push({
          id: item.Id ? item.Id : 0,
          ProductId: parseInt(item.ProductId),
          Rent: item.Rent,
          DeliveryDate: item.DeliveryDate,
          ReturnDate: item.ReturnDate,
          ReturnTimeSlot: item.ReturnTimeSlot ? item.ReturnTimeSlot : "",
          DeliveryTimeSlot: item.DeliveryTimeSlot ? item.DeliveryTimeSlot : "",
          CartId: item.CartId ? item.CartId : 0,
          Remarks: item.Remarks ? item.Remarks : "",
        })
      })
      if (state?.isEdit) {
        setisLoading(true)
        putApi("Booking/update", {
          id: state?.data.Id,
          name: values.Name,
          contactNo1: values.ContactNo1,
          contactNo2: values.ContactNo2,
          totalRent: tableRent,
          advance: advance,
          discount: discount,
          remarks: values.remark,
          updateBookingItems: bookingItem,
          Address: values.Address,
        }).then(({ data, status }) => {
          if (status === 200) {
            setisLoading(false)
            setLoader(false)
            toast.success("Successfully Done", {
              style: {
                fontSize: "15px",
              },
            })
            advance = 0
            discount = 0
            navigate(routes.bookingList)
          } else {
            setisLoading(false)
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      } else {
        setisLoading(true)
        postApi("Booking/create", {
          billNo: state ? state?.data.BillNo : "",
          name: values.Name,
          contactNo1: values.ContactNo1,
          contactNo2: values.ContactNo2,
          totalRent: tableRent,
          totalAmount: tableRent,
          advance: Number(values.Advance),
          discount: Number(values.discount),
          remarks: values.remark,
          createBookingItems: bookingItem,
          address: values.Address,
        }).then(({ data, status }) => {
          if (status === 200) {
            if (flag) {
              advance = 0
              discount = 0
              setisLoading(false)
              handleSaveprint(data)
            } else {
              toast.dismiss()
              toast.success("Booking successfully create", {
                style: {
                  fontSize: "15px",
                },
              })
              navigate(routes.bookingList)
            }
          } else {
            setisLoading(false)
            toast.dismiss()
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
    },
  })

  function handleTotalRent(advance, discount, data) {
    const totalTablerent = data
      ? data.reduce((sum, item) => sum + parseInt(item.Rent), 0)
      : tableData.reduce((sum, item) => sum + parseInt(item.Rent), 0)
    setTableRent(totalTablerent)
    settotalRent(totalTablerent)
    advance = parseInt(advance == "" ? 0 : advance)
    discount = parseInt(discount == "" ? 0 : discount)
    validation.setFieldValue("totalAmount", totalTablerent - advance - discount)
  }

  // Delete Booking handler
  const deleteHandle = (Id, CartId) => {
    let updatedArray = []
    if (CartId) {
      deleteApi(`Booking/delete-cart?id=${CartId}`).then(({ data, status }) => {
        if (status === 200) {
          toast.success("Successfully deleted", {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
      updatedArray = tableData
        .map(obj => (obj.CartId !== CartId ? obj : null))
        .filter(Boolean)
    } else {
      updatedArray = tableData
        .map(obj => (obj.Id !== Id ? obj : null))
        .filter(Boolean)
    }
    setTableData(updatedArray)
    setRentList(updatedArray)
    handleTotalRent(advance, discount, updatedArray)
  }

  // perticular booking item remarks
  const remarkHandle = (bookingId, remark) => {
    setRemarkProductId(bookingId)
    setProductNote(remark)
    toggle()
  }

  // Edit mode handler
  const [isEdit, setisEdit] = useState(false) //flag for editing
  const editHandle = editData => {
    if ((state && state?.isEdit) || state.data) {
      editData = {
        ...editData,
        DeliveryDate: editData.DeliveryDate?.split("T")[0],
        ReturnDate: editData.ReturnDate?.split("T")[0],
        ProductId: editData.ProductId.toString(),
      }
    }
    setisEdit(true)
    setdata(editData)
  }

  // validation Function for existing Item
  const exitInBookingItem = (arr, obj) => {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i]
      if (item.ProductCode === obj.ProductCode) {
        return true
      }
    }
    return false
  }

  // product Details initialValues
  const [data, setdata] = useState({
    ProductId: 0,
    ProductCode: "",
    DeliveryDate: "",
    ReturnDate: "",
    // Rent: 0,
    Rent: isEdit
      ? state?.data.totalAmount - state
        ? state?.data.Discount
        : 0 - state
        ? state?.data.Advance
        : 0
      : 0,
    ReturnTimeSlot: "",
    DeliveryTimeSlot: "",
    ProductName: "",
    CartId: 0,
    Remarks: "",
  })

  // Product Detilas validation
  const productDetailsValidation = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: Yup.object({
      ProductId: Yup.string().required("The Product is required"),
      ProductCode: Yup.string().required("The Product Code is required"),
      DeliveryDate: Yup.string().required("The Delivery date is required"),
      ReturnDate: Yup.string().required("The Return date is required"),
      Rent: Yup.string().required("The Rent is required"),
    }),
    onSubmit: bookingItem => {
      // if (isEdit) {
      //   const updatedArray = tableData.map(item =>
      //     item.ProductCode == data.ProductCode ? (item = bookingItem) : item
      //   )
      //   setTableData(updatedArray)
      //   productDetailsValidation.handleReset()
      //   setdata({
      //     ProductId: 0,
      //     ProductCode: "",
      //     DeliveryDate: "",
      //     ReturnDate: "",
      //     Rent: "",
      //   })
      //   setisEdit(false)
      //   return false
      // } else {

      let bookingItems = exitInBookingItem(tableData, bookingItem)
      postApi("Booking/add-to-cart", {
        name: state?.data.Name,
        productCode: bookingItem.ProductCode,
        deliveryDate: bookingItem.DeliveryDate,
        deliveryTimeSlot: bookingItem.DeliveryTimeSlot ?? "",
        returnDate: bookingItem.ReturnDate,
        returnTimeSlot: bookingItem.ReturnTimeSlot ?? "",
        confirmByVendor: cartConfirm,
      }).then(({ data, status }) => {
        // TODO : Status Condition for list
        if (status == 200 && data.CartId > 0 && data.Status == 1) {
          if (!bookingItems) {
            // setCartList([])
            setCartStatus("")
            setCartConfirm(false)
            bookingItem.CartId = data.CartId
            var temp = [...tableData, bookingItem]
            setTableData(temp)
            setRentList(temp)
            handleTotalRent(advance, discount, temp)
            //setTableRent(parseInt(tableRent) + parseInt(bookingItem.Rent))
            productDetailsValidation.handleReset()
            setdata({
              ProductId: 0,
              ProductCode: "",
              DeliveryDate: "",
              ReturnDate: "",
              Rent: 0,
            })
          } else {
            toast.error("Product is already has been list", {
              style: {
                fontSize: "15px",
              },
            })
          }
        } else if (status == 200 && data.Status == 2) {
          toast.error(data.ErrorMessage, {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          setCartList(data.CartAvailabilityList)
          toggle1()
        }
      })
      // }
    },
  })

  const handleChangeRent = (ProductId, rent) => {
    let updatedArray = []
    tableData.map(item => {
      updatedArray.push({
        CartId: item.CartId ? item.CartId : 0,
        Id: item.Id,
        ProductId: parseInt(item.ProductId),
        ProductCode: item.ProductCode,
        ProductName: item.ProductName,
        Rent:
          item.ProductId == ProductId
            ? rent == ""
              ? 0
              : (item.Rent = rent)
            : item.Rent,
        DeliveryDate: item.DeliveryDate,
        ReturnDate: item.ReturnDate,
        ReturnTimeSlot: item.ReturnTimeSlot ? item.ReturnTimeSlot : "",
        DeliveryTimeSlot: item.DeliveryTimeSlot ? item.DeliveryTimeSlot : "",
      })
    })
    // setTableData(updatedArray)
    setRentList(updatedArray)
    handleTotalRent(advance, discount, updatedArray)
  }

  const handleRemarkAdd = () => {
    rentList.map(item => {
      if (item.ProductId == remarkProductId) {
        item.Remarks = productNote
      }
    })
    toggle()
  }

  // multiple Booking Table
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Product Code
          </div>
        ),
        accessor: "ProductCode",
        width: "7%",
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Product Name
          </div>
        ),
        accessor: "ProductName",
        Cell: ({ row }) => {
          return <div>{row.original.ProductName}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Delivery
          </div>
        ),
        accessor: "DeliveryDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row?.original?.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row?.original?.DeliveryDate.split("T")[0],
                  "dd/MM/yyyy"
                )}{" "}
            {row?.original?.DeliveryTimeSlot ? (
              <span> {` (${row?.original?.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Return
          </div>
        ),
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row?.original?.ReturnDate == null
              ? "N/A"
              : dateFormat(
                  row?.original?.ReturnDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
            {row?.original?.ReturnTimeSlot ? (
              <span> {` (${row?.original?.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "10%",
        Cell: ({ row }) => {
          return (
            <div className="table-display">
              <i className="mdi mdi-currency-inr"></i>
              <Input
                className="form-control-sm table-cell"
                placeholder="rent"
                type="text"
                defaultValue={row.original.Rent}
                // value={changeTableRent}
                onChange={e =>
                  handleChangeRent(row.original.ProductId, e.target.value)
                }
              />
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center">
              <li style={{ marginRight: "4px" }}>
                <i
                  className="fs-6 far fa-bookmark btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() =>
                    remarkHandle(row.original.ProductId, row.original.Remarks)
                  }
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`edittooltip${row.original.Id}`}
                >
                  Note
                </UncontrolledTooltip>
              </li>

              <li>
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() =>
                    deleteHandle(row.original.Id, row.original.CartId)
                  }
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.Id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  const columns1 = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Salesman Name",
        accessor: "SalesmanName",
      },
      {
        Header: "Delivery Date",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.DeliveryDate.split("T")[0],
                  "dd/MM/yyyy"
                )}{" "}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return Date",
        accessor: "ReturnDate,ReturnTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    []
  )

  const handleDiscountChange = value => {
    value = value == "" ? 0 : value
    discount = parseInt(value)
    validation.setFieldValue("discount", discount)
    handleTotalRent(advance, discount, null)
  }

  const handleAdvanceChange = value => {
    value = value == "" ? 0 : value
    advance = parseInt(value)
    validation.setFieldValue("Advance", advance)
    handleTotalRent(advance, discount, null)
  }

  // Auto select feild Code Product Rent
  const autoSelect = (e, name) => {
    if (name == "ProductCode") {
      let TargetValue = e
      const filterData = ProductDetails.filter(item => item.Code == TargetValue)
      if (filterData.length) {
        setdata({
          ...data,
          ProductCode: TargetValue,
          ProductId: filterData[0].Id,
          Rent: filterData[0].Rent,
          ProductName: filterData[0].Name,
        })
      } else {
        setdata({
          ...data,
          ProductCode: TargetValue,
          ProductId: 0,
          Rent: 0,
          ProductName: filterData[0].Name,
        })
      }
    } else {
      let TargetValue = e
      const filterData = ProductDetails.filter(item => item.Id == TargetValue)
      setdata({
        ...data,
        ProductCode: filterData[0].Code,
        ProductId: TargetValue,
        Rent: filterData[0].Rent,
        ProductName: filterData[0].Name,
      })
    }
  }

  if (loader) {
    return (
      <React.Fragment>
        <div className="page-content">Loading...</div>
      </React.Fragment>
    )
  }

  // handle Save and Print event
  const InvoiceDetails = async id => {
    return await getApi(`Booking/invoice-detail?id=${id}`)
  }

  const number = [
    {
      number: 918200478318,
    },
    { number: 918758989031 },
  ]

  const whatapp = () => {
    number.map(({ number }, index) => {
      window.open(
        `https://wa.me/${number}?text=${"hello world"}`,
        "_blank",
        "width=1000,height=600,menubar=no,toolbar=no"
      )
    })
  }

  const handleSaveprint = async id => {
    toast.dismiss()
    toast.success("Booking successfully create", {
      style: {
        fontSize: "15px",
      },
    })
    const html = await InvoiceHtml(id)

    const doc = new jsPDF({
      format: "a5",
      unit: "px",
      filters: ["ASCIIHexEncode"],
    })
    doc.addFileToVFS("NotoSansGujarati-Regular.ttf", font)
    doc.addFont(
      "NotoSansGujarati-Regular.ttf",
      "NotoSansGujarati-Regular",
      "normal"
    )
    doc.setFont("NotoSansGujarati-Regular") // set font
    doc.html(html, {
      async callback(doc) {
        await doc.autoPrint()
        window.open(doc.output("bloburl"), "_blank")
      },
      margin: [10, 10, 10, 10],
    })
    navigate(routes.bookingList)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <BookingBreadcrumb
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
            BillNo={state?.data.BillNo}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row>
                      <Label className="mb-3">
                        Customer Details
                        <hr
                          style={{
                            color: "red",
                            background: "#00000036",
                            height: "0.5px",
                            margin: "0px",
                            marginBottom: "10px",
                          }}
                        />
                      </Label>
                    </Row>
                    <Row>
                      <Col md="4" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Name
                          </Label>
                          <Input
                            name="Name"
                            placeholder=" Name"
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Name || ""}
                            invalid={
                              validation.touched.Name && validation.errors.Name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.Name && validation.errors.Name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="3" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Contact No.1
                          </Label>
                          <Input
                            name="ContactNo1"
                            placeholder="Contact No."
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ContactNo1 || ""}
                            invalid={
                              validation.touched.ContactNo1 &&
                              validation.errors.ContactNo1
                                ? true
                                : false
                            }
                          />
                          {validation.touched.ContactNo1 &&
                          validation.errors.ContactNo1 ? (
                            <FormFeedback type="invalid">
                              {validation.errors.ContactNo1}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="3" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Contact No.2
                          </Label>
                          <Input
                            name="ContactNo2"
                            placeholder="customer no."
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ContactNo2 || ""}
                            invalid={
                              validation.touched.ContactNo2 &&
                              validation.errors.ContactNo2
                                ? true
                                : false
                            }
                          />
                          {validation.touched.ContactNo2 &&
                          validation.errors.ContactNo2 ? (
                            <FormFeedback type="invalid">
                              {validation.errors.ContactNo2}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Bill No.
                          </Label>
                          <Input
                            name="BillNo"
                            placeholder="Bill No."
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.BillNo || ""}
                            invalid={
                              validation.touched.BillNo &&
                                validation.errors.BillNo
                                ? true
                                : false
                            }
                          />
                          {validation.touched.BillNo &&
                            validation.errors.BillNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.BillNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                      <Col md="8">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Address
                          </Label>
                          <Input
                            name="Address"
                            placeholder="Address"
                            type="textarea"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Address || ""}
                            invalid={
                              validation.touched.Address &&
                              validation.errors.Address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.Address &&
                          validation.errors.Address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Label className="my-3">
                        Product Details
                        <hr
                          style={{
                            background: "#00000036",
                            height: "0.5px",
                            margin: "0px",
                            // marginBottom: "2px",
                          }}
                        />
                      </Label>
                    </Row>

                    <Row className="mb-2">
                      <Col md="11">
                        <Row>
                          <Col md="3" className="pe-0" lg="1">
                            <div className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className="required"
                              >
                                Code
                              </Label>
                              <Input
                                name="ProductCode"
                                placeholder="ProductCode"
                                disabled={isEdit}
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={e => {
                                  autoSelect(e.target.value, "ProductCode")
                                }}
                                value={
                                  productDetailsValidation?.values
                                    ?.ProductCode || ""
                                }
                                invalid={
                                  productDetailsValidation.touched
                                    .ProductCode &&
                                  productDetailsValidation.errors.ProductCode
                                    ? true
                                    : false
                                }
                              />
                              {productDetailsValidation.touched.ProductCode &&
                              productDetailsValidation.errors.ProductCode ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.ProductCode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="4" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label
                                htmlFor="productDetailsValidationCustom01"
                                className="required"
                              >
                                Products
                              </Label>
                              <Input
                                bsSize="sm"
                                type="select"
                                disabled={isEdit}
                                id="productDetailsValidationCustom01"
                                className="form-control-sm"
                                name="ProductId"
                                onChange={e => {
                                  autoSelect(e.target.value, "Product")
                                }}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation?.values?.ProductId
                                }
                                invalid={
                                  productDetailsValidation?.touched
                                    ?.ProductId &&
                                  productDetailsValidation?.errors?.ProductId
                                    ? true
                                    : false
                                }
                              >
                                <option>Select</option>
                                {ProductDetails.map(item => (
                                  <option
                                    key={item.ProductCode}
                                    value={item.Id}
                                  >
                                    {item.Name}
                                  </option>
                                ))}
                              </Input>
                              {productDetailsValidation.touched.ProductId &&
                              productDetailsValidation.errors.ProductId ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.ProductId}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="1">
                            <div className="mb-2">
                              <Label className="form-label required">
                                Rent
                              </Label>
                              <Input
                                name="Rent"
                                className="form-control-sm"
                                placeholder="Rent"
                                type="number"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={productDetailsValidation.values.Rent}
                                invalid={
                                  productDetailsValidation.touched.Rent &&
                                  productDetailsValidation.errors.Rent
                                    ? true
                                    : false
                                }
                              />
                              {productDetailsValidation.touched.Rent &&
                              productDetailsValidation.errors.Rent ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.Rent}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label
                                htmlFor="productDetailsValidationCustom01"
                                className="required"
                              >
                                Delivery Date
                              </Label>
                              <Input
                                name="DeliveryDate"
                                placeholder="date placeholder"
                                type="date"
                                // min={new Date().toISOString().slice(0, 10)}
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values
                                    .DeliveryDate || ""
                                }
                                invalid={
                                  productDetailsValidation.touched
                                    .DeliveryDate &&
                                  productDetailsValidation.errors.DeliveryDate
                                    ? true
                                    : false
                                }
                              />
                              {productDetailsValidation.touched.DeliveryDate &&
                              productDetailsValidation.errors.DeliveryDate ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.DeliveryDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label htmlFor="productDetailsValidationCustom01">
                                Time Slot
                              </Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                className="form-control-sm"
                                name="DeliveryTimeSlot"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values
                                    .DeliveryTimeSlot || ""
                                }
                                invalid={
                                  productDetailsValidation.touched
                                    .DeliveryTimeSlot &&
                                  productDetailsValidation.errors
                                    .DeliveryTimeSlot
                                    ? true
                                    : false
                                }
                              >
                                <option>Select</option>
                                {TimeSolteDetails.map(item => {
                                  return (
                                    <option key={item.Key} value={item.Value}>
                                      {item.Value}
                                    </option>
                                  )
                                })}
                              </Input>
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label
                                htmlFor="productDetailsValidationCustom01"
                                className="required"
                              >
                                Return Date
                              </Label>
                              <Input
                                name="ReturnDate"
                                placeholder="date placeholder"
                                type="date"
                                min={
                                  productDetailsValidation.values
                                    .DeliveryDate ||
                                  new Date().toISOString().slice(0, 10)
                                }
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values.ReturnDate ||
                                  ""
                                }
                                invalid={
                                  productDetailsValidation.touched.ReturnDate &&
                                  productDetailsValidation.errors.ReturnDate
                                    ? true
                                    : false
                                }
                              />
                              {productDetailsValidation.touched.ReturnDate &&
                              productDetailsValidation.errors.ReturnDate ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.ReturnDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label htmlFor="productDetailsValidationCustom01">
                                Time Slot
                              </Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                className="form-control-sm"
                                name="ReturnTimeSlot"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values
                                    .ReturnTimeSlot || ""
                                }
                              >
                                <option>Select</option>
                                {TimeSolteDetails.map(item => {
                                  return (
                                    <option key={item.Key} value={item.Value}>
                                      {item.Value}
                                    </option>
                                  )
                                })}
                              </Input>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="1" className="text-sm-end">
                        <Button
                          color="primary"
                          className="btn btn-success btn-sm "
                          type="button"
                          onClick={e => {
                            e.preventDefault()
                            productDetailsValidation.handleSubmit()
                            return false
                          }}
                          style={{ marginTop: "21px", padding: "4px 14px" }}
                        >
                          {isEdit ? "Save" : "Add"}
                        </Button>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md="12" className="text-end ">
                        <TableListProduct
                          columns={columns}
                          data={tableData}
                          isGlobalFilter={false}
                          isCreateBooking={false}
                          customPageSize={10}
                          totalRent={tableRent}
                          className="custom-header-css"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="4" className="pe-0">
                        <div className="mb-2">
                          <Label htmlFor="validationCustom05">Remark</Label>
                          <Input
                            name="remark"
                            rows="2"
                            placeholder="Type your remark here"
                            type="textarea"
                            className="form-control"
                            value={validation.values.remark || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                        </div>
                      </Col>
                      <Col md="2" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom01"
                            className="form-label"
                          >
                            Advance
                          </Label>
                          <Input
                            name="Advance"
                            className="form-control"
                            placeholder="Advance"
                            type="number"
                            onChange={e => {
                              handleAdvanceChange(e.target.value)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.Advance}
                            invalid={
                              validation.touched.Advance &&
                              validation.errors.Advance
                                ? true
                                : false
                            }
                          />
                          {validation.touched.Advance &&
                          validation.errors.Advance ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Advance}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md="2" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom01"
                            className="form-label"
                          >
                            Discount
                          </Label>
                          <Input
                            name="discount"
                            className="form-control"
                            placeholder="Discount"
                            type="number"
                            // onChange={validation.handleChange}
                            onChange={e => {
                              handleDiscountChange(e.target.value)
                            }}
                            onBlur={validation.handleBlur}
                            // onBlur={e => {
                            //   e.target.value > 0
                            //     ? validation.setFieldValue(
                            //       "Rent",
                            //       parseInt(totalRent) -
                            //       parseInt(e.target.value)
                            //     )
                            //     : validation.setFieldValue("Rent", totalRent)
                            // }}
                            value={validation.values.discount}
                            invalid={
                              validation.touched.discount &&
                              validation.errors.discount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.discount &&
                          validation.errors.discount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.discount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="2" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="form-label"
                          >
                            Payble Amount
                          </Label>
                          <Input
                            name="totalAmount"
                            disabled
                            className="form-control"
                            placeholder="Payble Amount"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.totalAmount}
                            invalid={
                              validation.touched.totalAmount &&
                              validation.errors.totalAmount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.totalAmount &&
                          validation.errors.totalAmount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.totalAmount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="12"
                        className="text-end d-flex justify-content-end align-items-center"
                      >
                        {state ? (
                          <Button
                            color="secondary"
                            className="btn btn-primary me-2"
                            type="submit"
                            onClick={() => {
                              {
                                state?.booking == true
                                  ? navigate(routes.cartlist)
                                  : navigate(routes.bookingList)
                              }
                            }}
                          >
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            color="secondary"
                            className="btn btn-primary me-2"
                            type="reset"
                            // onClick={handleReset}
                          >
                            Reset
                          </Button>
                        )}
                        {allowPrintButton ? (
                          <Button
                            color="primary"
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => {
                              setFlag(true)
                            }}
                          >
                            Save & Print
                          </Button>
                        ) : (
                          <>
                            {isLoading ? (
                              <button type="button" className="btn btn-primary">
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                Loading
                              </button>
                            ) : (
                              <Button
                                color="primary"
                                className="btn btn-primary me-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form>

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered={true}
                    size="small"
                    style={{ width: "350px" }}
                  >
                    <div className="modal-content">
                      <ModalHeader
                        className="text-centre"
                        toggle={toggle}
                        tag="h5"
                      >
                        Product Note
                      </ModalHeader>
                      <ModalBody>
                        <Row className="pe-0">
                          <div className="mb-2">
                            <Label htmlFor="validationCustom05">Remark</Label>
                            <Input
                              name="productRemark"
                              rows="2"
                              placeholder="Type your remark here"
                              type="textarea"
                              className="form-control"
                              defaultValue={productNote}
                              onChange={e => setProductNote(e.target.value)}
                            />
                          </div>
                          <div className="text-end">
                            <button
                              color="primary"
                              className="btn btn-primary btn-sm "
                              type="button"
                              onClick={() => handleRemarkAdd()}
                            >
                              Save
                            </button>
                          </div>
                        </Row>
                      </ModalBody>
                    </div>
                  </Modal>

                  <Modal isOpen={modal1} toggle={toggle1} centered={true}>
                    <div className="modal-content ">
                      <ModalHeader
                        className="text-centre"
                        toggle={toggle1}
                        tag="h5"
                      >
                        Selected Items for this date
                      </ModalHeader>
                      <ModalBody>
                        <TableList
                          columns={columns1}
                          data={cartList}
                          customPageSize={10}
                          className="custom-header-css"
                        />
                      </ModalBody>
                      <Row>
                        <Col
                          md="12"
                          className="text-end"
                          style={{ marginBottom: "5px" }}
                        >
                          <Button
                            color="secondary"
                            className="btn btn-primary btn-sm me-2"
                            type="button"
                            onClick={() => toggle1()}
                          >
                            Cancle
                          </Button>
                          <Button
                            color="primary"
                            className="btn btn-primary btn-sm me-2"
                            type="submit"
                            onClick={e => {
                              e.preventDefault()
                              handaleConfirm()
                              productDetailsValidation.handleSubmit()
                              return false
                            }}
                            // onClick={() => handaleConfirm()
                            //   addtoCartValidation.handleSubmit()}
                          >
                            Confirm
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateBooking
