import useApi from "common/ApiMiddlewere"
import { BookingItemHtml, InvoiceTable } from "./table"
import { dateFormat } from "pages/Common"

export const InvoiceHtml = async (id, getApi) => {
  let { data, status } = await getApi(`Booking/invoice-detail?id=${id}`)
  if (status == 200) {
    let {
      Advance,
      BillDate,
      BillNo,
      BillNoteList,
      ContactNo1,
      ContactNo2,
      Discount,
      Name,
      ProductList,
      TotalRent,
      CustomerAddress,
      Remarks,
      ShopAddress,
      ShopContactNo,
      ShopName,
    } = data

    var htmlNew = InvoiceTable
    var html = htmlNew
      .replace("{COMPANY_NAME}", ShopName)
      .replace("{COMPANY_ADDRESS}", ShopAddress)
      .replace("{COMPANY_MOBILENUMER}", ShopContactNo)
      .replace("{BILL_NO}", BillNo)
      .replace(
        "{BOOKING_DATE}",
        dateFormat(BillDate.split("T")[0], "dd/MM/yyyy")
      )
      .replace("{CUSTOMER_NAME}", Name)
      .replace("{CUSTOMER_ADDRESS}", CustomerAddress)
      .replace("{CUSTOMER_MOBILENUM1}", ContactNo1)
      .replace("{CUSTOMER_MOBILENUM2}", ContactNo2)
      .replace("{TOTALRENT}", TotalRent.toFixed(2))
      .replace("{DISCOUNT}", Discount.toFixed(2))
      .replace("{ADVANCE}", Advance.toFixed(2))
      .replace(
        "{PAYBLE_AMOUNT}",
        (parseInt(TotalRent) - parseInt(Advance) - parseInt(Discount)).toFixed(
          2
        )
      )
      .replace("{DEPOSITE}", "")
      .replace("{NOTE_1}", BillNoteList[0].Value)
      .replace("{NOTE_2}", BillNoteList[1].Value)
      .replace("{NOTE_3}", BillNoteList[2].Value)
      .replace("{NOTE_4}", BillNoteList[3].Value)
      .replace("{REMARKS}", Remarks)

    var itemHtmlNew = BookingItemHtml
    var itemHtml = ""
    let temp = 10 - parseInt(ProductList.length)
    for (let i = 0; i < ProductList.length; i++) {
      itemHtml += itemHtmlNew
        .replace("{RE_NO}", i + 1)
        .replace("{PRODUCT_CODE}", ProductList[i].ProductCode)
        .replace("{PRODUCT_NAME}", ProductList[i].ProductTitle)
        .replace(
          "{DELIVERY}",
          dateFormat(ProductList[i].DeliveryDate.split("T")[0], "dd/MM/yyyy")
        )
        .replace(
          "{RETURN}",
          dateFormat(ProductList[i].ReturnDate.split("T")[0], "dd/MM/yyyy")
        )
        .replace("{RUPPES}", "₹")
        .replace("{RENT}", ProductList[i].Rent.toFixed(2))
    }
    var tempData = BookingItemHtml
    for (let i = 0; i < temp; i++) {
      itemHtml += tempData
        .replace("{RE_NO}", "")
        .replace("{PRODUCT_CODE}", "")
        .replace("{PRODUCT_NAME}", "")
        .replace("{DELIVERY}", "")
        .replace("{RETURN}", "")
        .replace("{RENT}", "")
        .replace("{RUPPES}", "")
    }
    html = html.replace("{BOOKINT_ITEMS}", itemHtml)
    return html
  }
}
