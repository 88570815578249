import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Label,
    FormFeedback,
    InputGroup,
    Row,
    Col,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../common/Breadcrumbs"
import { useFormik } from "formik"
import { Screen_Names } from "_mock/Static_data"
import AdvertisementTable from "./AdvertisementTable"

function AdvertisementList() {
    const { deleteApi, postApi, putApi, getApi } = useApi()
    let { pathname } = useLocation()
    const [screeLoad, setscreeLoad] = useState(true)
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchText, setSearchText] = useState("")
    const [modal, setModal] = useState(false)
    const [TotalRecords, setTotalRecords] = useState(0.0)
    const [displayStart, setDisplayStart] = useState(0)
    const [isLive, setIsLive] = useState(true)
    const [isActive, setIsActive] = useState(true)
    const [formSumitLoading, setFormSubmitLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState(0)
    const [editData, setEditData] = useState()
    const [androisIOS, setAndroidIOS] = useState(0)

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(((pagePerIndex * pageSize) - pageSize) + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }
    const advertisementList = () => {
        setIsLoading(true)
        setscreeLoad(true)
        postApi("api/Advertisement/list", {
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText,
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.advertisementList)
                setTotalRecords(data.totalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })
    }

    // userList useEffect
    useEffect(() => {
        advertisementList()
    }, [rowsPerPage, searchText, displayStart])

    // user delete handler
    const handleDelete = id => {
        DeleteModel.confirmDeleteInquiry({
            title: "Are you sure?",
            text: "You want to delete this advertise permanently? ",
        }).then(response => {
            if (response == true) {
                deleteApi(`api/Advertisement/delete?id=${id}`).then(({ data, status }) => {
                    setIsLoading(true)
                    if (status === 200) {
                        toast.success("successfully deleted ", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        setIsLoading(false)
                        const updatedArray = tableData
                            .map(obj => (obj.id !== id ? obj : null))
                            .filter(Boolean)
                        setTableData(updatedArray)
                    } else {
                        setIsLoading(false)
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                })
            }
        })
    }

    // user edit handle
    const handleEdit = (Id, isAndroid) => {
        setAndroidIOS(isAndroid ? parseInt(0) : parseInt(1))
        if (Id) {
            setEditId(Id)
            setIsEdit(true)
            toggle()
            getApi(`api/Advertisement/details?id=${Id}`).then(({ data, status }) => {
                if (status === 200) {
                    setEditData(data)
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })

        }
    }

    const handleCancle = () => {
        setIsEdit(false)
        toggle()
    }

    // user active/inactive switch hanlde
    const handleSwitchLive = async id => {
        putApi("api/Advertisement/live-notLive", { id }).then(({ data, status }) => {
            if (status === 200) {
                toast.success("Sucessfully Changed", {
                    style: {
                        fontSize: "15px",
                    },
                })
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
                advertisementList()
            }
        })
    }

    const handleSwitchActive = async id => {
        putApi("api/Advertisement/active-inactive", { id }).then(({ data, status }) => {
            if (status === 200) {
                toast.success("Sucessfully Changed", {
                    style: {
                        fontSize: "15px",
                    },
                })
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
                advertisementList()
            }
        })
    }

    // handle user click
    const handleUserClicks = () => {
        setIsEdit(false)
        setAndroidIOS(0)
        validation.handleReset()
        toggle()
    }

    const toggle = () => {
        setModal(!modal)
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            screenName: isEdit ? editData?.screenName : "",
            provider: isEdit ? editData?.provider : "",
            addType: isEdit ? editData?.adType : "",
            appId: isEdit ? editData?.appId : "",
        },
        validationSchema: Yup.object({
            screenName: Yup.string().required("The screen name is required"),
            provider: Yup.string().required("The provider is required"),
            addType: Yup.string().required("The addType is required"),
            appId: Yup.string().required("The appId is required"),
        }),
        onSubmit: async (values) => {
            if (values) {
                setFormSubmitLoading(true);
                let response;
                if (isEdit) {
                    response = await putApi("api/Advertisement/edit", {
                        id: editId,
                        screenName: values.screenName,
                        provider: values.provider,
                        adType: values.addType,
                        appId: values.appId,
                        isAndroid: androisIOS === 0 ? true : false,
                    });
                } else {
                    response = await postApi("api/Advertisement/add", {
                        screenName: values.screenName,
                        provider: values.provider,
                        adType: values.addType,
                        appId: values.appId,
                        isLive: isLive,
                        isActive: isActive,
                        isAndroid: androisIOS === 0 ? true : false,
                    });
                }
                const { data, status } = response;
                if (status === 200) {
                    advertisementList();
                    toggle();
                    setIsEdit(false)
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    });
                }
                setFormSubmitLoading(false);
            }
        },

    })

    const userEdit = id => { }

    // table accessor & header
    const columns = useMemo(
        () => [
            {
                Header: "Platform",
                accessor: "isAndroid",
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row?.original?.isAndroid == true ? "Android" : "IOS"}
                        </div>
                    )
                },
            },
            {
                Header: "Screen Name",
                accessor: "screenName",
                width: 12,
            },
            {
                Header: "Provider",
                accessor: "provider",
            },
            {
                Header: "Add Type",
                accessor: "adType",
            },
            {
                Header: "App Id",
                accessor: "appId",
                width: "7%",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Is Live
                    </div>
                ),
                width: "6%",
                accessor: "isLive",
                Cell: ({ row }) => (
                    <FormGroup switch className="d-flex justify-content-center">
                        <Input
                            type="switch"
                            defaultChecked={row.original.isLive}
                            onClick={e => {
                                handleSwitchLive(row.original.id)
                            }}
                        />
                    </FormGroup>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Is Active
                    </div>
                ),
                width: "6%",
                accessor: "isActive",
                Cell: ({ row }) => (
                    <FormGroup switch className="d-flex justify-content-center">
                        <Input
                            type="switch"
                            defaultChecked={row.original.isActive}
                            onClick={e => {
                                handleSwitchActive(row.original.id)
                            }}
                        />
                    </FormGroup>
                ),
            },
            {
                Header: "Create Date",
                accessor: "createdOn",
                width: "7%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row?.original?.createdOn == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row?.original?.createdOn.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },

            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit "
                                    id={`edittooltip${row.original.id}`}
                                    onClick={() => handleEdit(row.original.id, row.original.isAndroid)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.id}`}
                                    onClick={() => handleDelete(row.original.id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )
    const handleChangePlatform = (e) => {
        setAndroidIOS(parseInt(e.target.value));
    };

    //meta title
    document.title = `Advertise | ${"Shop Manager"}`

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screeLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].parent}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                    link={breadcrumbs[pathname].parentLink}
                />
                <Card>
                    <CardBody>
                        <AdvertisementTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isAddUserList={true}
                            handleUserClick={handleUserClicks}
                            customPageSize={10}
                            className="custom-header-css"
                            userEdit={userEdit}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                        />
                    </CardBody>

                    <Modal
                        isOpen={modal}
                        toggle={() => setModal(!modal)}
                        centered={true}
                        size="Default"
                    >
                        <div className="modal-content">
                            <ModalHeader toggle={() => setModal(!modal)} tag="h5">
                                {isEdit ? "Update" : "Create"} Advertise
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                    }}
                                    onReset={e => {
                                        e.preventDefault
                                        validation.handleReset()
                                        return false
                                    }}
                                >
                                    <Row>
                                        <Col md={3} style={{ display: "flex", alignItems: "center" }}>
                                            <div className="d-flex gap-2" style={{ display: "flex" }}>
                                                <input
                                                    type="radio"
                                                    value={0}
                                                    name="gender"
                                                    id="customRadioInline1"
                                                    className="form-check-input"
                                                    // checked={(isEdit && isAndroid) ? true : androisIOS === 0}
                                                    checked={androisIOS === 0}
                                                    onChange={handleChangePlatform}
                                                />
                                                <Label
                                                    className="form-check-label font-size-13"
                                                    htmlFor="customRadioInline1"
                                                >
                                                    Android
                                                </Label>
                                                <input
                                                    type="radio"
                                                    value={1}
                                                    name="gender"
                                                    id="customRadioInline2"
                                                    className="form-check-input"
                                                    // checked={(isEdit && !isAndroid) ? true : androisIOS === 1}
                                                    checked={androisIOS === 1}
                                                    onChange={handleChangePlatform}
                                                />
                                                <Label
                                                    className="form-check-label font-size-13"
                                                    htmlFor="customRadioInline2"
                                                >
                                                    IOS
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup className="mb-2">
                                                <Label
                                                    htmlFor="validationCustom02"
                                                    className="required"
                                                >
                                                    Screen Name
                                                </Label>
                                                <Input
                                                    type="select"
                                                    id="validationCustom02"
                                                    bsSize="sm"
                                                    className="form-control-sm"
                                                    name="screenName"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.screenName || ""}
                                                    invalid={
                                                        validation.touched.screenName &&
                                                            validation.errors.screenName
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <option>--- Select Screen ---</option>
                                                    {Screen_Names.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.Key}>
                                                                {item.DisplayName}
                                                            </option>
                                                        )
                                                    })}
                                                </Input>
                                                {validation.touched.screenName &&
                                                    validation.errors.screenName ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.screenName}
                                                    </FormFeedback>
                                                ) : null}
                                            </FormGroup>

                                        </Col>
                                        <Col md={5}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03" className="required">
                                                    Provider
                                                </Label>
                                                <InputGroup className="mb-2">
                                                    <Input
                                                        className="form-control-sm"
                                                        name="provider"
                                                        value={validation.values.provider || ""}
                                                        type={"text"}
                                                        placeholder="provider"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.provider &&
                                                            !!validation.errors.provider
                                                        }
                                                    />
                                                    {validation.touched.provider &&
                                                        validation.errors.provider ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.provider}
                                                        </FormFeedback>
                                                    ) : null}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03" className="required">
                                                    Add Type
                                                </Label>
                                                <InputGroup className="mb-2">
                                                    <Input
                                                        className="form-control-sm"
                                                        name="addType"
                                                        value={validation.values.addType || ""}
                                                        type={"text"}
                                                        placeholder="add type"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.addType &&
                                                            !!validation.errors.addType
                                                        }
                                                    />
                                                    {validation.touched.addType &&
                                                        validation.errors.addType ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.addType}
                                                        </FormFeedback>
                                                    ) : null}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03" className="required">
                                                    App Id
                                                </Label>
                                                <InputGroup className="mb-2">
                                                    <Input
                                                        className="form-control-sm"
                                                        name="appId"
                                                        value={validation.values.appId || ""}
                                                        type={"text"}
                                                        placeholder="app id"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.appId &&
                                                            !!validation.errors.appId
                                                        }
                                                    />
                                                    {validation.touched.appId &&
                                                        validation.errors.appId ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.appId}
                                                        </FormFeedback>
                                                    ) : null}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {isEdit ? "" :
                                            <>
                                                <Col md={1}>
                                                    <Label for="switchLive" className="mb-1">
                                                        Live
                                                    </Label>
                                                    <FormGroup switch className="mt-1">
                                                        <Input
                                                            id="switchLive"
                                                            type="switch"
                                                            defaultChecked={isLive}
                                                            onClick={e => {
                                                                setIsLive(e.target.checked)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <Label for="switchActive" className="mb-2">
                                                        Active
                                                    </Label>
                                                    <FormGroup switch className="mt-1">
                                                        <Input
                                                            id="switchActive"
                                                            type="switch"
                                                            defaultChecked={isActive}
                                                            onClick={e => {
                                                                setIsActive(e.target.checked)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <div className="text-end">
                                        {isEdit ? (
                                            <Button
                                                color="secondary"
                                                className="btn btn-primary me-2 btn-sm"
                                                onClick={handleCancle}
                                                type="button"
                                            >
                                                Cancle
                                            </Button>
                                        ) : (
                                            <Button
                                                color="secondary"
                                                className="btn btn-primary me-2 btn-sm "
                                                type="reset"
                                            >
                                                Reset
                                            </Button>
                                        )}

                                        <>
                                            {formSumitLoading ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                    Loading
                                                </button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    className="btn btn-primary btn-sm "
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                            )}
                                        </>
                                    </div>
                                </Form>
                            </ModalBody>
                        </div>
                    </Modal>
                </Card>
            </div>
        </div>
    )
}

AdvertisementList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default AdvertisementList
