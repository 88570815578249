import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import useApi from "common/ApiMiddlewere"
import * as Yup from "yup"
import toast, { Toaster } from "react-hot-toast"

const SubscriptionModel = ({ modal, toggle, subscriptionApi, username, subscriptionUserId, userListApi, rolename }) => {
    const { getApi, postApi } = useApi()
    const [planAmount, setPlanAmount] = useState(0)
    const [planControls, setPlanControls] = useState([])
    const [userControls, setUserControls] = useState([])
    const [SubscriptionData, setSubscriptionData] = useState({
        userId: "",
        planId: "",
        amount: 0,
        discount: '',
        totalAmount: 0,
        startDate: new Date().toISOString().slice(0, 10),
        endDate: new Date().toISOString().slice(0, 10),
        remarks: "",
    })
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [days, setDays] = useState()
    const [planData, setPlanData] = useState([])


    // useEffect(() => {
    //     if (rolename == "Admin") {
    //         getApi("Plan/list", "").then(({ data, status }) => {
    //             if (status === 200) {
    //                 setPlanData(data)
    //             }
    //         })
    //         getApi("User/vendor-lookup").then(({ data, status }) => {
    //             if (status === 200) {
    //                 setUserControls(data)
    //             }
    //         })
    //         getApi("Plan/plan-lookup").then(({ data, status }) => {
    //             if (status === 200) {
    //                 setPlanControls(data)
    //             }
    //         })
    //     }
    //     validation.setFieldValue("userId", username)
    // }, [rolename])

    useEffect(() => {
        validation.handleReset()
        setSubscriptionData({
            userId: "",
            planId: "",
            amount: 0,
            discount: '',
            totalAmount: 0,
            startDate: new Date().toISOString().slice(0, 10),
            endDate: new Date().toISOString().slice(0, 10),
            remarks: "",
        })
    }, [modal])

    // subscription Validations
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: SubscriptionData,

        validationSchema: Yup.object({
            // userId: Yup.string().required("The User is required"),
            userId: Yup.string().when('username', (username, schema) => {
                return username ? schema.required("The User is required") : schema;
            }),
            planId: Yup.string().required("The Plan is required"),
            amount: Yup.string().required("The Amount is required"),
            startDate: Yup.string().required("The StartDate is required"),
            endDate: Yup.string().required("The endDate is required"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (values) {
                toggle()
                postApi("SubscriptionLogs/renew-subscription", {
                    userId: subscriptionUserId ?? values.userId,
                    planId: values.planId,
                    amount: values.amount,
                    discount: values.discount,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    remarks: values.remarks,
                }).then(({ data, status }) => {
                    setformSumitLoading(false)
                    if (status == 400) {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })

                        validation.handleReset()
                    } else {
                        if (subscriptionUserId) {
                            userListApi()
                        } else {
                            subscriptionApi()
                        }
                        toast.success("Sucessfully created", {
                            style: {
                                fontSize: "15px",
                            },
                        }
                        )
                        validation.handleReset()
                        setSubscriptionData({
                            userId: "",
                            planId: "",
                            amount: 0,
                            discount: '',
                            totalAmount: 0,
                            startDate: new Date().toISOString().slice(0, 10),
                            endDate: new Date().toISOString().slice(0, 10),
                            remarks: "",
                        })
                    }
                })
            }
        },
    })

    // useId handler
    const handleUserId = e => {
        setSubscriptionData({
            ...SubscriptionData,
            userId: e.target.value,
        })
    }

    // start date handler
    const handleDate = e => {
        setSubscriptionData({
            ...SubscriptionData,
            startDate: e.target.value,
        })
        if (e.target.value) {
            setSubscriptionData({
                ...SubscriptionData,
                startDate: e.target.value,
                endDate: new Date(
                    new Date(e.target.value).getTime() + days * 24 * 60 * 60 * 1000
                )
                    .toISOString()
                    .slice(0, 10),
            })
        }
    }

    const handleEndDate = e => {
        setSubscriptionData({
            ...SubscriptionData,
            startDate: e.target.value,
        })
        if (e.target.value) {
            setSubscriptionData({
                ...SubscriptionData,
                endDate: e.target.value,
                startDate: new Date(
                    new Date(e.target.value).getTime() - days * 24 * 60 * 60 * 1000
                )
                    .toISOString()
                    .slice(0, 10),
            })
        }
    }

    const autoSelect = (e, name) => {
        if (name == "plan") {
            let TargetValue = e
            const filterData = planData.filter(item => item.Id == TargetValue)
            if (filterData.length) {
                setDays(filterData[0].Days - 1)
                setPlanAmount(filterData[0].Amount)
                setSubscriptionData({
                    ...SubscriptionData,
                    planId: filterData[0].Id,
                    amount: filterData[0].Amount,
                    discount: filterData[0].Discount == null ? '0' : filterData[0].Discount,
                    totalAmount: filterData[0]?.Amount - filterData[0]?.Discount,
                    startDate: new Date().toISOString().slice(0, 10),
                    endDate: new Date(
                        new Date().getTime() +
                        (filterData[0].Days - 1) * 24 * 60 * 60 * 1000
                    )
                        .toISOString()
                        .slice(0, 10),
                })
            }
        }
    }

    const handleDiscountChange = value => {
        value = value == "" ? 0 : value
        const discount = parseInt(value)
        validation.setFieldValue("discount", discount)
        validation.setFieldValue("totalAmount", planAmount - discount)
    }


    return (
        <div>

            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="small"
                style={{ width: "350px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                        {"Create Subscription"}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            {username ? (<Row>
                                <FormGroup className="mb-2">
                                    <Label
                                        htmlFor="validationCustom01"
                                        className="required"
                                    >
                                        User
                                    </Label>
                                    <Input
                                        type="text"
                                        id="validationCustom01"
                                        className="form-control-sm"
                                        name="userId"
                                        disabled
                                        defaultValue={username}
                                        value={username}
                                        invalid={
                                            validation.touched.userId &&
                                                validation.errors.userId
                                                ? true
                                                : false
                                        }
                                    >
                                    </Input>
                                    {validation.touched.userId &&
                                        validation.errors.userId ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.userId}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>

                            </Row>) : (<Row>
                                <FormGroup className="mb-2">
                                    <Label
                                        htmlFor="validationCustom01"
                                        className="required"
                                    >
                                        Users
                                    </Label>
                                    <Input
                                        type="select"
                                        bsSize="sm"
                                        id="validationCustom01"
                                        className="form-control-sm"
                                        name="userId"
                                        disabled={username}
                                        onChange={e => handleUserId(e)}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.userId || username}
                                        invalid={
                                            validation.touched.userId &&
                                                validation.errors.userId
                                                ? true
                                                : false
                                        }
                                    >
                                        <option>Select User</option>
                                        {userControls.map((item, index) => {
                                            return (
                                                <option key={index} value={item.Key}>
                                                    {item.Value}
                                                </option>
                                            )
                                        })}
                                    </Input>
                                    {validation.touched.userId &&
                                        validation.errors.userId ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.userId}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>

                            </Row>)}

                            <Row>
                                <FormGroup className="mb-2">
                                    <Label
                                        htmlFor="validationCustom01"
                                        className="required"
                                    >
                                        Plans
                                    </Label>
                                    <Input
                                        type="select"
                                        bsSize="sm"
                                        id="validationCustom01"
                                        className="form-control-sm"
                                        name="planId"
                                        onChange={e => {
                                            autoSelect(e.target.value, "plan")
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.planId || ""}
                                        invalid={
                                            validation.touched.planId &&
                                                validation.errors.planId
                                                ? true
                                                : false
                                        }
                                    >
                                        <option>Select Plan</option>
                                        {planControls.map((item, index) => {
                                            return (
                                                <option key={index} value={item.Key}>
                                                    {item.Value}
                                                </option>
                                            )
                                        })}
                                    </Input>
                                    {validation.touched.planId &&
                                        validation.errors.planId ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.planId}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom03"
                                            className="required"
                                        >
                                            Amount
                                        </Label>
                                        <Input
                                            name="amount"
                                            disabled
                                            placeholder="Amount"
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.amount || ""}
                                            invalid={
                                                validation.touched.amount &&
                                                    validation.errors.amount
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.amount &&
                                            validation.errors.amount ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.amount}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom03"
                                        >
                                            Discount
                                        </Label>
                                        <Input
                                            name="discount"
                                            placeholder="Discount"
                                            type="text"
                                            className="form-control-sm"
                                            // onChange={validation.handleChange}
                                            onChange={(e) => {
                                                handleDiscountChange(e.target.value)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.discount || ''}
                                            invalid={
                                                validation.touched.discount &&
                                                    validation.errors.discount
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.discount &&
                                            validation.errors.discount ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.discount}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom03"
                                        >
                                            Total
                                        </Label>
                                        <Input
                                            name="totalAmount"
                                            placeholder="TotalAmount"
                                            type="text"
                                            disabled
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.totalAmount || ''}
                                            invalid={
                                                validation.touched.totalAmount &&
                                                    validation.errors.totalAmount
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.totalAmount &&
                                            validation.errors.totalAmount ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.totalAmount}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-2">
                                        <Label
                                            htmlFor="validation1Custom01"
                                            className="required"
                                        >
                                            Start Date
                                        </Label>
                                        <Input
                                            name="startDate"
                                            placeholder="date placeholder"
                                            type="date"
                                            min={new Date().toISOString().slice(0, 10)}
                                            dateFormat="DD/M/YYYY"
                                            className="form-control-sm"
                                            onChange={e => {
                                                handleDate(e)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.startDate}
                                            invalid={
                                                validation.touched.startDate &&
                                                    validation.errors.startDate
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.startDate &&
                                            validation.errors.startDate ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.startDate}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-2">
                                        <Label
                                            htmlFor="validation1Custom01"
                                            className="required"
                                        >
                                            End Date
                                        </Label>
                                        <Input
                                            name="endDate"
                                            placeholder="date placeholder"
                                            min={validation.values.startDate}
                                            type="date"
                                            dateFormat="DD/M/YYYY"
                                            className="form-control-sm"
                                            onChange={e => {
                                                handleEndDate(e)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.endDate}
                                            invalid={
                                                validation.touched.endDate &&
                                                    validation.errors.endDate
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.endDate &&
                                            validation.errors.endDate ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.endDate}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <FormGroup className="mb-2">
                                    <Label htmlFor="validationCustom03">Remark</Label>
                                    <Input
                                        name="remarks"
                                        placeholder="Type your remark here"
                                        type="textarea"
                                        className="form-control-sm"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.remarks || ""}
                                        invalid={
                                            validation.touched.remarks &&
                                                validation.errors.remarks
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.remarks &&
                                        validation.errors.remarks ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.remarks}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Row>
                            <div className="text-end">
                                <Button
                                    color="secondary"
                                    className="btn btn-primary me-2 btn-sm "
                                    type="reset"
                                >
                                    Reset
                                </Button>

                                {formSumitLoading ? (
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                    >
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                        Loading
                                    </button>
                                ) : (
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-sm "
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}

export default SubscriptionModel