


export const Booking_status = [{
    Key: "Booked",
    Value: 1,
    DisplayName: "Booked",
},
{
    Key: "Delivered",
    Value: 2,
    DisplayName: "Delivered",
},
{
    Key: "Return",
    Value: 3,
    DisplayName: "Return",
},
{
    Key: "Cancel",
    Value: 4,
    DisplayName: "Cancel",
},
]

export const Screen_Names = [{
    Key: "HomeScreen",
    Value: 1,
    DisplayName: "Home Screen",
},
{
    Key: "ProductDetailScreen",
    Value: 2,
    DisplayName: "Product Detail Screen",
},
{
    Key: "SearchScreen",
    Value: 3,
    DisplayName: "Search Screen",
},
]

export function GetObjectsByValues(...values) {
    const array = [
        {
            Key: "Admin",
            Value: 1,
            DisplayName: "Admin",
        },
        {
            Key: "Reviewer",
            Value: 2,
            DisplayName: "Reviewer",
        },
        {
            Key: "Customer",
            Value: 3,
            DisplayName: "Customer",
        },
        {
            Key: "Dealer",
            Value: 4,
            DisplayName: "Dealer",
        },
        {
            Key: "CustomerAndDealer",
            Value: 5,
            DisplayName: "Customer & Dealer",
        },
    ];

    return array.filter(obj => values.includes(obj.Value));
}

export const GetVerificationStatus =
    [
        {
            Key: "All",
            Value: 0,
            DisplayName: "Status",
        },
        {
            Key: "InReview",
            Value: 1,
            DisplayName: "In Review",
        },
        {
            Key: "Approved",
            Value: 2,
            DisplayName: "Approved",
        },
        {
            Key: "Rejected",
            Value: 3,
            DisplayName: "Rejected",
        }
    ]
export default { GetVerificationStatus }