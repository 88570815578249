import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import DeliveryTable from "./DeliveryTable"
import { dateFormat } from "pages/Common"
import { exportDataExcel } from "pages/ExportData"
import { Booking_status } from "_mock/Static_data"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { saveAs } from "file-saver"
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate"

function Delivery() {
  const { postApi } = useApi()
  const XLSX = require("xlsx")
  let { pathname } = useLocation()
  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [productViewId, setProductViewId] = useState()
  const [formDate, setFormDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10))
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchText, setSearchText] = useState("")
  const [displayStart, setDisplayStart] = useState(0)
  const [TotalRecords, setTotalRecords] = useState(0.0)

  const filterData = textFilter => {}
  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }

  // fromDate & toDate
  const dateValue = (deliveryDate, returnDate) => {
    setFormDate(deliveryDate)
    setToDate(returnDate)
  }

  // delivery list api useEffect
  useEffect(() => {
    deliveryApi()
  }, [formDate, toDate, rowsPerPage, searchText, displayStart])

  // delivery list api
  const deliveryApi = () => {
    setIsLoading(true)
    postApi("Report/delivery-report", {
      fromDate: formDate ? formDate : new Date().toISOString().slice(0, 10),
      toDate: toDate ? toDate : new Date().toISOString().slice(0, 10),
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.DeliveryReportList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }
  function writeDataToExcel() {
    setIsLoading(true)
    let header = [
      "Bill No.",
      "Product Name",
      "Product Code",
      "Name",
      "Contact No.",
      "Rent",
      "Advance",
      "Discount",
      "Booking Date",
      "Delivery Date",
      "Return Date",
      "Remarks",
    ]
    const mainTitle = "Delivery Report"
    const widthObj = [
      {
        Key: "B",
        Value: 15,
      },
      {
        Key: "C",
        Value: 15,
      },
      {
        Key: "D",
        Value: 15,
      },
      {
        Key: "E",
        Value: 15,
      },
      {
        Key: "G",
        Value: 12,
      },
      {
        Key: "H",
        Value: 12,
      },
      {
        Key: "I",
        Value: 15,
      },
      {
        Key: "J",
        Value: 20,
      },
      {
        Key: "K",
        Value: 20,
      },
      {
        Key: "L",
        Value: 25,
      },
    ]
    const extention = "Delivery_Report.xlsx"

    postApi("Report/delivery-report", {
      fromDate: formDate ? formDate : new Date().toISOString().slice(0, 10),
      toDate: toDate ? toDate : new Date().toISOString().slice(0, 10),
      displayStart: 1,
      pageSize: 10000,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTotalRecords(data.TotalRecords)
        let excelSheetData = []
        for (let index = 0; index < data.DeliveryReportList.length; index++) {
          let dataObj = {
            BillNo: data.DeliveryReportList[index].BillNo,
            ProductName: data.DeliveryReportList[index].ProductName,
            ProductCode: data.DeliveryReportList[index].ProductCode,
            Name: data.DeliveryReportList[index].Name,
            ContactNo: data.DeliveryReportList[index].ContactNo1,
            Rent: data.DeliveryReportList[index].Rent,
            Advance: data.DeliveryReportList[index].Advance,
            Discount: data.DeliveryReportList[index].Discount,
            BookingDate: dateFormat(
              data.DeliveryReportList[index].BookingDate.split("T")[0],
              "dd/MM/yyyy"
            ),
            DeliveryDate:
              dateFormat(
                data.DeliveryReportList[index].DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              ) +
              ` ${
                data.DeliveryReportList[index].DeliveryTimeSlot == null
                  ? ""
                  : `(${data.DeliveryReportList[index].DeliveryTimeSlot}) `
              }`,
            ReturnDate:
              dateFormat(
                data.DeliveryReportList[index].ReturnDate.split("T")[0],
                "dd/MM/yyyy"
              ) +
              ` ${
                data.DeliveryReportList[index].ReturnTimeSlot == null
                  ? ""
                  : `(${data.DeliveryReportList[index].ReturnTimeSlot}) `
              }`,
            Remarks: data.DeliveryReportList[index].Remarks,
          }
          excelSheetData.push(dataObj)
        }
        var fields = Object.keys(excelSheetData[0])
        var sheetData = excelSheetData.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName]
          })
        })
        sheetData.unshift(header)
        exportDataExcel(sheetData, mainTitle, widthObj, extention, "", "")
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // status value get displayName
  const statusName = id => {
    const statusValue = Booking_status.filter(item => {
      if (item.Value == id) {
        return item.Value
      }
    })
    return statusValue[0].DisplayName
  }

  // header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "5%",
      },
      {
        Header: "Name",
        accessor: "Name",
        // enableRowSpan: false
      },
      {
        Header: "Product Code",
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: "Product Name",
        accessor: "ProductName",
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "8%",
      },
      {
        Header: "Rent",
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Rent.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Advance",
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Advance.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            // style={{width:"120px"}}
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks}`}
          >
            {row.original.Remarks.length > 30
              ? `${row.original.Remarks.substr(0, 30)}...`
              : row.original.Remarks == ""
              ? "N/A"
              : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.DeliveryDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "Status",
        accessor: "Status",
        width: "6%",
        Cell: ({ row }) => {
          let statusValue = statusName(row.original.Status)
          return <div>{statusValue}</div>
        },
      },
    ],
    []
  )

  document.title = `Deliveries | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <DeliveryTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              dateValue={dateValue}
              isLoading={isLoading}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              filterData={filterData}
              TotalRecords={TotalRecords}
              writeDataToExcel={writeDataToExcel}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Delivery.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Delivery
