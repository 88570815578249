/* eslint-disable react/no-unknown-property */
import React, { useState } from "react"
import Compressor from "compressorjs"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { useLocation, useNavigate } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { routes } from "routes/path"
import { useEffect } from "react"
import {GetObjectsByValues} from "_mock/Static_data"

function Adduser() {
  const { state } = useLocation()
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [isLoading, setisLoading] = useState(false)
  const { postApi, getApi } = useApi()
  // let { RoleId } = JSON.parse(localStorage.getItem("authUser"))
  const [shopName, setshopName] = useState("")
  const [RoleId, setRoleId] = useState("")
  const [gender, setGender] = useState("1")
  const [previewUrl, setPreviewUrl] = useState(null)

  useEffect(() => {
    async function UserDetails() {
      const {
        data: { shopName, id },
      } = await getApi(`api/User/details?id=${JSON.parse(localStorage.getItem('authUser')).id}`)
      setshopName(id != 3 ? shopName : "")
      setRoleId(id)
    }
    UserDetails()
  }, [])

  // useStates
  const [fileFlag, setfileFlag] = useState(false)

  // create user datail validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      FullName: state ? state?.inquiryData?.Name : "",
      Username: "",
      ShopName: state ? state?.inquiryData?.ShopName : shopName || '',
      ContactNo: state ? state?.inquiryData?.ContactNo : "",
      Address: state ? state?.inquiryData?.Address : "",
      Password: "",
      remark: "",
      Email: "",
      profilePic: null,
      RoleId: 0,
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("The full name is required"),
      Username: Yup.string().required("The username is required"),
      ShopName: Yup.string().nullable().required("The shop name is required"),
      ContactNo: Yup.string()
        .required("The contact number is required.")
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number"),
      Password: Yup.string().required('The password is required').min(5).max(20),
      RoleId: Yup.number().required("The role is required"),
    }),
    onSubmit: values => {
      setisLoading(true)
      postApi("api/User/create", {
        fullName: values.FullName,
        contactNo: values.ContactNo,
        gender: gender == "1" ? true : false,
        email: values.Email,
        roleId: values.RoleId,
        username: values.Username,
        password: values.Password,
        shopName: values.ShopName,
        address: values.Address,
        profilePic: fileFlag ? values.profilePic.split(",")[1] : "",
        remarks: values.remark,
        latitude: '',
        longitude: '',
      }).then(({ data, status }) => {
        if (status === 200) {
          setisLoading(false)
          toast.success("User Create Successfully", {
            style: {
              fontSize: "15px",
            },
          })
          navigate(routes.userList)
        } else {
          setisLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })

  // profile pic convert toBase64 handle
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  // profile pic select handle
  const handleDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setfileFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
      success: async compressedResult => {
        setPreviewUrl(URL.createObjectURL(compressedResult))
        validation.setFieldValue("profilePic", await toBase64(compressedResult))
      },
    })
  }

  // profile[ic display & set
  const handleclick = () => {
    validation.setFieldValue("profilePic", null)
    setPreviewUrl(null)
  }

  document.title = `Create Users | ${"Shop Manager"}`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Dropzone
                            onDrop={handleDrop}
                            onClick={handleDrop}
                            accept="image/*"
                            multiple={false}
                            id="profilePic"
                            name="profilePic"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div className="d-flex flex-column align-items-center text-center p-2 py-0">
                                  {previewUrl ? (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle pe-auto"
                                        style={{ cursor: "pointer" }}
                                        width="150px"
                                        height="150px"
                                        src={previewUrl}
                                        {...getRootProps()}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle"
                                        style={{ cursor: "pointer" }}
                                        width="150px"
                                        src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                        {...getRootProps()}
                                      />
                                    </>
                                  )}
                                </div>
                                {previewUrl && (
                                  <div className="d-flex justify-content-center align-item-center ">
                                    <button
                                      onClick={() => handleclick()}
                                      type="button"
                                      className="btn btn-sm btn-soft-danger"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </Dropzone>
                          {validation.touched.profilePic &&
                            validation.errors.profilePic ? (
                            <div className="error">
                              {validation.errors.profilePic}
                            </div>
                          ) : null}
                        </FormGroup>
                        <div className="mb-2 "
                          style={{ justifyContent: 'center', display: "flex", alignItems: "center" }}
                        >
                          <div className=" d-flex gap-2" style={{ marginTop: "7px" }}>
                            <input
                              type="radio"
                              value="1"
                              id="customRadioInline1"
                              name="mode"
                              className="form-check-input"
                              defaultChecked={gender == "1"}
                              onChange={(e) => { setGender(e.target.value) }}
                            />
                            <Label
                              className="form-check-label font-size-13"
                              htmlFor="customRadioInline1"
                            >
                              Male
                            </Label>


                            <input
                              type="radio"
                              value="2"
                              id="customRadioInline2"
                              name="mode"
                              className="form-check-input"
                              defaultChecked={gender == '2'}
                              onChange={(e) => { setGender(e.target.value) }}
                            />
                            <Label
                              className="form-check-label font-size-13"
                              htmlFor="customRadioInline2"
                            >
                              Female
                            </Label>
                          </div>
                        </div>
                      </Col>


                      <Col md="9">
                        <Row>
                          <Col md={3}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom01"
                                className="required"
                              >
                                Role
                              </Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                // id="validationCustom01"
                                className="form-control-sm"
                                name="RoleId"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.RoleId}
                                invalid={
                                  validation.touched.RoleId &&
                                    validation.errors.RoleId
                                    ? true
                                    : false
                                }
                              >
                                <option value="">Select User</option>
                                {GetObjectsByValues(2, 4).map((item, index) => {
                                  return (
                                    <option key={index} value={item.Value}>
                                      {item.DisplayName}
                                    </option>
                                  )
                                })}
                              </Input>
                              {validation.touched.RoleId &&
                                validation.errors.RoleId ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.RoleId}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom03"
                                className="required"
                              >
                                Shop Name
                              </Label>
                              <Input
                                name="ShopName"
                                // disabled={shopName}
                                placeholder="Shop Name"
                                type="text"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ShopName || ''}
                                invalid={
                                  validation.touched.ShopName &&
                                    validation.errors.ShopName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.ShopName &&
                                validation.errors.ShopName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.ShopName}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom01"
                                className="required"
                              >
                                Full Name
                              </Label>
                              <Input
                                name="FullName"
                                placeholder="Full Name"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.FullName || ""}
                                invalid={
                                  validation.touched.FullName &&
                                    validation.errors.FullName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.FullName &&
                                validation.errors.FullName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.FullName}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom01"
                                className="required"
                              >
                                Contact No
                              </Label>
                              <Input
                                name="ContactNo"
                                placeholder="Phone Number"
                                maxLength="10"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ContactNo || ""}
                                invalid={
                                  validation.touched.ContactNo &&
                                    validation.errors.ContactNo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.ContactNo &&
                                validation.errors.ContactNo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.ContactNo}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <div className="mb-2">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="Email"
                                placeholder="Email Address"
                                type="Email"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Email || ""}
                                invalid={
                                  validation.touched.Email &&
                                    validation.errors.Email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Email &&
                                validation.errors.Email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className="required"
                              >
                                Username
                              </Label>
                              <Input
                                name="Username"
                                placeholder="Username"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Username || ""}
                                invalid={
                                  validation.touched.Username &&
                                    validation.errors.Username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Username &&
                                validation.errors.Username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Username}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <div className="mb-2">
                              <Label className="form-label required">
                                Password
                              </Label>
                              <Input
                                name="Password"
                                value={validation.values.Password || ""}
                                type="Password"
                                className="form-control-sm"
                                placeholder="Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.Password &&
                                    validation.errors.Password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Password &&
                                validation.errors.Password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        {/* <Row>
                          <Col md="4">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className=""
                              >
                                Latitude
                              </Label>
                              <Input
                                name="Latitude"
                                placeholder="Latitude"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Latitude || ""}
                                invalid={
                                  validation.touched.Latitude &&
                                    validation.errors.Latitude
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Latitude &&
                                validation.errors.Latitude ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Latitude}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <div className="mb-2">
                              <Label className="form-label">
                                Longitude
                              </Label>
                              <Input
                                name="Longitude"
                                value={validation.values.Longitude || ""}
                                type="Longitude"
                                className="form-control-sm"
                                placeholder="Longitude"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.Longitude &&
                                    validation.errors.Longitude
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Longitude &&
                                validation.errors.Longitude ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Longitude}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row> */}

                        <Row>
                          <Col md="6">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom04"
                                className=""
                              >
                                Address
                              </Label>
                              <Input
                                name="Address"
                                rows="2"
                                placeholder="Type your address here"
                                type="textarea"
                                className="form-control-sm"
                                id="validationCustom04"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Address || ""}
                                invalid={
                                  validation.touched.Address &&
                                    validation.errors.Address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Address &&
                                validation.errors.Address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Address}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label for="remark">Remarks</Label>
                              <Input
                                type="textarea"
                                className="form-control-sm"
                                name="remark"
                                id="remark"
                                row={2}
                                placeholder="Enter your remarks"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.remark}
                                invalid={
                                  validation.touched.remark &&
                                    validation.errors.remark
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.remark &&
                                validation.errors.remark ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.remark}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="text-end ">
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2"
                          type="reset"
                        >
                          Reset
                        </Button>
                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Adduser
