import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { Booking_status } from "_mock/Static_data"
import ProductView from "./ProductView"
import DeleteModel from "../../common/deleteModel/deleteModel"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import BookingTable from "pages/Reports/Booking/BookingTable"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import jsPDF from "jspdf"
import { InvoiceHtml } from "pages/Reports/Booking/invoicePDF"
import { font } from "pages/Reports/Booking/font"
import Loader from "common/Loader"
import { routes } from "routes/path"
import useApi from "common/ApiMiddlewere"

function Booking() {
  document.title = `Bookings | ${localStorage.getItem("ShopName")}`
  const { deleteApi, getApi, postApi, putApi } = useApi()

  const getBase64FromUrl = async url => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  }

  useEffect(() => {}, [])

  const navigate = useNavigate()
  const ref = useRef(null)
  let { pathname } = useLocation()

  // useStates
  const [tableData, setTableData] = useState([])
  const [modal, setModal] = useState(false)
  const [productViewId, setProductViewId] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [error, setError] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchText, setSearchText] = useState("")
  const [statusCancel, setStatusCancel] = useState(false)
  const [status, setStatus] = useState(0)
  const [formDate, setFormDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const [loader, setloader] = useState(false)
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10))
  const [profileImage, setProfileImage] = useState(null)
  const [displayStart, setDisplayStart] = useState(0)
  const [TotalRecords, setTotalRecords] = useState(0.0)

  const filterData = textFilter => {}

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
  }

  const statusValue = status => {
    if (status) {
      setStatus(status)
    }
  }

  const dateValue = (deliveryDate, returnDate) => {
    setFormDate(deliveryDate)
    setToDate(returnDate)
  }

  // handlers
  const toggle = () => {
    setModal(!modal)
  }

  const bookingReportApi = () => {
    setIsLoading(true)
    postApi("Booking/booking-list", {
      fromDate: formDate ? formDate : new Date().toISOString().slice(0, 10),
      toDate: toDate ? toDate : new Date().toISOString().slice(0, 10),
      displayStart: displayStart,
      status: parseInt(status),
      pageSize: rowsPerPage,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.BookingList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // booking list API useEffect
  useEffect(() => {
    setStatusCancel(false)
    bookingReportApi()
  }, [
    modal,
    statusCancel,
    status,
    formDate,
    toDate,
    rowsPerPage,
    searchText,
    displayStart,
  ])

  //  Delete Booking handler
  const handleDelete = bookingDeleteId => {
    if (bookingDeleteId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this booking permanently? ",
      }).then(response => {
        if (response == true) {
          deleteApi(`booking/delete?id=${bookingDeleteId}`).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== bookingDeleteId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("Booking deleted successfully", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Booking",
                  text: data,
                })
              }
            }
          )
        }
      })
    }
  }

  // Booking table in change status handler
  const handleBookingStatus = (bookingId, statusId) => {
    if ((bookingId, statusId)) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure you want to change status?",
        text: "If you change the status of this item, the status of all the product inside it will be changed.",
      }).then(response => {
        if (response == true) {
          const productId = ""
          putApi("Booking/change-Status", {
            bookingId: bookingId == "" ? 0 : bookingId,
            bookingItemId: productId == "" ? 0 : productId,
            BookingStatus: parseInt(statusId),
          }).then(({ data, status }) => {
            if (status === 200) {
              toast.success("Status change sucessfully", {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
          })
        } else {
          setStatusCancel(true)
        }
      })
    }
  }

  // Update  Booking handler
  const handleEditBooking = bookingEditId => {
    if (bookingEditId) {
      setIsEdit(true)
      getApi(`Booking/details?id=${String(bookingEditId)}`).then(
        ({ data, status }) => {
          if (status === 200) {
            navigate(routes.updatebooking, {
              state: {
                data,
                booking: false,
                isEdit: true,
              },
            })
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        }
      )
    }
  }

  // view button : Product View handler
  const handleProductView = bookingViewId => {
    if (bookingViewId) {
      setProductViewId(bookingViewId)
      toggle()
    }
  }

  // view booking
  const handleViewBooking = id => {
    getApi(`Booking/invoice-detail?id=${String(id)}`).then(
      ({ data, status }) => {
        if (status === 200) {
          navigate(routes.viewBooking, {
            state: {
              data,
              isEdit: true,
            },
          })
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      }
    )
  }

  const handlePrintPDf = async id => {
    // console.log("first")
    setloader(true)
    const {
      data: { ProfilePic },
    } = await getApi(`User/user-details?id=-1`) // async function name() {

    const html = await InvoiceHtml(id, getApi)
    // console.log(html)
    const doc = new jsPDF({
      format: "a5",
      unit: "px",
      filters: ["ASCIIHexEncode"],
    })
    // var imgData = "data:image/jpeg;base64," + imagebase64
    doc.addFileToVFS("NotoSansGujarati-Regular.ttf", font)
    doc.addFont(
      "NotoSansGujarati-Regular.ttf",
      "NotoSansGujarati-Regular",
      "normal"
    )
    if (ProfilePic) {
      doc.addImage(await getBase64FromUrl(ProfilePic), "PNG", 16, 19, 45, 35)
    }
    doc.setFont("NotoSansGujarati-Regular") // set font
    doc.html(html, {
      async callback(doc) {
        setloader(false)

        await doc.autoPrint()

        window.open(doc.output("bloburl"), "_blank")
      },
      margin: [10, 10, 10, 10],
    })
  }

  const handleDownloadPdf = async id => {
    const {
      data: { ProfilePic },
    } = await getApi(`User/user-details?id=-1`)

    setloader(true)
    const html = await InvoiceHtml(id, getApi)

    const doc = new jsPDF({
      format: "a5",
      unit: "px",
      filters: ["ASCIIHexEncode"],
      compress: true,
    })
    // var imgData = "data:image/jpeg;base64," + imagebase64
    doc.addFileToVFS("NotoSansGujarati-Regular.ttf", font)
    doc.addFont(
      "NotoSansGujarati-Regular.ttf",
      "NotoSansGujarati-Regular",
      "normal"
    )
    if (ProfilePic) {
      doc.addImage(
        await getBase64FromUrl(
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJNsszLg4hq5LhCVG6cAYC9Lv5-BEDO13tdhO1V8g-yZt2RoDeXluUnLZpUIDaPf0ji28&usqp=CAU"
        ),
        "PNG",
        15,
        17,
        45,
        35,
        "MEDIUM"
      )
      console.log(
        await getBase64FromUrl(
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJNsszLg4hq5LhCVG6cAYC9Lv5-BEDO13tdhO1V8g-yZt2RoDeXluUnLZpUIDaPf0ji28&usqp=CAU"
        )
      )
    }
    doc.setFont("NotoSansGujarati-Regular") // set font
    doc.html(html, {
      async callback(doc) {
        setloader(false)
        await doc.save("invode.pdf")
      },
      margin: [10, 10, 10, 10],
    })
  }
  // Booking table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "4%",
        Cell: ({ row }) => (
          <div
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleViewBooking(row.original.Id)
            }}
          >
            {row.original.BillNo}
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "6%",
      },
      {
        Header: "Rent",
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Rent.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Advance",
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Advance.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        width: "7%",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks}`}
          >
            {row.original.Remarks.length > 15
              ? `${row.original.Remarks.substr(0, 15)}...`
              : row.original.Remarks == ""
              ? "N/A"
              : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: "Total Product",
        accessor: "TotalProduct",
        width: "4%",
        Cell: ({ row }) => (
          <div
            className="text-primary , text-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleProductView(row.original.Id)
            }}
          >
            {row.original.TotalProduct}
          </div>
        ),
      },
      {
        Header: "Booking Date",
        accessor: "BookingDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.BookingDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.BookingDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Status
          </div>
        ),
        width: "8%",
        // width: 100,
        accessor: "MinStatus",
        Cell: ({ row }) => (
          <div
            className="textWrap"
            style={{
              height: "8px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Input
              type="select"
              onChange={e =>
                handleBookingStatus(row.original.Id, e.target.value)
              }
              defaultValue={row.original.MinStatus}
              bsSize="sm"
              className=" form-control"
            >
              {Booking_status.map((item, index) => {
                return (
                  <option key={index} value={item.Value}>
                    {item.DisplayName}
                  </option>
                )
              })}
            </Input>
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "Action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li>
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEditBooking(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`edittooltip${row.original.Id}`}
                >
                  Edit
                </UncontrolledTooltip>
              </li>
              <li>
                <i
                  className=" fs-6 fas fa-print btn-edit "
                  id={`Print${row.original.Id}`}
                  onClick={() => handlePrintPDf(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target={`Print${row.original.Id}`}
                >
                  Print
                </UncontrolledTooltip>
              </li>
              <li>
                <i
                  className=" fs-6 fas fa-download "
                  style={{ color: "#f1b44c", cursor: "pointer" }}
                  id={`Download${row.original.Id}`}
                  onClick={() => handleDownloadPdf(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target={`Download${row.original.Id}`}
                >
                  Download
                </UncontrolledTooltip>
              </li>

              <li>
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  // Create Booking handler
  const handleBookingClicks = () => {
    navigate(routes.createbooking)
  }

  // if (loader) {
  //   return <Loader />
  // }

  return (
    <div className="page-content background">
      <div className="container-fluid">
        {loader && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <BookingTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              dateValue={dateValue}
              isLoading={isLoading}
              filterData={filterData}
              pagePerSize={pagePerSize}
              statusValue={statusValue}
              TotalRecords={TotalRecords}
              className="custom-header-css"
            />

            <Modal isOpen={modal} toggle={toggle} centered={true} size="small">
              <div className="modal-content">
                <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                  Products
                </ModalHeader>
                <ModalBody>
                  <ProductView productViewId={productViewId} />
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Booking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Booking
