import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup, Label } from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import { useState } from "react"
import { GetVerificationStatus } from "_mock/Static_data"

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
    filterData,
    value,
    setValue,
}) {
    const count = preGlobalFilteredRows.length
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
        filterData(value)
    }, 200)

    return (
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search…"}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

const ProductVerificationTable = ({
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    className,
    filterData,
    pagePerSize,
    isLoading,
    TotalRecords,
    categories,
    handleUserClick,
    isCreateProduct,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            manualPagination: true,
            initialState: {
                pageSize: 10,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )
    const [pageIndex, setpageIndex] = useState(1)
    const [prevValue, setPrevValue] = useState();

    const handlesort = column => { }

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    const [value, setValue] = React.useState()
    const [isRetire, setIsRetire] = useState(false)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [categoryChange, setCategoryChange] = useState(0)
    const [statusChange, setStatusChange] = useState('0')

    useEffect(() => {
        pagePerSize(pageSize, pageIndex, value, statusChange, categoryChange)
        setPrevValue(value);
    }, [pageSize, value, pageIndex, statusChange, categoryChange])

    useEffect(() => {
        if (value !== prevValue) {
            setpageIndex(1);
        }
    }, [value]);

    useEffect(() => {
        if (isRetire == true) {
            setpageIndex(1);
        }
    }, [isRetire]);


    return (
        <Fragment>
            <Row >
                <Col md="8" >
                    <Row>
                        <Col md="2" className="table-header">
                            <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col md="3" className="table-header">
                            {isGlobalFilter && (
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    isJobListGlobalFilter={isJobListGlobalFilter}
                                    filterData={filterData}
                                    value={value}
                                    setValue={setValue}
                                />
                            )}
                        </Col>
                        <Col md="4" style={{ marginBottom: "14px" }}
                            className={`${isMobile ? 'retireProductStyle' : ''} d-flex align-items-center text-nowrap`}
                        >
                            <Input
                                type="select"
                                className="form-select"
                                // style={{ cursor: "pointer" }}
                                value={categoryChange}
                                onChange={e => setCategoryChange(e.target.value)}
                            >
                                <option value={0}>Categories</option>
                                {categories.map((item, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={item.id}
                                            disabled={!item.isLast}
                                            className={item.isLast ? '' : 'category-disabled'}
                                        >
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </Input>

                        </Col>
                        <Col md="3" style={{ marginBottom: "14px" }}
                            className={`${isMobile ? 'retireProductStyle' : ''} d-flex align-items-center text-nowrap`}
                        >
                            <Input
                                type="select"
                                className="form-select"
                                // style={{ cursor: "pointer" }}
                                value={statusChange}
                                onChange={e => setStatusChange(e.target.value)}
                            >
                                {GetVerificationStatus.map((item, index) => (
                                    <option key={index} value={item.Key}>{item.DisplayName}</option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                </Col>


                <Col md="4" style={{ textAlign: isMobile ? "start" : "end" }}
                >
                    <Row>
                        <Col md="12" className="table-header">
                            {isCreateProduct && (
                                <div className="text-sm-end">
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn  mb-2 "
                                        onClick={handleUserClick}
                                    >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Create Product
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        onClick={() => {
                                            handlesort(column)
                                        }}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className="text-nowrap"
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? "Loading..." : "No Record Found"}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>

            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            color="primary"
                            onClick={() => setpageIndex(pageIndex - 1)}
                            disabled={pageIndex == 1}
                        >
                            {"<<"}
                        </Button>
                    </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                    Page<strong>
                        {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
                    </strong> | Total <strong>{TotalRecords}</strong>
                </Col>
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            color="primary"
                            onClick={() => setpageIndex(pageIndex + 1)}
                            disabled={pageIndex == (pageIndex == 1 && TotalRecords == 0 ? Math.ceil(TotalRecords / pageSize) + 1 : Math.ceil(TotalRecords / pageSize))}
                        >
                            {">>"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Fragment >

    )
}

ProductVerificationTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}


export default ProductVerificationTable