import { routes } from "routes/path"

const breadcrumbs = {
  [routes.dashboard]: {
    title: "Dashboard",
    breadcrumbItem: "Dashboard",
  },
  [routes.userCreate]: {
    parent: "Users",
    breadcrumbItem: "Create User",
    parentLink: routes.userList,
  },
  [routes.userUpdate]: {
    parent: "Users",
    breadcrumbItem: "Update User",
    parentLink: routes.userList,
  },
  [routes.userList]: {
    title: "Users",
    breadcrumbItem: "Users",
    link: routes.userList,
  },
  [routes.inquiries]: {
    title: "Inquiries",
    breadcrumbItem: "Inquiries",
  },
  [routes.plan]: {
    title: "Plans",
    breadcrumbItem: "Plans",
  },
  [routes.feedback]: {
    title: "Feedback",
    breadcrumbItem: "Feedback",
  },
  [routes.setting]: {
    title: "Settings",
    breadcrumbItem: "Settings",
  },
  [routes.dbBackup]: {
    title: "Db-Backup",
    breadcrumbItem: "Db-Backup",
  },
  [routes.subscription]: {
    title: "Subscription",
    breadcrumbItem: "Subscription",
  },
  [routes.categories]: {
    title: "Categories",
    breadcrumbItem: "Categories",
  },
  [routes.size]: {
    title: "Size",
    breadcrumbItem: "Size",
  },
  [routes.color]: {
    title: "Colors",
    breadcrumbItem: "Colors",
  },
  [routes.profile]: {
    title: "User Profile",
    breadcrumbItem: "User Profile",
  },
  [routes.productList]: {
    title: "Products",
    breadcrumbItem: "Products",
  },
  [routes.productCreate]: {
    parent: "Products",
    title: "Create Product",
    breadcrumbItem: "create Product",
    parentLink: routes.productList,
  },
  [routes.productUpdate]: {
    parent: "Products",
    title: "Update Product",
    breadcrumbItem: "Update Products",
    parentLink: routes.productList,
  },
  [routes.productVerification]: {
    title: "Product-Verification",
    breadcrumbItem: "Product-Verification",
  },
  [routes.productVerificationUpdate]: {
    parent: "Product-Verification",
    title: "Update Product",
    breadcrumbItem: "Update Product",
    parentLink: routes.productVerification,
  },
  [routes.advertisement]: {
    title: "Advertisement",
    breadcrumbItem: "Advertisement",
  },
  [routes.popularCategories]: {
    title: "Popular Categories",
    breadcrumbItem: "Popular Categories",
  },
  [routes.appVersion]: {
    title: "AppVersion",
    breadcrumbItem: "AppVersion",
  },
  // [routes.timeslot]: {
  //   title: "Time Slots",
  //   breadcrumbItem: "Time Slots",
  // },
  // [routes.createProduct]: {
  //   parent: "Products",
  //   breadcrumbItem: "Create Product",
  //   parentLink: routes.productList,
  // },
  // [routes.productList]: {
  //   title: "Products",
  //   breadcrumbItem: "Products",
  // },
  // [routes.productHistory]: {
  //   title: "Product History",
  //   breadcrumbItem: "Product History",
  // },
  // [routes.createbooking]: {
  //   parent: "Bookings",
  //   breadcrumbItem: "Create Booking",
  //   parentLink: routes.bookingList,
  // },
  // [routes.updatebooking]: {
  //   parent: "Bookings",
  //   breadcrumbItem: "Update Booking",
  //   parentLink: routes.bookingList,
  // },
  // [routes.bookingList]: {
  //   title: "Bookings",
  //   breadcrumbItem: "Bookings",
  // },
  // [routes.cartlist]: {
  //   title: "Cart",
  //   breadcrumbItem: "Cart",
  // },
  // [routes.reportBooking]: {
  //   title: "Bookings",
  //   breadcrumbItem: "Bookings",
  // },
  // [routes.delivery]: {
  //   title: "Deliveries",
  //   breadcrumbItem: "Deliveries",
  // },
  // [routes.return]: {
  //   title: "Returns",
  //   breadcrumbItem: "Returns",
  // },
  // [routes.salesman]: {
  //   title: "Salesman",
  //   breadcrumbItem: "Salesman",
  // },
  // [routes.salesReport]: {
  //   title: "Sales",
  //   breadcrumbItem: "Sales",
  // },
  // [routes.available]: {
  //   title: "Available",
  //   breadcrumbItem: "Available",
  // },

}

export default breadcrumbs