import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { routes } from "routes/path"
import { postApi } from "common/ApiMiddlewere"

// const { RoleName } = JSON.parse(localStorage.getItem("authUser"))

const SidebarContent = props => {
  const [RoleName, setRoleName] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  useEffect(() => { }, [props.success])

  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()

  const activeMenu = useCallback(() => {
    let pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName == routes.userCreate || pathName == routes.userUpdate) {
        pathName = routes.userList
      }
      if (
        pathName == routes.productCreate ||
        pathName == routes.productUpdate
      ) {
        pathName = routes.productList
      }
      if (
        pathName == routes.createbooking ||
        pathName == routes.updatebooking ||
        pathName == routes.viewBooking
      ) {
        pathName = routes.bookingList
      }
      if (
        pathName == routes.productVerification ||
        pathName == routes.productVerificationUpdate
      ) {
        pathName = routes.productVerification
      }
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])


  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <>
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Dashboard")} </li>
              <li>
                <Link to={routes.dashboard}>
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("Management")}</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Master")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={routes.userList}>{props.t("Users")}</Link>
                  </li>
                  <li>
                    <Link to={routes.popularCategories}>{props.t("Popular Categories")}</Link>
                  </li>
                  <li>
                    <Link to={routes.categories}>{props.t("Categories")}</Link>
                  </li>
                  <li>
                    <Link to={routes.appVersion}>{props.t("AppVersion")}</Link>
                  </li>
                  {/* <li>
                    <Link to={routes.plan}>{props.t("Color")}</Link>
                  </li> */}
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="  bx bxs-detail"></i>
                  <span>{props.t("Inventory")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={routes.productList}>{props.t("Products")}</Link>
                  </li>
                  <li>
                    <Link to={routes.productVerification}>{props.t("Product Verification")}</Link>
                  </li>
                  {/* <li>
                      <Link to={routes.uploadImage}>{props.t("Upload Image")}</Link>
                    </li> */}
                </ul>
              </li>

              <li>
                <Link to={routes.setting}>
                  <i className="bx bxs-cog"></i>
                  <span>{props.t("Setting")}</span>
                </Link>
              </li>
              <li>
                  <Link to={routes.dbBackup}>
                    <i className="bx bxs-data"></i>
                    <span>{props.t("Db-Backup")}</span>
                  </Link>
                </li>
              <li>
                <Link to={routes.advertisement}>
                  <i className="bx bx-broadcast"></i>
                  <span>{props.t("Advertisement")}</span>
                </Link>
              </li>

            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>

      {/* {RoleName.roleName == "Admin" ? (
        <React.Fragment>
          <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{props.t("Dashboard")} </li>
                <li>
                  <Link to={routes.dashboard}>
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li className="menu-title">{props.t("Management")}</li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Master")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.userList}>{props.t("Users")}</Link>
                    </li>
                    <li>
                      <Link to={routes.inquiries}>{props.t("Popular Categories")}</Link>
                    </li>
                    <li>
                      <Link to={routes.plan}>{props.t("Categories")}</Link>
                    </li>
                    <li>
                      <Link to={routes.plan}>{props.t("Color")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="  bx bxs-detail"></i>
                    <span>{props.t("Inventory")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.productList}>{props.t("Products")}</Link>
                    </li>
                    <li>
                      <Link to={routes.productVerification}>{props.t("Product Verification")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      ) : RoleName.roleName == "Vendor" ? (
        <React.Fragment>
          <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{props.t("Dashboard")} </li>
                <li>
                  <Link to={routes.dashboard}>
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes.available}>
                    <i className="bx bx-calendar-check"></i>
                    <span>{props.t("Available")}</span>
                  </Link>
                </li>

                <li className="menu-title">{props.t("Menu")}</li>

                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Master")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.userList}>{props.t("Users")}</Link>
                    </li>
                    <li>
                      <Link to={routes.categories}>
                        {props.t("Categories")}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.size}>{props.t("Size")}</Link>
                    </li>
                    <li>
                      <Link to={routes.color}>{props.t("Colors")}</Link>
                    </li>
                    <li>
                      <Link to={routes.timeslot}>{props.t("Time Slots")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-store"></i>
                    <span>{props.t("Inventory")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.productList}>{props.t("Products")}</Link>
                    </li>
                    <li>
                      <Link to={routes.importProduct}>
                        {props.t("Import Product")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-money"></i>
                    <span>{props.t("Transaction")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.bookingList}>{props.t("Bookings")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="  bx bxs-detail"></i>
                    <span>{props.t("Report")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to={routes.delivery}>{props.t("Delivery")}</Link>
                    </li>
                    <li>
                      <Link to={routes.return}>{props.t("Return")}</Link>
                    </li>
                    <li>
                      <Link to={routes.productHistory}>
                        {props.t("Product History")}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.salesReport}>
                        {props.t("Sales")}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.salesman}>{props.t("Salesman")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      ) : (
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to={routes.available}>
                  <i className="fas fa-calendar-check"></i>
                  <span>{props.t("Available")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      )} */}
    </>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
