import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button,FormGroup} from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import { useEffect } from "react"

const DBBackupDataTable = ({
    columns,
    data,
    className,
    pagePerSize,
    isLoading,
    handleBackupDatebase,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: 3000,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )

    if (pagePerSize) {
        useEffect(() => {
            pagePerSize(pageSize)
        }, [pageSize, state.globalFilter])
    }

    return (
        <Fragment>
            <Row>
                <Col md={12} style={{ marginTop: "22px", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <FormGroup className="mb-2">
                        <Button
                            type="button"
                            color="primary"
                            className="btn "
                            onClick={handleBackupDatebase}
                        >
                            Backup Database
                        </Button>
                    </FormGroup>
                </Col>
            </Row>


            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className={`text-nowrap`}
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? ("Loading...") : ("No Record Found")}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>

        </Fragment>
    )
}

DBBackupDataTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default DBBackupDataTable