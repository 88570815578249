import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SalesmanTable from "./SalesmanTable"
import { exportDataExcel } from "pages/ExportData"

function Salesman() {
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const { getApi, postApi } = useApi()

  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectMonth, setSelectMonth] = useState()
  const [salesmanSelectId, setSalesmanSelectId] = useState(0)
  const [salemanList, setSalesmanList] = useState([])

  // formDate & toDate
  const dateValue = (month, saleamanId) => {
    setSalesmanSelectId(saleamanId)
    setSelectMonth(month)
  }

  // return api useEffect
  useEffect(() => {
    getApi("User/salesman-lookup").then(({ data, status }) => {
      if (status === 200) {
        setSalesmanList(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [])

  useEffect(() => {
    saleamanReportApi()
  }, [selectMonth, salesmanSelectId])

  // returnList api
  const saleamanReportApi = () => {
    if (salesmanSelectId > 0) {
      setIsLoading(true)
      postApi("Report/salesman-report", {
        salesmanId: salesmanSelectId,
        month: selectMonth
          ? selectMonth
          : new Date().toISOString().slice(0, 10),
      }).then(({ data, status }) => {
        if (status === 200) {
          setIsLoading(false)
          setTableData(data)
        } else {
          setIsLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    } else {
      setTableData([])
    }
  }

  function writeDataToExcel() {

    let header = ["Salesman Name", "No. of Bill", "No. of Product", "Bill Date"];

    const mainTitle = "Salesman Report";
    const extention = "Salesman_Report.xlsx";
    const widthObj = [{
      Key: "A",
      Value: 17,
    },
    {
      Key: "B",
      Value: 12,
    },
    {
      Key: "C",
      Value: 15,
    },
    {
      Key: "D",
      Value: 12,
    },
    ]
    let excelSheetData = []
    for (let index = 0; index < tableData.length; index++) {
      let dataObj = {
        SalesmanName: tableData[index].SalesmanName,
        NoOfBill: tableData[index].NoOfBill,
        NoOfProduct: tableData[index].NoOfProduct,
        BillDate: dateFormat(tableData[index].BillDate.split("T")[0],
          "dd/MM/yyyy"),
      }

      excelSheetData.push(dataObj)
    }
    var fields = Object.keys(excelSheetData[0]);

    var sheetData = excelSheetData.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName];
      });
    });
    sheetData.unshift(header);
    exportDataExcel(sheetData, mainTitle, widthObj, extention, '', '')
  }
  // header and accessor
  const columns = useMemo(
    () => [
      {
        Header: "Salesman Name",
        accessor: "SalesmanName",
      },
      {
        Header: "No. of Bill",
        accessor: "NoOfBill",
        width: "5%",
        Cell: ({ row }) => (
          <div className="text-center">{row.original.NoOfBill}</div>
        ),
      },
      {
        Header: "No. of Product",
        accessor: "NoOfProduct",
        width: "5%",
        Cell: ({ row }) => (
          <div className="text-center">{row.original.NoOfProduct}</div>
        ),
      },
      {
        Header: "Bill Date",
        accessor: "BillDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className="mb-0">
            {row.original.BillDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.BillDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
    ],
    [tableData]
  )

  document.title = `Salesman | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SalesmanTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              dateValue={dateValue}
              isLoading={isLoading}
              salemanList={salemanList}
              className="custom-header-css"
              writeDataToExcel={writeDataToExcel}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Salesman.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Salesman
