import React, { useEffect, useMemo, useState } from "react"
import Select from "react-select";
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Compressor from "compressorjs"

import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import * as Yup from "yup"

import { useFormik } from "formik"
import DeleteModel from "common/deleteModel/deleteModel"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import PopularCategoryTable from "./PopularCategoryTable"
import Dropzone from "react-dropzone"
import uploadImg from "../../../assets/images/upload_img.png"


const customStyles = {
  control: () => ({
    label: "control",
    alignItems: "center",
    cursor: "default",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    outline: "0 !important",
    position: "relative",
    transition: "all 100ms",
    backgroundColor: "hsl(0, 0%, 100%)",
    borderColor: "hsl(0, 0%, 80%)",
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    '&:hover': {
      borderColor: "none",
    },
    boxSizing: "border-box",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    width: "395px",
    borderRadius: "0"
  }),
};

const customStyles1 = {
  control: () => ({
    label: "control",
    alignItems: "center",
    cursor: "default",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    outline: "0 !important",
    position: "relative",
    transition: "all 100ms",
    backgroundColor: "hsl(0, 0%, 100%)",
    borderColor: "#f46a6a",
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    '&:hover': {
      borderColor: "none",
    },
    boxSizing: "border-box",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    width: "395px",
    borderRadius: "0"
  }),
};

let PopularCategories = []
function PopularCategoriesList() {
  document.title = `Popular Categories | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { deleteApi, getApi, postApi, putApi } = useApi()

  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState(null)
  const [tableData, setTableData] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [popularCategories, setPopularCategories] = useState()
  const [idList, setIdList] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [isError, setIsError] = useState(false)
  const [oldCategoriesIds, setOldCategoriesIds] = useState()

  useEffect(() => {
    getApi("api/Categories/all", '').then(({ data, status }) => {
      if (status === 200) {
        const categories = data.map(item => ({
          label: item.name,
          value: item.id,
          Id: item.id,
          isLast: item.isLast,
          isDisabled: item.isLast == false
        }));
        setPopularCategories(categories)
        PopularCategories = categories
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [])

  // filter data for popular category
  const filterData = textFilter => {
    setSearchText(textFilter == undefined ? '' : textFilter)
  }

  // popular category list api
  const PopularCategoryListApi = () => {
    setIsLoading(true)
    getApi("api/PopularCategory/list", '').then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
        setTotalRecords(data.length)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // popular category list api useEffect
  useEffect(() => {
    PopularCategoryListApi()
  }, [searchText])


  // Delete popular category handler
  const handleDelete = categoryId => {
    if (categoryId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this category permanently? ",
      }).then(response => {
        if (response == true) {
          setIsLoading(true)
          deleteApi(`api/PopularCategory/delete?id=${categoryId}`).then(
            async ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.id !== categoryId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("Sucessfully deleted", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Category",
                  text: data,
                })
              }
              setIsLoading(false)
            }
          )
        }
      })
    }
  }

  const handleSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
    const categoryIds = new Set(selectedOptions.map(item => item.Id));
    const categoryIdsArray = [...categoryIds];
    setIdList(categoryIdsArray.join(','));
  };

  // cancle handler
  const handleCancle = () => {
    toggle()
  }

  // Update popular category handler
  const handleEditPopularCategory = popularCategoryData => {
    if (popularCategoryData.id) {
      setCategoryId(popularCategoryData.id)
      setIsLoading(true)
      getApi(`api/PopularCategory/details?id=${popularCategoryData.id}`, '').then(({ data, status }) => {
        if (status === 200) {
          setIsEdit(true)
          setModal(!modal)
          setEditData(data)
          setPreviewUrl(data.imageUrl)
          const selectedCategoriesIds = data?.categoryIds?.split(',').map(Number);
          const selectedCategories = PopularCategories?.filter(category => selectedCategoriesIds?.includes(category?.Id));
          setSelectedValues(selectedCategories)
          setOldCategoriesIds(data?.categoryIds)
          setIdList(data.categoryIds)
        } else {
          setIsLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
        setIsLoading(false)
      })
    }
  }

  // Create popular category handler
  const handlePopularCategoryClick = () => {
    setIsEdit(false)
    setEditData('')
    setPreviewUrl('')
    setSelectedValues('')
    toggle()
  }

  // Popular Categories Validations
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      profilePic: isEdit ? editData?.imageUrl : "",
      title: isEdit ? editData?.title : "",
      sortOrder: isEdit ? editData?.sortOrder : '',
      isActive: isEdit ? editData?.isActive : false
    },
    validationSchema: Yup.object({
      profilePic: Yup.string().required("The profilePic is required"),
      title: Yup.string().required("The title is required"),
      sortOrder: Yup.string().required("The sort order is required"),
    }),
    onSubmit: values => {

      if (idList == "" || idList == undefined) {
        setIsError(true)
        return false
      } else {
        setIsError(false)
      }
      setformSumitLoading(true)
      if (values) {
        isEdit
          ? putApi("api/PopularCategory/edit", {
            id: categoryId,
            title: values.title,
            imageBase: values.profilePic.split(',')[1],
            categoryIds: idList,
            isActive: values.isActive,
            sortOrder: parseInt(values.sortOrder)
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 200) {
              toast.success("Sucessfully updated", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              PopularCategoryListApi()
              validation.handleReset()
              setPreviewUrl('')
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
          })
          : postApi("api/PopularCategory/add", {
            title: values.title,
            imageBase: values.profilePic.split(',')[1],
            categoryIds: idList,
            isActive: values.isActive,
            sortOrder: parseInt(values.sortOrder)
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 200) {
              toast.success("Sucessfully created", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              PopularCategoryListApi()
              validation.handleReset()
              setPreviewUrl('')
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
          })
      }
    },
  })

  // popup box handler
  const toggle = () => {
    setModal(!modal)
    validation.handleReset()
  }

  const handleSwitch = (id, isActive, e) => {
    if (id) {
      postApi(`api/PopularCategory/active-inactive?id=${id}`, '').then(({ data, status }) => {
        e.preventDefault()
        if (status === 200) {
          toast.success("Popular-Category sucessfully changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          $("#isActive_" + id + "").prop("checked", isActive)
          ErrorModel.errormodel({
            title: "Verify Popular-Category",
            text: data,
          })
        }
      })
    }
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Picture",
        accessor: "imageUrl",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                className=""
                style={{ textAlign: 'center' }}
                src={row.original.imageUrl}
                height={40}
                width={40}
              />
            </div>
          )
        },
      },
      {
        Header: "Popular Category",
        accessor: "title",
      },
      {
        Header: "Sort Order",
        accessor: "sortOrder",
        width: "5%",
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {row.original.sortOrder}
            </div>
          )
        },
      },
      {
        Header: "Is Active",
        accessor: "isActive",
        width: "8%",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`isActive_${row.original.id}`}
              // disabled={row.original.IsRetire == true}
              defaultChecked={row.original.isActive}
              onClick={e => {
                handleSwitch(row.original.id, row.original.isActive, e)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "7%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.id}`}
                  onClick={() => handleEditPopularCategory(row.original)}
                ></i>
                <span className="mytext">Edit</span>
              </li>


              <li className="mytooltip">
                <i
                  id={`deletetooltip${row.original.id}`}
                  className="fs-6 fas fa-trash-alt btn-delete"
                  onClick={() => handleDelete(row.original.id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>


              {/* <li>
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.id}`}
                  onClick={() => handleDelete(row.original.id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li> */}
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  // profile pic convert toBase64 handle
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const handleDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    // setfileFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
      success: async compressedResult => {
        setPreviewUrl(URL.createObjectURL(compressedResult))
        validation.setFieldValue("profilePic", await toBase64(compressedResult))
      },
    })
  }

  // profilepic display & set
  const handleclick = () => {
    validation.setFieldValue("profilePic", null)
    setPreviewUrl(null)
  }

  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <PopularCategoryTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isPopolarCategoryList={true}
              handlePopularCategoryClick={handlePopularCategoryClick}
              customPageSize={10}
              className="custom-header-css"
              filterData={filterData}
              isLoading={isLoading}
              TotalRecords={totalRecords}
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {isEdit ? "Update" : "Create"} Popular Category
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Dropzone
                            onDrop={handleDrop}
                            onClick={handleDrop}
                            accept="image/*"
                            multiple={false}
                            id="profilePic"
                            name="profilePic"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div className="d-flex flex-column align-items-center text-center p-2 py-0">
                                  <input {...getInputProps()} />
                                  <div
                                    // className="rounded-circle"
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid #0000004d",
                                      height: "140px",
                                      width: "140px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50%",
                                    }}
                                    width="150px"
                                  >
                                    {previewUrl ?
                                      <>
                                        <img
                                          style={{
                                            cursor: "pointer", height: "135px",
                                            width: "135px",
                                          }}
                                          src={previewUrl}
                                          {...getRootProps()}
                                        />
                                      </>
                                      :
                                      <>
                                        <img
                                          style={{ cursor: "pointer" }}
                                          width="70px"
                                          src={uploadImg}
                                          {...getRootProps()}
                                        />
                                      </>
                                    }
                                  </div>
                                </div>
                                {previewUrl && (
                                  <div className="d-flex justify-content-center align-item-center ">
                                    <button
                                      onClick={() => handleclick()}
                                      type="button"
                                      className="btn btn-sm btn-soft-danger"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </Dropzone>
                          {validation.touched.profilePic &&
                            validation.errors.profilePic ? (
                            <div className="error">
                              {validation.errors.profilePic}
                            </div>
                          ) : null}
                        </FormGroup>

                        <FormGroup switch className="d-flex" style={{ gap: "60px" }}>
                          <Label htmlFor="validationCustom03" style={{ marginTop: "5px" }}>
                            IsActive
                          </Label>
                          <Input
                            type="switch"
                            name="isActive"
                            style={{ fontSize: "20px" }}
                            defaultChecked={editData?.isActive || false}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.isActive}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="8">
                        <FormGroup className="mb-2">
                          <Label htmlFor="validationCustom03" className="required">
                            Name
                          </Label>
                          <Input
                            name="title"
                            placeholder="Popular Category"
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title && validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <div className="mb-3">
                          <label className="control-label required">
                            Categories
                          </label>
                          <Select
                            isMulti  // Enables multiple selection
                            options={popularCategories}
                            value={selectedValues}
                            onChange={handleSelectChange}
                            styles={isError ? customStyles1 : customStyles}
                          />
                          {isError ? (
                            <div className="name-required">
                              {"The category is required"}
                            </div>
                          ) : null}
                        </div>

                        <Row>
                          <Col md="3">   <FormGroup className="mb-2">
                            <Label htmlFor="validationCustom03" className="required">
                              Sort Order
                            </Label>
                            <Input
                              name="sortOrder"
                              placeholder="0"
                              type="text"
                              className="form-control-sm"
                              onKeyPress={handleKeyPress}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.sortOrder || ""}
                              invalid={
                                validation.touched.sortOrder && validation.errors.sortOrder
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.sortOrder && validation.errors.sortOrder ? (
                              <FormFeedback type="invalid">
                                {validation.errors.sortOrder}
                              </FormFeedback>
                            ) : null}
                          </FormGroup></Col>
                        </Row>

                      </Col>
                    </Row>

                    <div className="text-end">
                      {isEdit ? (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          onClick={handleCancle}
                        >
                          Cancle
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          type="reset"
                        >
                          Reset
                        </Button>
                      )}
                      {formSumitLoading ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                          Loading
                        </button>
                      ) : (
                        <Button
                          color="primary"
                          className="btn btn-primary btn-sm "
                          type="submit"
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div >
  )
}
PopularCategoriesList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  onFilterChange: PropTypes.func,
}

export default PopularCategoriesList
