import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container, FormGroup, Input, Label } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import breadcrumbs from "common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import ProductHistoryTable from "./ProductHistoryTable"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"
import uploadImage from "../../assets/images/uploadImage.png"

function ProductHistory() {
  let { pathname } = useLocation()
  const { postApi } = useApi()
  const [tableData, settableData] = useState([])
  const [IsLoading, setIsLoading] = useState(false)
  const [searchText, setsearchText] = useState("")
  const [pendingForReturn, setpendingForReturn] = useState(false)
  const [totalBooking, setTotalBooking] = useState(0)
  const [viewtotaliBooking, setViewTotalBooking] = useState(false)
  const [productDetils, setProductDetails] = useState()

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Bill No.
          </div>
        ),
        accessor: "BillNo",
        width: "5%",
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Product Code
          </div>
        ),
        accessor: "ProductCode",
        width: "5%",
        Cell: ({ row }) => {
          return <div>{productDetils?.ProductCode}</div>
        },
      },
      // {
      //   Header: () => (
      //     <div
      //       style={{
      //         textAlign: "left",
      //       }}
      //     >
      //      Product Name
      //     </div>
      //   ),
      //   accessor: "ProductName",
      //   Cell: ({ row }) => {
      //     return <div>{row.original.ProductName}</div>
      //   },
      // },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Booking Date
          </div>
        ),
        accessor: "BookingDate",
        width: "7%",
        Cell: ({ row }) => {
          return <div>{dateFormat(row.original.BookingDate, "dd/MM/yyyy")}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Delivery
          </div>
        ),
        accessor: "DeliveryDate",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>{dateFormat(row.original.DeliveryDate, "dd/MM/yyyy")}</div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Return
          </div>
        ),
        accessor: "ReturnDate",
        width: "7%",
        Cell: ({ row }) => {
          return <div>{dateFormat(row.original.ReturnDate, "dd/MM/yyyy")}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Customer Name
          </div>
        ),
        accessor: "CustomerName",
        Cell: ({ row }) => {
          return <div>{row.original.CustomerName}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Contact No.
          </div>
        ),
        width: "7%",
        accessor: "CustomerContactNo",
        Cell: ({ row }) => {
          return <div>{row.original.CustomerContactNo}</div>
        },
      },
    ],
    [tableData]
  )

  const handleSearch = () => {
    setIsLoading(true)
    if (searchText) {
      postApi("Report/product-history", {
        productCode: searchText,
        pendingForReturn,
      }).then(({ data, status }) => {
        if (status === 200) {
          settableData(data.ProductHistoryList)
          setProductDetails(data)
          setViewTotalBooking(true)
          setIsLoading(false)
          setTotalBooking(data.ProductHistoryList.length)
        } else {
          setViewTotalBooking(false)
          setIsLoading(false)
          settableData([])
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    } else {
      toast.error("search text is required", {
        style: {
          fontSize: "15px",
        },
      })
    }
  }

  useEffect(() => {
    if (searchText) {
      handleSearch()
    }
  }, [pendingForReturn])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />
          <Card>
            <CardBody>
              <div className="row mb-3">
                <div className="col-md-2">
                  <input
                    onChange={e => {
                      setsearchText(e.target.value)
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control"
                    placeholder={"Search Product Code..."}
                    value={searchText}
                  />
                </div>

                <div className="col-md-3 px-0">
                  <div className="row">
                    <div className="col-md-5" style={{ paddingRight: "0px" }}>
                      {" "}
                      {IsLoading ? (
                        <button type="button" className="btn btn-primary">
                          <i className="bx bx-hourglass bx-spin font-size-13 align-middle me-1"></i>{" "}
                          Loading
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={() => {
                            handleSearch()
                          }}
                        >
                          <i className="bx bx-search-alt font-size-16 align-middle me-2"></i>{" "}
                          Search
                        </button>
                      )}
                    </div>
                    <div
                      className="col-md-7 px-0 d-flex align-items-center text-nowrap"
                      style={{ paddingLeft: "0px" }}
                    >
                      {" "}
                      <FormGroup check>
                        <Input
                          id="checkbox2"
                          type="checkbox"
                          checked={pendingForReturn}
                          onChange={e => setpendingForReturn(e.target.checked)}
                        />
                        <Label for="checkbox2" check>
                          Pending for Return
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>
                {viewtotaliBooking ? (
                  <div
                    className="col-md-7 d-flex align-items-center justify-content-end text-end"
                    style={{ color: "#0F6E25", fontSize: "15px" }}
                  >
                    Total {totalBooking} Bookings for
                    {` ${productDetils?.ProductName}`}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* <Row>

                <Col md="2">
                  <FormGroup>
                    <Dropzone
                      accept="image/*"
                      multiple={false}
                      id="profilePic"
                      name="profilePic"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <div className="d-flex flex-column align-items-center text-center p-2 py-3">
                            <>
                              <input {...getInputProps()} />

                              <div
                                style={{
                                  width: "135px",
                                  height: "150px",
                                  border: "1px solid #ced4da",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "3px",
                                }}
                              >
                                <img
                                  className="img-fluid"
                                  style={{}}
                                  src={productDetils?.ProductImage ? productDetils?.ProductImage : uploadImage}
                                  {...getRootProps()}
                                />
                              </div>
                            </>
                          </div>
                        </>
                      )}
                    </Dropzone>
                  </FormGroup>
                </Col>

                <Col md="9" className="mt-3">
                  <div>
                    <b>  Product Code </b>: {productDetils?.ProductCode} <br /> <br />
                    <b> Product Name </b> : {productDetils?.ProductName} <br /> <br />
                    <b> Color </b> : {productDetils?.ColorName} <br /> <br />
                    <b> Size </b> : {productDetils?.SizeName
                    } 
                  </div>
                </Col>
              </Row> */}
              <div className="row">
                <div className="col-md-10">
                  <ProductHistoryTable
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={false}
                    isCreateBooking={false}
                    customPageSize={10}
                    IsLoading={IsLoading}
                    className="custom-header-css"
                  />
                </div>
                <div className="col-md-2 ">
                  <div>
                    <div>
                      <div
                        style={{
                          width: "150px",
                          height: "170px",
                          border: "1px solid #ced4da",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <img
                          className="img-fluid"
                          style={{}}
                          src={
                            productDetils?.ProductImage
                              ? productDetils?.ProductImage
                              : uploadImage
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mt-2 " style={{ fontSize: "15px" }}>
                        <div>
                          <b> Name:</b> {productDetils?.ProductName}
                        </div>

                        <div className="mt-2">
                          <b> Code:</b> {productDetils?.ProductCode}
                        </div>

                        <div className="mt-2">
                          <b>Color:</b> {productDetils?.ColorName}
                        </div>

                        <div className="mt-2">
                          <b>Size: </b> {productDetils?.SizeName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductHistory
