// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Swal from "sweetalert"
// import 'sweetalert/dist/sweetalert.css';

//import components
import TableList from "../../components/Common/TableList"
import { Input } from "reactstrap"

import { Booking_status } from "_mock/Static_data"
import DeleteModel from "common/deleteModel/deleteModel"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"

function ProductView({ productViewId }) {
  // useState
  const [tableData, setTableData] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [statusCancel, setStatusCancel] = useState(false)
  const { getApi, putApi } = useApi()

  // useEffect product List
  useEffect(() => {
    setIsLoading(true)
    setStatusCancel(false)
    if (productViewId) {
      getApi(`Booking/booking-item-list?id=${String(productViewId)}`).then(
        ({ data, status }) => {
          if (status === 200) {
            setIsLoading(false)
            setTableData(data)
          } else {
            setIsLoading(false)
            setError(data)
          }
        }
      )
    }
  }, [statusCancel])

  // product status change handler
  const handleProductStatus = (productId, statusId) => {
    if ((productId, statusId)) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure you want to change status?",
        text: "If you change the status of this item, the status of all the product inside it will be changed.",
      }).then(response => {
        if (response == true) {
          const bookingId = ""
          putApi("Booking/change-Status", {
            bookingId: bookingId == "" ? 0 : bookingId,
            bookingItemId: productId == "" ? 0 : productId,
            BookingStatus: parseInt(statusId),
          }).then(res => {})
        } else setStatusCancel(true)
      })
    }
  }

  // product view table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: "Name",
        accessor: "ProductName",
      },
      {
        Header: "Rent",
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Rent.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.DeliveryDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        width: "7%",
        Cell: ({ row }) => (
          <p
            className="mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks}`}
          >
            {row.original?.Remarks?.length > 15
              ? `${row.original.Remarks.substr(0, 15)}...`
              : row.original.Remarks == ""
              ? "N/A"
              : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Status
          </div>
        ),
        accessor: "Status",
        // width: "",
        Cell: ({ row }) => (
          <div
            className="textWrap"
            style={{
              height: "8px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Input
              type="select"
              onChange={e =>
                handleProductStatus(row.original.Id, e.target.value)
              }
              defaultValue={row.original.Status}
              bsSize="sm"
              className=" form-control"
            >
              {Booking_status.map((item, index) => {
                return (
                  <option key={index} value={item.Value}>
                    {item.DisplayName}
                  </option>
                )
              })}
            </Input>
          </div>
        ),
      },
    ],
    [tableData]
  )

  return (
    <div>
      <div>
        <TableList
          columns={columns}
          data={tableData}
          isGlobalFilter={true}
          isCategoryList={true}
          customPageSize={10}
          isLoading={isLoading}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
ProductView.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ProductView
