/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
  FormGroup,
  FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import { GoogleApiWrapper } from "google-maps-react"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { useLocation, useNavigate } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import Compressor from "compressorjs"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { routes } from "routes/path"

function UpadateUser() {
  const { getApi, putApi } = useApi()
  const { state } = useLocation()
  // useStates
  const [userData, setuserData] = useState(state?.data)
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [isLoading, setisLoading] = useState(false)
  const [screeLoad, setscreeLoad] = useState(false)
  const [fileFlag, setfileFlag] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(state?.data?.url)
  const [imageFlag, setImageFlag] = useState(false)
  const [gender, setGender] = useState(state?.data?.gender == true ? "true" : "false")

  // user details validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullname: userData ? userData.fullName : "",
      shopname: userData ? userData.shopName == null ? "" : userData.shopName : "",
      email: userData ? userData.email : "",
      contactNo: userData ? userData.contactNo : "",
      username: userData ? userData.username : "",
      address: userData ? userData.address : "",
      remark: userData ? userData.remarks : "",
      profilePic: userData ? userData.url : "",
      latitude: userData ? userData.latitude : "",
      longitude: userData ? userData.longitude : "",
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required("The Fullname is required"),
      shopname: Yup.string().nullable().required("The shop name is required"),
      latitude: Yup.string().required("The latitude is required"),
      longitude: Yup.string().required("The longitude is required"),
      contactNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
        .required("The contact number is required"),
      username: Yup.string().required("The username is required"),
    }),
    onSubmit: values => {
      setisLoading(true)
      putApi("api/User/edit", {
        id: state.data.id,
        fullName: values.fullname,
        gender: gender == "true" ? true : false,
        email: values.email,
        username: values.username,
        shopName: values.shopname,
        latitude: values.latitude,
        longitude: values.longitude,
        address: values.address,
        profilePic: imageFlag ? values.profilePic.split(",")[1] : "",
        remarks: values.remark,
        imgDelete: fileFlag,
      }).then(({ data, status }) => {
        setisLoading(false)
        if (status === 200) {
          setisLoading(false)
          setImageFlag(false)
          setfileFlag(false)
          toast.success("User Update Successfully", {
            style: {
              fontSize: "15px",
            },
          })
          setTimeout(() => {
            navigate(routes.userList)
          }, 1000)
        } else {
          setisLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })

  // profile pic toBase64 convert handle
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  // profile pic select handle & compressor
  const handleDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setImageFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4,
      success: async compressedResult => {
        setPreviewUrl(URL.createObjectURL(compressedResult))
        validation.setFieldValue("profilePic", await toBase64(compressedResult))
      },
    })
  }

  // profile pic display & set
  const handleclick = () => {
    setfileFlag(true)
    validation.setFieldValue("profilePic", null)
    setPreviewUrl(null)
  }

  useEffect(() => {
    const mapOptions = {
      center: new google.maps.LatLng(21.2310051, 72.901111),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    const map = new google.maps.Map(document.getElementById('lat-lng-Map'), mapOptions);
    google.maps.event.addListener(map, 'click', function (e) {
      validation.setFieldValue("latitude", String(e.latLng.lat()).substring(0, 10))
      validation.setFieldValue("longitude", String(e.latLng.lng()).substring(0, 10))
    });
  }, [window.onload, window.pathname])

  document.title = `Update User | ${"Shop Manager"}`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row">
                      <Col md="3">
                        <FormGroup>
                          <Dropzone
                            onDrop={handleDrop}
                            accept="image/*"
                            multiple={false}
                            id="profilePic"
                            name="profilePic"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div class="d-flex flex-column align-items-center text-center p-2 py-0">
                                  {previewUrl ? (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle pe-auto"
                                        style={{ cursor: "pointer" }}
                                        width="150px"
                                        height="150px"
                                        src={previewUrl}
                                        {...getRootProps()}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle"
                                        style={{ cursor: "pointer" }}
                                        width="150px"
                                        src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                        {...getRootProps()}
                                      />
                                    </>
                                  )}
                                </div>
                                {previewUrl && (
                                  <div className="d-flex justify-content-center align-item-center ">
                                    <button
                                      onClick={() => handleclick()}
                                      type="button"
                                      className="btn btn-sm btn-soft-danger"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </Dropzone>
                          {validation.touched.profilePic &&
                            validation.errors.profilePic ? (
                            <div className="error">
                              {validation.errors.profilePic}
                            </div>
                          ) : null}
                        </FormGroup>

                        <div className="mb-2 "
                          style={{ justifyContent: 'center', display: "flex", alignItems: "center" }}
                        >
                          <div className=" d-flex gap-2" style={{ marginTop: "7px" }}>
                            <input
                              type="radio"
                              name="gender"
                              value={true}
                              id="customRadioInline1"
                              className="form-check-input"
                              defaultChecked={gender == "true"}
                              onChange={(e) => { setGender(e.target.value) }}
                            />
                            <Label
                              className="form-check-label font-size-13"
                              htmlFor="customRadioInline1"
                            >
                              Male
                            </Label>


                            <input
                              type="radio"
                              name="gender"
                              value={false}
                              id="customRadioInline2"
                              className="form-check-input"
                              defaultChecked={gender == "false"}
                              onChange={(e) => { setGender(e.target.value) }}
                            />
                            <Label
                              className="form-check-label font-size-13"
                              htmlFor="customRadioInline2"
                            >
                              Female
                            </Label>
                          </div>
                        </div>

                      </Col>
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-4">
                            <FormGroup>
                              <Label for="fullname" className="required">
                                Full Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                name="fullname"
                                id="fullname"
                                placeholder="Enter your full name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fullname}
                                invalid={
                                  validation.touched.fullname &&
                                    validation.errors.fullname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.fullname &&
                                validation.errors.fullname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fullname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                          <div className="col-md-4">
                            <FormGroup>
                              <Label for="shopname" className="required">
                                Shop Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                // disabled
                                name="shopname"
                                placeholder="Shop name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shopname}
                                invalid={
                                  validation.touched.shopname &&
                                    validation.errors.shopname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.shopname &&
                                validation.errors.shopname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.shopname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                          <div className="col-md-4">
                            <FormGroup>
                              <Label for="contactNo" className="required">
                                Contact Number
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                disabled
                                maxLength="10"
                                name="contactNo"
                                id="contactNo"
                                placeholder="Enter your contact number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contactNo}
                                invalid={
                                  validation.touched.contactNo &&
                                    validation.errors.contactNo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.contactNo &&
                                validation.errors.contactNo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.contactNo}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label for="email">Email</Label>
                              <Input
                                type="email"
                                name="email"
                                id="email"
                                className="form-control-sm"
                                placeholder="Enter your email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label for="username" className="required">
                                Username
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                name="username"
                                id="username"
                                placeholder="Enter your username"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username}
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label for="address" className="">
                                Address
                              </Label>
                              <Input
                                type="textarea"
                                className="form-control-sm"
                                name="address"
                                id="address"
                                row={2}
                                placeholder="Enter your address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address}
                                invalid={
                                  validation.touched.address &&
                                    validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                                validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label for="remark">Remarks</Label>
                              <Input
                                type="textarea"
                                className="form-control-sm"
                                name="remark"
                                id="remark"
                                row={2}
                                placeholder="Enter your remarks"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.remark}
                                invalid={
                                  validation.touched.remark &&
                                    validation.errors.remark
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.remark &&
                                validation.errors.remark ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.remark}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </div>

                          <Row>
                            <Col className="col-12" sm={12} md={12} lg={12} xl={8}>
                              <div id="lat-lng-Map" style={{ height: "200px", width: "550px" }} />
                            </Col>
                            <Col className="col-12" sm={12} md={12} lg={12} xl={4}>
                              <Row>
                                <Col sm={12} md={10}>
                                  <FormGroup>
                                    <Label className="required" for="latitude">Latitude</Label>
                                    <Input
                                      type="latitude"
                                      disabled
                                      name="latitude"
                                      id="latitude"
                                      className="form-control-sm"
                                      placeholder="latitude"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.latitude}
                                      invalid={
                                        validation.touched.latitude &&
                                          validation.errors.latitude
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.latitude &&
                                      validation.errors.latitude ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.latitude}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={10}>
                                  <FormGroup>
                                    <Label className="required" for="longitude">Longitude</Label>
                                    <Input
                                      type="longitude"
                                      disabled
                                      name="longitude"
                                      id="longitude"
                                      className="form-control-sm"
                                      placeholder="longitude"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.longitude}
                                      invalid={
                                        validation.touched.longitude &&
                                          validation.errors.longitude
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.longitude &&
                                      validation.errors.longitude ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.longitude}
                                      </FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>

                    <Row>
                      <Col md="12" className="text-end">
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2"
                          type="button"
                          onClick={() => {
                            navigate(routes.userList)
                          }}
                        >
                          Cancle
                        </Button>
                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// export default UpadateUser
export default GoogleApiWrapper({
  apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
  v: "3",
})(UpadateUser)