import { routes } from "routes/path"

export const routesRoles = {
  [routes.index]: {
    role: ["Admin", "Vendor", "Salesman", ""],
  },
  [routes.dashboard]: {
    role: ["Admin", "Vendor", ""],
  },
  [routes.userList]: {
    role: ["Admin", "Vendor"],
  },
  [routes.userCreate]: {
    role: ["Admin", "Vendor"],
  },
  [routes.userUpdate]: {
    role: ["Admin", "Vendor"],
  },
  [routes.inquiries]: {
    role: ["Admin"],
  },
  [routes.categories]: {
    role: ["Admin", "Vendor"],
  },
  [routes.size]: {
    role: ["Admin", "Vendor"],
  },
  [routes.color]: {
    role: ["Admin", "Vendor"],
  },
  [routes.profile]: {
    role: ["Admin", "Vendor", "Salesman"],
  },
  [routes.productList]: {
    role: ["Admin"],
  },
  [routes.productCreate]: {
    role: ["Admin", "Vendor"],
  },
  [routes.productUpdate]: {
    role: ["Admin", "Vendor"],
  },
  [routes.productVerification]: {
    role: ["Admin", "Reviewer"],
  },
  [routes.productVerificationUpdate]: {
    role: ["Admin"],
  },
  [routes.uploadImage]: {
    role: ["Admin"],
  },
  [routes.advertisement]: {
    role: ["Admin"],
  },
  [routes.popularCategories]: {
    role: ["Admin"],
  },
  [routes.appVersion]: {
    role: ["Admin"],
  },
  [routes.dbBackup]: {
    role: ["Admin"],
  },
  // [routes.timeslot]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.plan]: {
  //   role: ["Admin"],
  // },
  // [routes.available]: {
  //   role: ["Admin", "Vendor", "Salesman"],
  // },
  // [routes.demo]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.productList]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.createProduct]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.bookingList]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.createbooking]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.updatebooking]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.cartlist]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.reportBooking]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.delivery]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.return]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.salesman]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.salesReport]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.feedback]: {
  //   role: ["Admin"],
  // },
  [routes.setting]: {
    role: ["Admin"],
  },
  // [routes.invoice]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.subscription]: {
  //   role: ["Admin"],
  // },

  // [routes.viewBooking]: {
  //   role: ["Admin", "Vendor", "Salesman"],
  // },
  // [routes.importProduct]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.productHistory]: {
  //   role: ["Admin", "Vendor"],
  // },
}