/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Container,
  FormFeedback,
  Table,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
import CreatableSelect from "react-select/creatable"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Tbody, Th, Tr } from "react-super-responsive-table"
import toast from "react-hot-toast"
import TableList from "components/Common/TableList"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import { NavLink } from "react-router-dom"
import classnames from "classnames"
import AddCartPage from "./AddCartPage"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useRef } from "react"
import uploadImage from "assets/images/uploadImage.png"
import DeleteModel from "common/deleteModel/deleteModel"

const colourStyles = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
}

function Avaiblity() {
  const { getApi, postApi } = useApi()
  let { pathname } = useLocation()
  const scrollRef = useRef(null)
  // useStates
  const [screeLoad, setscreeLoad] = useState(true)
  const [bookingAvailableList, setBookingAvailableList] = useState([])
  const [cartAvailableList, setCartAvailableList] = useState([])
  const [bookingDataStatus, setBookingDataStatus] = useState()
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState()
  const [data, setData] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [TimeSolteDetails, setTimeSolteDetails] = useState([])
  const [customerNames, setCustomersNames] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isChangeTab, setIsChangeTab] = useState(false)
  const [options, setOptions] = useState([])
  const [value, setValue] = useState("")
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [formSumitLoading2, setformSumitLoading2] = useState(false)
  const [modal, setModal] = useState(false)
  const [cartConfirm, setCartConfirm] = useState(false)
  const [cartButton, setCartButton] = useState(false)
  const [open, setOpen] = useState("")
  const [productDetails, setproductDetails] = useState({
    ProductName: "N/A",
    ProductImage: "",
    SizeName: "N/A",
    ColorName: "N/A",
  })

  const toggleAccordion = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  useEffect(() => {
    setscreeLoad(true)
    getApi("TimeSlot/time-slot-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        setTimeSolteDetails(data)
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
    getApi("Booking/cart-name-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        data.map(item => {
          options.push({
            label: item.Value,
            value: item.Value,
          })
        })
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [])

  const toggle = () => {
    setModal(!modal)
  }

  const handleformDate = e => {
    validation.handleChange(e)
    setFromDate(e.target.value)
  }
  const handleToDate = e => {
    validation.handleChange(e)
    setToDate(e.target.value)
  }

  const handaleConfirm = () => {
    handleAddtoCart()
  }

  const handleAddtoCart = () => {
    DeleteModel.confirmDeleteInquiry({
      title: "Are you sure to procced?",
      text: "",
    }).then(response => {
      if (response == true) {
        setCartConfirm(true)
        setIsChangeTab(false)
        addtoCartValidation.handleSubmit()
        toggle()
      }else{
        toggle()
      }
    })
  }


  // avalability validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Code: "",
      formDate: new Date().toISOString().slice(0, 10),
      toDate: new Date().toISOString().slice(0, 10),
    },
    validationSchema: Yup.object({
      Code: Yup.string().required("Code is required"),
      formDate: Yup.string().required("Delivery Date is required"),
      toDate: Yup.string().required("Return Date is required"),
    }),
    onSubmit: values => {
      setformSumitLoading2(true)
      setIsChangeTab(false)
      if (values) {
        setData(values)
        postApi("Booking/check-availablility", {
          productCode: values.Code,
          fromDate: values.formDate,
          toDate: values.toDate,
        }).then(({ data, status }) => {
          setformSumitLoading2(false)
          if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" })
          }
          if (status === 200) {
            if (data.IsWashing == true) {
              // toast.error("This product is currenly in washing", {
              // style: {
              //   fontSize: "15px",
              // },
              // })

              toast("This product is currenly in washing.", {
                icon: "⚠️",
                style: {
                  fontSize: "15px",
                },
              })
            }
            setproductDetails({
              ProductName: data.ProductName,
              ProductImage: data.ProductImage,
              SizeName: data.SizeName,
              ColorName: data.ColorName,
            })


            setBookingAvailableList(data.BookingAvailabilityList)
            setBookingDataStatus(data.Status)
            setCartButton(true)
          } else {
            setBookingAvailableList("")
            setBookingDataStatus("")
            setCartButton(false)
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
    },
  })

  const addtoCartValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      productCode: data?.Code,
      deliveryDate: data?.formDate,
      returnDate: data?.toDate,
      customerName: inputValue ? inputValue : value?.label,
      deliveryTimeSlot: "",
      returnTimeSlot: "",
    },
    validationSchema: Yup.object({
      // deliveryTimeSlot: Yup.string().required("The Delivery time is required"),
      // returnTimeSlot: Yup.string().required("The Return time is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      // toggle()
      if (
        values.productCode == "" ||
        values.customerName == "" ||
        values.customerName == undefined
      ) {
        setformSumitLoading(false)

        toast.error("Please fill details", {
          style: {
            fontSize: "15px",
          },
        })
      } else {
        postApi("Booking/add-to-cart", {
          name: values.customerName,
          productCode: values.productCode,
          deliveryDate: values.deliveryDate,
          deliveryTimeSlot: values.deliveryTimeSlot,
          returnDate: values.returnDate,
          returnTimeSlot: values.returnTimeSlot,
          confirmByVendor: cartConfirm,
        }).then(({ data, status }) => {
          setformSumitLoading(false)
          if (status == 200 && data.Status == 1) {
            setValue("")
            setBookingAvailableList([])
            setBookingDataStatus("")
            setCartConfirm(false)
            setCartButton(false)
            setIsChangeTab(true)
            toast.success("The product successfully added to the cart.", {
              style: {
                fontSize: "15px",
              },
            })
            validation.handleReset()
            addtoCartValidation.handleReset()
          } else if (status == 200 && data.Status == 2) {
            setIsChangeTab(true)
            setCartButton(false)
            toast.error(data.ErrorMessage, {
              style: {
                fontSize: "15px",
              },
            })
          } else {
            setIsChangeTab(true)
            setCartButton(false)
            setCartAvailableList(data.CartAvailabilityList)
            toggle()
          }
        })
      }
    },
  })

  // table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Contact No.1",
        accessor: "ContactNo1",
        width: "8%",
      },
      {
        Header: "Contact No.2",
        accessor: "ContactNo2",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ContactNo2 == null ? "N/A" : row.original.ContactNo2}
          </p>
        ),
      },

      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}{" "}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    []
  )

  const columns1 = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Salesman Name",
        accessor: "SalesmanName",
      },
      {
        Header: "Delivery Date",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}{" "}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return Date",
        accessor: "ReturnDate,ReturnTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    []
  )

  const [customActiveTab, setcustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const createOption = label => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  })

  const handleCreate = inputValue => {
    setInputValue(inputValue)
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setOptions(prev => [...prev, newOption])
      setValue(newOption)
    }, 1000)
  }

  document.title = `Available | ${localStorage.getItem("ShopName")}`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />

          <Card>
            <Row>
              <Col md={4}>
                <Nav
                  tabs
                  className="nav-tabs-custom nav-justified"
                  style={{ padding: "2px 10px" }}
                >
                  <NavItem
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "active nav-link": customActiveTab === "1",
                      })}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-calendar-check"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        Check Availablility
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "active nav-link": customActiveTab === "2",
                      })}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-shopping-cart"></i>
                      </span>
                      <span className="d-none d-sm-block">Cart List</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      onReset={e => {
                        e.preventDefault
                        validation.handleReset()
                        return false
                      }}
                    >
                      <Row>
                        <Col md={9}>
                          <Row>
                            <Col md={4} className="mb-2">
                              <label
                                htmlFor="name"
                                className="required form-label"
                              >
                                Code
                              </label>
                              <Input
                                name="Code"
                                placeholder="Code"
                                type="text"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Code || ""}
                                invalid={
                                  validation.touched.Code &&
                                    validation.errors.Code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Code &&
                                validation.errors.Code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Code}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={4} className="mb-2">
                              <label
                                htmlFor="name"
                                className="required form-label"
                              >
                                Delivery
                              </label>
                              <Input
                                name="formDate"
                                placeholder="date placeholder"
                                type="date"
                                // min={new Date().toISOString().slice(0, 10)}
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={e => {
                                  handleformDate(e)
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.formDate}
                                invalid={
                                  validation.touched.formDate &&
                                    validation.errors.formDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.formDate &&
                                validation.errors.formDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.formDate}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={4} className="">
                              <Row>
                                <Col md={12} className="mb-2">
                                  <label
                                    htmlFor="name"
                                    className="required form-label"
                                  >
                                    Return
                                  </label>
                                  <Input
                                    name="toDate"
                                    type="date"
                                    min={
                                      fromDate ||
                                      new Date().toISOString().slice(0, 10)
                                    }
                                    dateFormat="DD/M/YYYY"
                                    className="form-control-sm"
                                    onChange={e => {
                                      handleToDate(e)
                                    }}
                                    onfocus={e => {console.log(e)}}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.toDate || ""}
                                    invalid={
                                      validation.touched.toDate &&
                                        validation.errors.toDate
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.toDate &&
                                    validation.errors.toDate ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.toDate}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={2}
                          className="mb-2 available_button check_button Button_aline"
                        >
                          <>
                            {formSumitLoading2 ? (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                style={{ width: "100%" }}
                              >
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                Loading
                              </button>
                            ) : (
                              <Button
                                color="primary"
                                className="btn btn-primary btn-sm mt-1"
                                style={{ width: "100%" }}
                                type="submit"
                              >
                                {" "}
                                Check Available
                              </Button>
                            )}
                          </>
                        </Col>

                        <Col md={12}>
                          <Row>
                            <Col md={3} className="mb-2">
                              <label
                                htmlFor="name"
                                className="required form-label"
                              >
                                Customer Name
                              </label>
                              <CreatableSelect
                                name="customerName"
                                unstyled
                                styles={colourStyles}
                                isClearable
                                isDisabled={isLoading}
                                isLoading={isLoading}
                                onChange={newValue => setValue(newValue)}
                                onCreateOption={handleCreate}
                                options={options}
                                value={value}
                              />
                              {addtoCartValidation.touched.customerName &&
                                addtoCartValidation.errors.customerName ? (
                                <FormFeedback type="invalid">
                                  {addtoCartValidation.errors.customerName}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col md={6}>
                              <Row>
                                <Col sm={6} className="mb-2">
                                  <label htmlFor="name" className="form-label">
                                    Delivery Time
                                  </label>
                                  <Input
                                    name="deliveryTimeSlot"
                                    placeholder="Delivery Time"
                                    bsSize="sm"
                                    type="select"
                                    className="form-control-sm"
                                    onChange={addtoCartValidation.handleChange}
                                    onBlur={addtoCartValidation.handleBlur}
                                    value={
                                      addtoCartValidation.values
                                        .deliveryTimeSlot || ""
                                    }
                                    invalid={
                                      addtoCartValidation.touched
                                        .deliveryTimeSlot &&
                                        addtoCartValidation.errors
                                          .deliveryTimeSlot
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Select</option>
                                    {TimeSolteDetails.map(item => {
                                      return (
                                        <option
                                          key={item.Key}
                                          value={item.Value}
                                        >
                                          {item.Value}
                                        </option>
                                      )
                                    })}
                                  </Input>
                                  {addtoCartValidation.touched
                                    .deliveryTimeSlot &&
                                    addtoCartValidation.errors
                                      .deliveryTimeSlot ? (
                                    <FormFeedback type="invalid">
                                      {
                                        addtoCartValidation.errors
                                          .deliveryTimeSlot
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col sm={6} className="mb-2">
                                  <label htmlFor="name" className="form-label">
                                    Return Time
                                  </label>
                                  <Input
                                    name="returnTimeSlot"
                                    placeholder="Return Time"
                                    bsSize="sm"
                                    type="select"
                                    className="form-control-sm"
                                    onChange={addtoCartValidation.handleChange}
                                    onBlur={addtoCartValidation.handleBlur}
                                    value={
                                      addtoCartValidation.values
                                        .returnTimeSlot || ""
                                    }
                                    invalid={
                                      addtoCartValidation.touched
                                        .returnTimeSlot &&
                                        addtoCartValidation.errors.returnTimeSlot
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Select</option>
                                    {TimeSolteDetails.map(item => {
                                      return (
                                        <option
                                          key={item.Key}
                                          value={item.Value}
                                        >
                                          {item.Value}
                                        </option>
                                      )
                                    })}
                                  </Input>
                                  {addtoCartValidation.touched.returnTimeSlot &&
                                    addtoCartValidation.errors.returnTimeSlot ? (
                                    <FormFeedback type="invalid">
                                      {
                                        addtoCartValidation.errors
                                          .returnTimeSlot
                                      }
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              md={2}
                              className="mb-2 check_button Button_aline available_button"
                            >
                              {(bookingDataStatus == 1 ||
                                bookingDataStatus == 3) &&
                                cartButton ? (
                                <>
                                  {formSumitLoading ? (
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      style={{
                                        width: "100%",
                                        cursor: "not-allowed",
                                      }}
                                    >
                                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                      Loading
                                    </button>
                                  ) : (
                                    <Button
                                      color="primary"
                                      type="submit"
                                      className="btn btn-primary btn-sm w-full mt-1"
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={e => {
                                        e.preventDefault()
                                        addtoCartValidation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      Add to Cart
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <Button
                                  color="primary"
                                  type="button"
                                  className="btn btn-primary btn-sm w-full mt-1"
                                  style={{
                                    width: "100%",
                                    cursor: "not-allowed",
                                  }}
                                >
                                  Add to Cart
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    {bookingAvailableList.length > 0 &&
                      bookingDataStatus === 3 ? (
                      <div ref={scrollRef}>
                        {/* <div
                          className="text-start"
                          
                          style={{
                            color: "#28A745",
                            fontSize: "20px",
                            marginBottom: "5px",
                          }}
                        >
                          Selected Items for this date
                        </div> */}
                        <div>
                          <div>
                            <TableList
                              columns={columns}
                              data={bookingAvailableList}
                              isGlobalFilter={true}
                              isCategoryList={true}
                              customPageSize={10}
                              isLoading={isLoading}
                              className="custom-header-css"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          ref={scrollRef}
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            {bookingAvailableList.length === 0 &&
                              bookingDataStatus === 1 && (
                                <Tbody>
                                  <Tr>
                                    <Th
                                      className="text-center"
                                      style={{
                                        color: "#28A745",
                                        fontSize: "20px",
                                      }}
                                    >
                                      Available
                                    </Th>
                                  </Tr>
                                </Tbody>
                              )}
                            {bookingAvailableList.length === 0 &&
                              bookingDataStatus === 2 && (
                                <Tbody>
                                  <Tr>
                                    <Th
                                      className="text-center"
                                      style={{
                                        color: "#DC3545",
                                        fontSize: "20px",
                                      }}
                                    >
                                      Not available
                                    </Th>
                                  </Tr>
                                </Tbody>
                              )}
                          </Table>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <AddCartPage isChangeTab={isChangeTab} />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <Modal isOpen={modal} toggle={toggle} centered={true}>
              <div className="modal-content ">
                <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                  Product already in Cart
                </ModalHeader>
                <ModalBody>
                  <TableList
                    columns={columns1}
                    data={cartAvailableList}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </ModalBody>
                <Row>
                  <Col
                    md="12"
                    className="text-end"
                    style={{ marginBottom: "5px" }}
                  >
                    <Button
                      color="secondary"
                      className="btn btn-primary btn-sm me-2"
                      type="button"
                      onClick={() => toggle()}
                    >
                      Cancle
                    </Button>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-sm me-2"
                      type="submit"
                      onClick={() => {
                        handaleConfirm()
                      }}
                    // onClick={() => handaleConfirm()
                    //   addtoCartValidation.handleSubmit()}
                    >
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Card>
          <Card>
            {customActiveTab == "1" ? (
              <Row>
                <Col>
                  <Accordion open={open} toggle={toggleAccordion}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        Product Details
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <div className="d-flex gap-3">
                          {
                            <img
                              style={{
                                cursor: "pointer",
                                border: "1px solid #ced4da",
                                borderRadius: "3px",
                              }}
                              width="125px"
                              height="150px"
                              src={
                                productDetails?.ProductImage
                                  ? productDetails?.ProductImage
                                  : uploadImage
                              }
                            />
                          }
                          <div>
                            <div className="mt-2 " style={{ fontSize: "12px" }}>
                              <div>
                                <b> Name:</b>{" "}
                                {productDetails.ProductName ?? "N/A"}
                              </div>

                              <div className="mt-2">
                                <b> Code:</b>{" "}
                                {addtoCartValidation.values.productCode ??
                                  "N/A"}
                              </div>

                              <div className="mt-2">
                                <b>Color:</b>{" "}
                                {productDetails.ColorName ?? "N/A"}
                              </div>

                              <div className="mt-2">
                                <b>Size: </b> {productDetails.SizeName ?? "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </Col>
              </Row>
            ) : null}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Avaiblity
