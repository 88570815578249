import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "../../../components/Common/TableContainer"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import { routes } from "routes/path"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useFormik } from "formik"
import SubscriptionModel from "components/Common/SubscriptionModel"

function DatatableTables() {
  const { deleteApi, postApi, putApi, getApi } = useApi()
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [screeLoad, setscreeLoad] = useState(true)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchText, setSearchText] = useState("")
  const [userId, setUserId] = useState(0)
  const [username, setUsername] = useState('')
  const [rolename, setRolename] = useState('')
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [displayStart, setDisplayStart] = useState(0)
  const [statusFiltter, setStatusFiltter] = useState(0)
  
  // filterData for users
  const filterData = textFilter => {
    // setSearchText(textFilter)
  }

  const pagePerSize = (pageSize, pagePerIndex, serchValue, statusChange) => {
    setDisplayStart(((pagePerIndex * pageSize) - pageSize) + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
    setStatusFiltter(statusChange)
  }

  const userList = () => {
    setIsLoading(true)
    setscreeLoad(true)
    postApi("Api/User/list", {
      roleId: statusFiltter,
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.userList)
        setTotalRecords(data.totalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setscreeLoad(false)
    })
  }

  // userList useEffect
  useEffect(() => {
    async function UserDetails() {
      const {
        data: { roleName },
      } = await getApi(`api/User/details?id=${JSON.parse(localStorage.getItem('authUser')).id}`)
      setRolename(roleName)
    }
    UserDetails()
    userList()
  }, [rowsPerPage, searchText, displayStart, statusFiltter])

  // user delete handler
  const handleDelete = id => {
    DeleteModel.confirmDeleteInquiry({
      title: "Are you sure?",
      text: "You want to delete this User permanently? ",
    }).then(response => {
      if (response == true) {
        deleteApi(`api/User/delete?id=${id}`).then(({ data, status }) => {
          setIsLoading(true)
          if (status === 200) {
            toast.success("successfully deleted ", {
              style: {
                fontSize: "15px",
              },
            })
            setIsLoading(false)
            const updatedArray = tableData
              .map(obj => (obj.id !== id ? obj : null))
              .filter(Boolean)
            setTableData(updatedArray)
          } else {
            setIsLoading(false)
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      } else {
        
      }
    })
  }

  // user edit handle

  const handleEdit = Id => {
    setscreeLoad(true)
    if (Id) {
      getApi(`api/User/details?id=${Id}`).then(({ data, status }) => {
        if (status === 200) {
          navigate(routes.userUpdate, {
            state: {
              data,
            },
          })
        }
        setscreeLoad(false)
      })
    }
  }

  // user active/inactive switch hanlde
  const handleSwitch = async id => {
    putApi("User/active-user", { id: String(id) }).then(({ data, status }) => {
      if (status === 200) {
        toast.success("Sucessfully Changed", {
          style: {
            fontSize: "15px",
          },
        })
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
        userList()
      }
    })
  }

  const timeFormate = time => {
    const date = new Date(time)
    const formattedTime = date.toLocaleString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
    return formattedTime
  }

  // handle user click
  const handleUserClicks = () => {
    navigate(routes.userCreate)
  }

  const handleChangePassword = (userId) => {
    if (userId) {
      setUserId(userId)
    }
    setModal(!modal)
    validation.handleReset()
  }

  const toggle = () => {
    setModal1(!modal1)
  }

  const handleSubscription = (Username, userId) => {
    if (Username) {
      setUsername(Username)
      setUserId(userId)
    }
    toggle()
  }


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    validationSchema: Yup.object({
      NewPassword: Yup.string().required("The new password is required"),
      ConfirmPassword: Yup.string()
        .required("The confirm password is required")
        .oneOf([Yup.ref("NewPassword")], "Passwords must match"),
    }),
    onSubmit: values => {
      if (values) {
        DeleteModel.confirmDeleteInquiry({
          title: "Are you sure? ",
          text: "You want to update password this user? ",
        }).then(response => {
          if (response == true) {
            putApi("api/User/change-password", {
              userId: userId,
              newPassword: values.ConfirmPassword,
            }).then(({ data, status }) => {
              if (status == 400) {
                toast.error(data, {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                toast.success("successfully updated", {
                  style: {
                    fontSize: "15px",
                  },
                })
                setModal(false)
              }
            })
          } else {
            setModal(true)
          }
        })
      }
    },
  })

  const userEdit = id => { }

  // table accessor & header
  const columns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "roleName",
        width: 12,
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Contact No.",
        accessor: "contactNo",
        width: "7%",
      },
      {
        Header: "Shop Name",
        accessor: "shopName",
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.shopName ? row.original.shopName : "N/A"}
            </div>
          )
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.email ? row.original.email : "N/A"}
            </div>
          )
        },
      },
      // {
      //   Header: "Gender",
      //   accessor: "gender",
      // },
      {
        Header: "Last Login",
        accessor: "lastLoginDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row?.original?.lastLoginDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row?.original?.lastLoginDate.split("T")[0],
                "dd/MM/yyyy"
              ) + ` ${timeFormate(row?.original?.lastLoginDate)}`}
          </p>
        ),
      },
      {
        Header: "Create Date",
        accessor: "createdOn",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row?.original?.createdOn == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row?.original?.createdOn.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      // {
      //   Header: () => (
      //     <div
      //       style={{
      //         textAlign: "center",
      //       }}
      //     >
      //       Is Active
      //     </div>
      //   ),
      //   width: "6%",
      //   accessor: "IsActive",
      //   Cell: ({ row }) => (
      //     <FormGroup switch className="d-flex justify-content-center">
      //       <Input
      //         type="switch"
      //         defaultChecked={row.original.IsActive}
      //         onClick={e => {
      //           handleSwitch(row.original.Id)
      //         }}
      //       />
      //     </FormGroup>
      //   ),
      // },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-unlock-alt "
                  style={{ color: "#90499c", cursor: "pointer" }}
                  id={`changepasswordtooltip${row.original.id}`}
                  onClick={() => handleChangePassword(row.original.id)}
                ></i>
                <span className="mytext">Change Password</span>
              </li>

              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit "
                  id={`edittooltip${row.original.id}`}
                  onClick={() => handleEdit(row.original.id)}
                ></i>
                <span className="mytext">Edit</span>
              </li>
              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.id}`}
                  onClick={() => handleDelete(row.original.id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  //meta title
  document.title = `Users | ${"Shop Manager"}`

  return (
    <div className="page-content">
      <div className="container-fluid">
      {screeLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
        <Breadcrumbs
          title={breadcrumbs[pathname].parent}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          link={breadcrumbs[pathname].parentLink}
        />
        <Card>
          <CardBody>
            <TableContainer
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isAddUserList={true}
              handleUserClick={handleUserClicks}
              customPageSize={10}
              className="custom-header-css"
              userEdit={userEdit}
              isLoading={isLoading}
              filterData={filterData}
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
            />
          </CardBody>

          <Modal
            isOpen={modal}
            toggle={() => setModal(!modal)}
            centered={true}
            size="small"
            style={{ width: "350px" }}
          >
            <div className="modal-content">
              <ModalHeader toggle={() => setModal(!modal)} tag="h5">
                Change Password
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                  onReset={e => {
                    e.preventDefault
                    validation.handleReset()
                    return false
                  }}
                >
                  <FormGroup className="mb-2">
                    <Label htmlFor="validationCustom03" className="required">
                      New Password
                    </Label>
                    <Input
                      name="NewPassword"
                      placeholder="New Password"
                      type="password"
                      className="form-control-sm"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.NewPassword || ""}
                      invalid={
                        validation.touched.NewPassword &&
                          validation.errors.NewPassword
                          ? true
                          : false
                      }
                    />
                    {validation.touched.NewPassword &&
                      validation.errors.NewPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.NewPassword}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <Label htmlFor="validationCustom03" className="required">
                    Confirm Password
                  </Label>
                  <InputGroup className="mb-2">
                    <Input
                      className="form-control-sm"
                      name="ConfirmPassword"
                      value={validation.values.ConfirmPassword || ""}
                      type={passwordShow ? "text" : "password"}
                      placeholder="Confirm Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.ConfirmPassword &&
                        !!validation.errors.ConfirmPassword
                      }
                    />
                    {/* <InputGroupAddon addonType="append"> */}
                    <Button
                      onClick={() => setPasswordShow(!passwordShow)}
                      className="btn btn-sm"
                      style={{ border: "1px solid #ced4da" }}
                      color="light"
                    >
                      {passwordShow ? (
                        <i className="mdi mdi-eye-outline" />
                      ) : (
                        <i className="mdi mdi-eye-off-outline" />
                      )}
                    </Button>
                    {/* </InputGroupAddon> */}
                    {validation.touched.ConfirmPassword &&
                      validation.errors.ConfirmPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.ConfirmPassword}
                      </FormFeedback>
                    ) : null}
                  </InputGroup>

                  <div className="text-end">
                    <>
                      {/* {formSumitLoading ? ( */}
                      {/* <button
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                      Loading
                    </button> */}
                      {/* ) : ( */}
                      <Button
                        color="primary"
                        className="btn btn-primary btn-sm "
                        type="submit"
                      >
                        Submit
                      </Button>
                      {/* )} */}
                    </>
                  </div>
                </Form>
              </ModalBody>
            </div>
          </Modal>

          <SubscriptionModel
            modal={modal1}
            toggle={toggle}
            userListApi={userList}
            username={username}
            subscriptionUserId={userId}
            rolename={rolename}
          />
        </Card>
      </div>
    </div>
  )
}

DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
