import Pages404 from "pages/Utility/pages-404"
import React, { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { routesRoles } from "common/routesRoles"
import { routes } from "./path"

// Vendor Salesman Admin

// Create Inquiry for All

// Admin = User, Feedback, Inquiry List, Setting, Subscription, Plan + Vendor + Salesman
// Vendor = Color, Size, Category, TimeSlot, Product, Booking, Bill, Report
// Salesman = Check Available

const Authmiddleware = props => {
  const [RoleName, setRoleName] = useState("Admin")
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setRoleName(obj.roleName)
    }
  }, [props.success])

  // if (!localStorage.getItem("authUser")) {
  //   return (
  //     <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  //   )
  // }
  const { pathname } = useLocation()

  if (routesRoles[pathname]) {
    if (routesRoles[pathname]["role"]?.includes(RoleName)) {
      return <React.Fragment>{props.children}</React.Fragment>
    } else {
      // console.log(RoleName)
      if (RoleName == "Salesman") {
        return <Navigate to={routes.available} />
      }
      return (
        <React.Fragment>
          <Pages404 />
        </React.Fragment>
      )
    }
  }
}

export default Authmiddleware
