import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Collapse, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import readXlsxFile from "read-excel-file"
import chargeSample from "./GroomWellProduct.xlsx"
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"

let size = []
let categories = []
let color = []
let products = []

let categoriesError = []
let sizeError = []
let colorError = []
let productsError = []

let validationForUpload = []

let productvalidation = []
let sizevalidation = []
let colorvalidation = []
let categoryvalidation = []

import classnames from "classnames"

import CommonValidationTable from "./ComonValidataionTable"
import ProductValidationTable from "./ProductValidationTable"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { routes } from "routes/path"

function ImportProduct() {
  // document.title = `Import Product | ${localStorage.getItem("ShopName")}`
  const navigate = useNavigate()
  const { postApi } = useApi()

  const [validationError, setvalidationError] = useState({
    product: [],
    size: [],
    color: [],
    category: [],
  })

  useEffect(() => {
    productvalidation = []
    sizevalidation = []
    colorvalidation = []
    categoryvalidation = []
    size = []
    categories = []
    color = []
    products = []
    categoriesError = []
    sizeError = []
    colorError = []
    productsError = []
    validationForUpload = []
  }, [])

  const inputRef = useRef(null)
  const exelFile = useRef(null)

  const [category, setcategory] = useState([])
  const [colorArr, setcolorArr] = useState([])
  const [sizeArr, setsizeArr] = useState([])
  const [productArr, setproductArr] = useState([])
  const [flag, setflag] = useState(false)
  const [flagForApi, setflagForApi] = useState(false)
  const [validation, setvalidation] = useState([])
  const [flagUpload, setflagUpload] = useState(false)

  const handleClick = () => {
    setflagForApi(false)
    setcategory([])
    setcolorArr([])
    setsizeArr([])
    setproductArr([])
    // 👇️ open file input box on click of another element
    inputRef.current.click()
  }

  const handleDownloadExel = () => {
    setLoading(true)
    postApi("Product/export-products", null).then(({ data, status }) => {
      if (status === 200) {
        setLoading(false)
        var mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
        window.open(mediaType, "_blank")
      } else {
        setLoading(false)
        toast.error(data)
      }
    })
  }

  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(true)
  const [col3, setcol3] = useState(true)
  const [col4, setcol4] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleImport = async $event => {
    setLoading(true)
    setvalidationError({
      product: [],
      size: [],
      color: [],
      category: [],
    })
    categories = []
    size = []
    color = []
    products = []
    validationForUpload = []
    productvalidation = []
    sizevalidation = []
    colorvalidation = []
    categoryvalidation = []

    setflag(false)
    // categoriesError = []
    // sizeError = []
    // colorError = []
    // productsError = []

    const files = $event.target.files

    readXlsxFile(files[0]).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        let obj = {
          SrNo: rows[i][0],
          Name: rows[i][1] ? rows[i][1]?.toString() : null,
          ErrorMessage: "",
        }
        if (obj.Name) {
          categories.push(obj)
        }
      }
    })

    readXlsxFile(files[0], { sheet: "Size" }).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        let obj = {
          SrNo: rows[i][0],
          Name: rows[i][1] ? rows[i][1]?.toString() : null,
          ErrorMessage: "",
        }
        if (obj.Name) {
          size.push(obj)
        }
      }
    })

    readXlsxFile(files[0], { sheet: "Color" }).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        let obj = {
          SrNo: rows[i][0],
          Name: rows[i][1] ? rows[i][1]?.toString() : null,
          ErrorMessage: "",
        }
        if (obj.Name) {
          color.push(obj)
        }
      }
    })

    readXlsxFile(files[0], { sheet: "Products" }).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        let obj = {
          SrNo: rows[i][0],
          Name: rows[i][1] ? rows[i][1]?.toString() : null,
          Rent: rows[i][2],
          CategoryName: rows[i][3] ? rows[i][3]?.toString() : null,
          SizeName: rows[i][4] ? rows[i][4]?.toString() : "",
          ColorName: rows[i][5] ? rows[i][5]?.toString() : "",
          Code: rows[i][6] ? rows[i][6]?.toString() : null,
          Remarks: rows[i][7] == null ? "" : rows[i][7],
          ErrorMessage: "",
        }
        products.push(obj)
      }
    })

    setTimeout(() => {
      // categories.reduce((acc, current, index, array) => {
      //   categoriesError.push(current.Name)

      //   if (
      //     array.findIndex(item => item.Name === current.Name) !== index &&
      //     !acc.includes(current)
      //   ) {
      //     validationForUpload.push(current.SrNo)
      //     acc.push(current)
      //     current.ErrorMessage +=
      //       current.ErrorMessage == ""
      //         ? " Already exists."
      //         : " | Already exists."
      //   }

      //   if (current.ErrorMessage == "") {
      //     current.ErrorMessage = "N/A"
      //   } else {
      //     validationForUpload.push("Not Valid")
      //     categoryvalidation.push("Not Valid")
      //   }

      //   return acc
      // }, [])

      // size.reduce((acc, current, index, array) => {
      //   sizeError.push(current.Name)

      //   if (
      //     array.findIndex(item => item.Name === current.Name) !== index &&
      //     !acc.includes(current)
      //   ) {
      //     acc.push(current)
      //     validationForUpload.push(current.SrNo)

      //     current.ErrorMessage +=
      //       current.ErrorMessage == ""
      //         ? "Name already exists."
      //         : " | Name already exists."
      //   }

      //   if (current.ErrorMessage == "") {
      //     current.ErrorMessage = "N/A"
      //   } else {
      //     validationForUpload.push("Not Valid")
      //     sizevalidation.push("Not Valid")
      //   }

      //   return acc
      // }, [])

      // color.reduce((acc, current, index, array) => {
      //   colorError.push(current.Name)

      //   if (
      //     array.findIndex(item => item.Name === current.Name) !== index &&
      //     !acc.includes(current)
      //   ) {
      //     validationForUpload.push(current.SrNo)
      //     acc.push(current)
      //     current.ErrorMessage +=
      //       current.ErrorMessage == ""
      //         ? "Name already exists."
      //         : " | Name already exists."
      //   }

      //   if (current.ErrorMessage == "") {
      //     current.ErrorMessage = "N/A"
      //   } else {
      //     validationForUpload.push("Not Valid")
      //     colorvalidation.push("Not Valid")
      //   }

      //   return acc
      // }, [])

      products?.reduce((acc, current, index, array) => {
        if (current.Name == null) {
          current.Name = "--"
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "The name is required."
              : " | The name is required."
        }
        if (current.Rent == null) {
          current.Rent = "--"
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "The Rent is required."
              : " | The Rent is required."
        }

        if (current.Code == null) {
          current.Code = "--"
          current.ErrorMessage += "The code is required."
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "The code is required."
              : " | The code is required."
        }
        // if (
        //   current.CategoryName == null &&
        //   categoriesError.includes(current.CategoryName)
        // ) {
        //   current.Code = "--"
        //   current.ErrorMessage +=
        //     current.ErrorMessage == ""
        //       ? "The category is required."
        //       : " | The category is required."
        // }

        // if (!categoriesError.includes(current.CategoryName)) {
        //   current.ErrorMessage +=
        //     current.ErrorMessage == ""
        //       ? "category is not valid."
        //       : " | category is not valid."
        // }

        // if (
        //   !sizeError.includes(current.SizeName) &&
        //   current.SizeName != "N/A"
        // ) {
        //   current.ErrorMessage +=
        //     current.ErrorMessage == ""
        //       ? "size is not valid."
        //       : " | size is not valid."
        // }
        // if (
        //   !colorError.includes(current.ColorName) &&
        //   current.ColorName != "N/A"
        // ) {
        //   current.ErrorMessage +=
        //     current.ErrorMessage == ""
        //       ? "color is not valid."
        //       : " | color is not valid.."
        // }

        if (
          array.findIndex(item => item.Code === current.Code) !== index &&
          !acc.includes(current)
        ) {
          validationForUpload.push(current.SrNo)
          acc.push(current)
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "Code already exists."
              : " | Code already exists."
        }
        if (current.ErrorMessage == "") {
          current.ErrorMessage = "N/A"
        } else {
          validationForUpload.push("Not Valid")
          productvalidation.push("Not Valid")
        }

        return acc
      }, [])
      setvalidationError({
        ...validationError,
        product: productvalidation,
        size: sizevalidation,
        color: colorvalidation,
        category: categoryvalidation,
      })
      setcategory(categories)
      setcolorArr(color)
      setproductArr(products)
      setsizeArr(size)
      setLoading(false)
      document.getElementById("inputGroupFile").value = ""
    }, 2000)
    setflagUpload(true)
  }
  const t_col1 = () => {
    setcol1(!col1)
  }

  const t_col2 = () => {
    setcol2(!col2)
  }

  const t_col3 = () => {
    setcol3(!col3)
  }
  const t_col4 = () => {
    setcol4(!col4)
  }
  // const Loader = () => {
  //   return (
  //     <div
  //       style={{
  //         height: "auto",
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <svg viewBox="25 25 50 50" className="svg">
  //         <circle r="20" cy="50" cx="50" className="circle "></circle>
  //       </svg>
  //     </div>
  //   )
  // }

  const handleSubmit = () => {
    setflagForApi(true)
    var xyz = {
      createCategory: categories,
      createColor: color,
      createSize: size,
      createProducts: products,
    }
    setLoading(true)
    postApi("Product/import-products", {
      createCategory: categories,
      createColor: color,
      createSize: size,
      createProducts: products,
    }).then(({ data, status }) => {
      if (status == 200) {
        if (data) {
          toast.success("Successfully Created", {
            style: {
              fontSize: "15px",
            },
          })
          // navigate(routes.productList)
          setLoading(false)
          setproductArr(data?.ProductsError)
          // array.length > 1

          if (data.ProductsError.length > 1) {
            productvalidation.push({ yes: "true" })
            setflag(true)
            setvalidation(validation.push({ yes: "true" }))
          }
        }
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
        setLoading(false)
      }
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Card>
          {loading && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <CardBody>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <input
                type="file"
                name="file"
                ref={inputRef}
                className="custom-file-input"
                style={{ display: "none" }}
                id="inputGroupFile"
                required
                onChange={handleImport}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <a
                href={chargeSample}
                className="btn bg-info text-light mb-2"
                download="your file name"
                ref={exelFile}
                style={{ display: "none" }}
              >
                Download Template
              </a>
              <button
                type="button"
                className="btn btn-primary  btn-label"
                onClick={handleDownloadExel}
              >
                <i className="mdi mdi-download  label-icon"></i> Download
              </button>

              <button
                type="button"
                className="btn btn-light  btn-label"
                onClick={handleClick}
              >
                <i className="mdi mdi-upload  label-icon "></i> Upload
              </button>
              {!validationForUpload.length &&
              (category.length ||
                colorArr.length ||
                sizeArr.length ||
                productArr.length) &&
              !productvalidation.length ? (
                <button
                  type="button"
                  className="btn btn-success  btn-label"
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  <i className="bx bx-check-double label-icon"></i> Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-success  btn-label"
                  style={{ cursor: "not-allowed" }}
                >
                  <i className="bx bx-check-double label-icon"></i> Submit
                </button>
              )}
            </div>

            <div className="accordion mt-4" id="accordion">
              {flagUpload ? (
                <>
                  {categoryvalidation.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col1,
                            }
                          )}
                          type="button"
                          onClick={() => {
                            t_col1()
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Category
                        </button>
                      </h2>

                      <Collapse isOpen={col1} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading && (
                            <div className="loading-overlay is-active">
                              <span className="fas fa-spinner fa-3x fa-spin"></span>
                            </div>
                          )}
                          <CommonValidationTable category={category} />
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}

                  {sizevalidation.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col2,
                            }
                          )}
                          type="button"
                          onClick={t_col2}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Size
                        </button>
                      </h2>

                      <Collapse isOpen={col2} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading && (
                            <div className="loading-overlay is-active">
                              <span className="fas fa-spinner fa-3x fa-spin"></span>
                            </div>
                          )}
                          <>
                            <CommonValidationTable category={sizeArr} />
                          </>
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}

                  {colorvalidation.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col3,
                            }
                          )}
                          type="button"
                          onClick={t_col3}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Color
                        </button>
                      </h2>
                      <Collapse isOpen={col3} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading}
                          <>
                            <CommonValidationTable category={colorArr} />
                          </>
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}
                  {productvalidation.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col4,
                            }
                          )}
                          type="button"
                          onClick={t_col4}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Product
                        </button>
                      </h2>
                      <Collapse isOpen={col4} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading && (
                            <div className="loading-overlay is-active">
                              <span className="fas fa-spinner fa-3x fa-spin"></span>
                            </div>
                          )}
                          <>
                            {flag ? (
                              <CommonValidationTable category={productArr} />
                            ) : (
                              <ProductValidationTable category={productArr} />
                            )}
                          </>
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : null}
            </div>

            {!validationError.category.length &&
            !validationError.color.length &&
            !validationError.size.length &&
            !validationError.product.length &&
            flagUpload ? (
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Tbody>
                      <Tr>
                        <Th
                          className="text-center"
                          style={{
                            color: "#28A745",
                            fontSize: "20px",
                          }}
                        >
                          Your details have been thoroughly verified and are
                          ready for submission. Please click on Submit.
                        </Th>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </div>
            ) : null}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ImportProduct
