// /* eslint-disable react/no-unknown-property */
// import React, { useEffect, useState } from "react"
// import Breadcrumbs from "../components/Common/Breadcrumb"
// import {
//   Row,
//   Col,
//   Card,
//   CardBody,
//   Form,
//   Label,
//   Input,
//   Button,
//   CardTitle,
//   Container,
//   FormGroup,
//   FormFeedback,
//   CardSubtitle,
//   Table,
// } from "reactstrap"
// import { useFormik } from "formik"
// import * as Yup from "yup"
// import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
// import data from "../_mock/data.json"

// // function Demo() {
// //   const fetchJson = () => {
// //     fetch("", {
// //       headers: {
// //         "Content-Type": "application/json",
// //         Accept: "application/json",
// //       },
// //     })
// //       .then(function (response) {
// //         console.log(response)
// //         return response.json()
// //       })
// //       .then(function (myJson) {
// //         console.log(myJson)
// //       })
// //   }
// //   useEffect(() => {
// //     fetchJson()
// //   }, [])

// //   const validation = useFormik({
// //     enableReinitialize: true,

// //     initialValues: {
// //       Code: "",
// //       DeliveryDate: new Date().toISOString().slice(0, 10),
// //       ReturnDate: new Date().toISOString().slice(0, 10),
// //     },
// //     validationSchema: Yup.object({
// //       Code: Yup.string().required("Code is required"),
// //       DeliveryDate: Yup.string().required("Delivery Date is required"),
// //       ReturnDate: Yup.string().required("Return Date is required"),
// //     }),
// //     onSubmit: values => {},
// //   })

// //   return (
// //     <React.Fragment>
// //       <div className="page-content">
// //         <Container fluid={true}>
// //           <Breadcrumbs breadcrumbItem={"Available"} />
// //           <Card>
// //             <CardBody>
// //               <table
// //                 style={{
// //                   fontSize: 8,
// //                   width: "295px !important",
// //                   height: "425px !important",
// //                   borderCollapse: "collapse",
// //                   border: "0.1px solid black",
// //                 }}
// //                 id="section-to-print"
// //               >
// //                 <tbody>
// //                   <tr style={{ height: 52, borderBottom: "0.1px solid black" }}>
// //                     <td colSpan={2} align="center"></td>
// //                     <td
// //                       colSpan={4}
// //                       align="center"
// //                       style={{ verticalAlign: "top" }}
// //                     >
// //                       <div style={{ fontSize: 11 }}>
// //                         WhiteCore Technology LLP
// //                       </div>
// //                       <div style={{ fontSize: 8, marginTop: 3 }}>
// //                         428, Avadh viceroy, Sarthana Jakat Naka, Nana Varachha,
// //                         Surat-395006, Gujarat.
// //                       </div>
// //                       <div style={{ fontSize: 8, marginTop: 3 }}>
// //                         Mo: +91 8200478318 | +91 7800783090
// //                       </div>
// //                     </td>
// //                   </tr>
// //                   <tr
// //                     style={{
// //                       height: 15,
// //                       backgroundColor: "#dfdfdf",
// //                       fontWeight: "bold",
// //                       borderBottom: "0.1px solid black",
// //                       verticalAlign: "middle",
// //                     }}
// //                     align="center"
// //                   >
// //                     <td colSpan={4} style={{ borderRight: "none" }}>
// //                       Customer Details
// //                     </td>
// //                     <td
// //                       colSpan={2}
// //                       align="right"
// //                       style={{ borderLeft: "none" }}
// //                     >
// //                       બિલ નંબર: 65516
// //                     </td>
// //                   </tr>
// //                   <tr
// //                     style={{
// //                       height: 15,
// //                       verticalAlign: "top",
// //                       borderBottom: "0.1px solid black",
// //                     }}
// //                   >
// //                     <td colSpan={4} style={{ borderRight: "none" }}>
// //                       <div
// //                         style={{
// //                           fontWeight: "bold",
// //                           float: "left",
// //                           marginRight: 3,
// //                         }}
// //                       >
// //                         Name:
// //                       </div>
// //                       Lakum Nitin B.
// //                     </td>
// //                     <td
// //                       colSpan={2}
// //                       style={{ paddingLeft: 15, borderLeft: "none" }}
// //                       align="right"
// //                     >
// //                       <div style={{ fontWeight: "bold" }}>Date: 29/12/2023</div>
// //                     </td>
// //                   </tr>
// //                   <tr
// //                     style={{
// //                       height: 30,
// //                       verticalAlign: "top",
// //                       borderBottom: "0.1px solid black",
// //                     }}
// //                   >
// //                     <td colSpan={6}>
// //                       <div
// //                         style={{
// //                           fontWeight: "bold",
// //                           float: "left",
// //                           marginRight: 3,
// //                         }}
// //                       >
// //                         Address:
// //                       </div>
// //                       428, Avadh viceroy, Sarthana Jakat Naka, Nana Varachha,
// //                       Surat-395006, Gujarat.
// //                     </td>
// //                   </tr>
// //                   <tr
// //                     style={{
// //                       height: 15,
// //                       verticalAlign: "top",
// //                       borderBottom: "0.1px solid black",
// //                     }}
// //                   >
// //                     <td colSpan={6}>
// //                       <div
// //                         style={{
// //                           fontWeight: "bold",
// //                           float: "left",
// //                           marginRight: 3,
// //                         }}
// //                       >
// //                         Mo:
// //                       </div>
// //                       +91 8200478318 | +91 7800783090
// //                     </td>
// //                   </tr>
// //                   <tr
// //                     style={{
// //                       height: 15,
// //                       backgroundColor: "#dfdfdf",
// //                       fontWeight: "bold",
// //                       borderBottom: "0.1px solid black",
// //                     }}
// //                   >
// //                     <td style={{ width: 10, borderRight: "0.1px solid black" }}>
// //                       No.
// //                     </td>
// //                     <td style={{ width: 40, borderRight: "0.1px solid black" }}>
// //                       Code
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Name</td>
// //                     <td style={{ width: 40, borderRight: "0.1px solid black" }}>
// //                       Delivery
// //                     </td>
// //                     <td style={{ width: 40, borderRight: "0.1px solid black" }}>
// //                       Return
// //                     </td>
// //                     <td style={{ width: 50 }}>Rent</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>1</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>2</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>3</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>4</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>5</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>6</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>7</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>8</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>9</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15 }}>
// //                     <td style={{ borderRight: "0.1px solid black" }}>10</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       BD-AS-01
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>Blazer</td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td style={{ borderRight: "0.1px solid black" }}>
// //                       29/12/2023
// //                     </td>
// //                     <td>₹19,000.00</td>
// //                   </tr>
// //                   <tr style={{ height: 15, borderTop: "0.1px solid black" }}>
// //                     <td
// //                       rowSpan={5}
// //                       colSpan={3}
// //                       style={{
// //                         borderRight: "0.1px solid black",
// //                         borderBottom: "0.1px solid black",
// //                         verticalAlign: "top",
// //                       }}
// //                     >
// //                       Notes is a notetaking app developed by Apple Inc. It is
// //                       provided on their iOS.
// //                     </td>
// //                     <td
// //                       style={{
// //                         fontWeight: "bold",
// //                         borderRight: "0.1px solid black",
// //                         borderBottom: "0.1px solid black",
// //                       }}
// //                       colSpan={2}
// //                     >
// //                       Total Rent
// //                     </td>
// //                     <td
// //                       style={{
// //                         fontWeight: "bold",
// //                         borderBottom: "0.1px solid black",
// //                       }}
// //                     >
// //                       ₹19,000.00
// //                     </td>
// //                   </tr>
// //                   <tr style={{ height: 15, fontWeight: "bold" }}>
// //                     <td
// //                       colSpan={2}
// //                       style={{
// //                         borderRight: "0.1px solid black",
// //                         borderBottom: "0.1px solid black",
// //                       }}
// //                     >
// //                       Discount
// //                     </td>
// //                     <td style={{ borderBottom: "0.1px solid black" }}>
// //                       ₹9,000.00
// //                     </td>
// //                   </tr>
// //                   <tr style={{ height: 15, fontWeight: "bold" }}>
// //                     <td
// //                       colSpan={2}
// //                       style={{
// //                         borderRight: "0.1px solid black",
// //                         borderBottom: "0.1px solid black",
// //                       }}
// //                     >
// //                       Advance
// //                     </td>
// //                     <td style={{ borderBottom: "0.1px solid black" }}>
// //                       ₹9,000.00
// //                     </td>
// //                   </tr>
// //                   <tr style={{ height: 15, fontWeight: "bold" }}>
// //                     <td
// //                       colSpan={2}
// //                       style={{
// //                         borderRight: "0.1px solid black",
// //                         borderBottom: "0.1px solid black",
// //                       }}
// //                     >
// //                       Payble Amount
// //                     </td>
// //                     <td style={{ borderBottom: "0.1px solid black" }}>
// //                       ₹10,000.00
// //                     </td>
// //                   </tr>
// //                   <tr style={{ height: 15, fontWeight: "bold" }}>
// //                     <td
// //                       colSpan={2}
// //                       style={{
// //                         borderRight: "0.1px solid black",
// //                         borderBottom: "0.1px solid black",
// //                       }}
// //                     >
// //                       Deposit
// //                     </td>
// //                     <td style={{ borderBottom: "0.1px solid black" }}>
// //                       ₹10,000.00
// //                     </td>
// //                   </tr>
// //                   <tr>
// //                     <td colSpan={6} style={{ verticalAlign: "top" }}>
// //                       <div>
// //                         1. Notes is a notetaking app developed by Apple Inc. It
// //                         is provided on their iOS.
// //                       </div>
// //                       <div>
// //                         2. PadOS and macOS operating systems, the latter
// //                         starting with OS X Mountain Lion.
// //                       </div>
// //                       <div>
// //                         3. which can be synchronized between devices using
// //                         Apple's iCloud service.
// //                       </div>
// //                       <div>
// //                         4. Lorem ipsum dolor sit amet Deserunt ipsam ratione,
// //                         rerum expedita omnis maiores?
// //                       </div>
// //                       <div>
// //                         5. Lorem ipsum dolor sit amet consectetur adipisicing
// //                         elit. Quibusdam, corrupti?
// //                       </div>
// //                     </td>
// //                   </tr>
// //                 </tbody>
// //               </table>
// //             </CardBody>
// //           </Card>
// //         </Container>
// //       </div>
// //     </React.Fragment>
// //   )
// // }

// export default Demo

// let a = []

// const [obj, setobj] = useState([{ name: "" }])

// var a = [
//   {
//     name: " jadyeep",
//   },
// ]

// useEffect(() => {

//   if()
//   {
//     let a = obj
//     a.push = {

//     }
//     setobj(a)
//   }

// }, [])

// if (api.data == true) {
//   a.push = {
//     name: "sadhna",
//   }
// }

// import React, { useEffect } from "react"
// // import readXlsxFile from "read-excel-file"

// const Demo = async () => {

//   return (
//     <React.Fragment>
//       <div className="page-content" onClick={() => {}}>

//       </div>
//     </React.Fragment>
//   )
// }

// export default Demo

import useApi from "common/ApiMiddlewere"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Container, Table } from "reactstrap"

let testdata = [
  {
    fromData: "2023-06-01",
    toDate: "2023-06-01",
    test: "",
  },
  {
    fromData: "2023-06-01",
    toDate: "2023-06-02",
    test: "",
  },
  {
    fromData: "2023-06-03",
    toDate: "2023-06-05",
    test: "",
  },
  {
    fromData: "2023-06-02",
    toDate: "2023-06-03",
    test: "",
  },
  {
    fromData: "2023-06-01",
    toDate: "2023-06-03",
    test: "",
  },
  {
    fromData: "2023-06-07",
    toDate: "2023-06-09",
    test: "",
  },
  {
    fromData: "2023-06-06",
    toDate: "2023-06-07",
    test: "",
  },
  {
    fromData: "2023-06-05",
    toDate: "2023-06-06",
    test: "",
  },
  {
    fromData: "2023-06-05",
    toDate: "2023-06-07",
    test: "",
  },
  {
    fromData: "2023-06-03",
    toDate: "2023-06-04",
    test: "",
  },
  {
    fromData: "2023-06-09",
    toDate: "2023-06-12",
    test: "",
  },
  {
    fromData: "2023-06-12",
    toDate: "2023-06-12",
    test: "",
  },
]

function Demo() {
  const { postApi } = useApi()
  //   const [testData, settestData] = useState()
  const [loading, setloading] = useState(true)

  //   2023-06-01
  useEffect(() => {
    // let temp = [...testData]
    for (let index = 0; index < testdata.length; index++) {
      postApi("Booking/check-availablility", {
        productCode: "K-17",
        fromDate: testdata[index].fromData,
        toDate: testdata[index].toDate,
      }).then(({ data, status }) => {
        console.log(
          "payload",
          {
            productCode: "ca",
            fromDate: testdata[index].fromData,
            toDate: testdata[index].toDate,
          },
          "response",
          data
        )
        // console.log(data)

        if (data.Status === 1) {
          testdata[index].test = "available"
        } else if (data.Status === 2) {
          testdata[index].test = "Not available"
        } else {
          testdata[index].test = "list"
        }
      })
    }
    setTimeout(() => {
      setloading(false)
    }, 5000)
    // settestData(temp)
  }, [])

  if (loading) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>...loding</Container>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>From Date</th>
                  <th>To Date</th>

                  <th>Actual O/P</th>
                </tr>
              </thead>
              <tbody>
                {testdata &&
                  testdata.map(
                    ({ test, fromData, toDate }, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{fromData}</td>
                          <td>{toDate}</td>

                          <td>{test}</td>
                        </tr>
                      )
                    }
                  )}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Demo
