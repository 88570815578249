import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { map } from "lodash"

//Import Image
import { dateFormat } from "pages/Common"
import { useState } from "react"

const InvoiceDetail = props => {
  const { state } = useLocation() // navigate data
  const [invoiceDetails, setInvoiceDetails] = useState(state?.data)
  // const dispatch = useDispatch()

  const params = props.router.params
  // useEffect(() => {
  //   if (params && params.id) {
  //     dispatch(onGetInvoiceDetail(params.id))
  //   } else {
  //     dispatch(onGetInvoiceDetail(1)) //remove this after full integration
  //   }
  // }, [dispatch, onGetInvoiceDetail])

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div></div>
          <Row>
            <Col lg="2"></Col>
            <Col lg="8">
              <Card>
                <CardBody className="p-2">
                  <div className="invoice-title mb-4">
                    <h4 className="float-end font-size-56">
                      Bill No: {invoiceDetails.BillNo}
                    </h4>
                  </div>
                  <hr />
                  <Row>
                    <Col sm="3">
                      <address>
                        <strong>Address :</strong>
                        <br />
                        <React.Fragment>
                          <span>{invoiceDetails.CustomerAddress}</span>
                          <br />
                        </React.Fragment>
                      </address>
                    </Col>
                    <Col sm="9" className="text-sm-end">
                      <address>
                        <strong>Customer Details :</strong>
                        <br />
                        <span>{invoiceDetails.Name}</span>
                        <br />
                        <span>
                          Contact No :{" "}
                          {invoiceDetails.ContactNo1 ||
                            invoiceDetails.ContactNo2}
                        </span>
                      </address>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="9" className="mt-3">
                      <address className="mb-0">
                        <strong>Remarks :</strong>
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: invoiceDetails.Remarks,
                          }}
                        />
                      </address>
                    </Col>
                    <Col sm="3" className="mt-3 mb-0 text-sm-end">
                      <address className="mb-0">
                        <strong>Booking Date :</strong>
                        <br />
                        {dateFormat(
                          invoiceDetails.BillDate.split("T")[0],
                          "dd/MM/yyyy"
                        )}
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Product Detail</h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-nowrap" bordered>
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th style={{ width: "70px" }}>Product Code</th>
                          <th>Product Name</th>
                          <th style={{ width: "70px" }}>Delivery Date</th>
                          <th style={{ width: "70px" }} className="text-sm-end">
                            Return Date
                          </th>
                          <th style={{ width: "70px" }} className="text-sm-end">
                            Rent
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(invoiceDetails.ProductList, (item, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{item.ProductCode}</td>
                            <td>{item.ProductTitle}</td>
                            <td>
                              {dateFormat(
                                item.DeliveryDate.split("T")[0],
                                "dd/MM/yyyy"
                              )}
                            </td>
                            <td className="text-sm-end">
                              {dateFormat(
                                item.ReturnDate.split("T")[0],
                                "dd/MM/yyyy"
                              )}
                            </td>
                            <td className="text-sm-end">
                              <i className="mdi mdi-currency-inr"></i>
                              {item.Rent.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="5" className="border-0 text-end">
                            <strong>Total Rent</strong>
                          </td>
                          <td className="border-0 text-end">
                            {" "}
                            <i className="mdi mdi-currency-inr"></i>
                            <strong>
                              {invoiceDetails.TotalRent.toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="5" className="border-0 text-end">
                            <strong>Advance</strong>
                          </td>
                          <td className="border-0 text-end">
                            {" "}
                            <i className="mdi mdi-currency-inr"></i>
                            <strong>{invoiceDetails.Advance.toFixed(2)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="5" className="border-0 text-end">
                            <strong>Discount</strong>
                          </td>
                          <td className="border-0 text-end">
                            <strong>
                              {" "}
                              <i className="mdi mdi-currency-inr"></i>
                              {invoiceDetails.Discount.toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="5" className="border-0 text-end">
                            <strong>Payble Amount</strong>
                          </td>
                          <td className="border-0 text-end">
                            <strong className="m-0">
                              {" "}
                              <i className="mdi mdi-currency-inr"></i>
                              {(
                                parseInt(invoiceDetails.TotalRent) -
                                parseInt(invoiceDetails.Advance) -
                                parseInt(invoiceDetails.Discount)
                              ).toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="2"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)
