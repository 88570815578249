import Swal from "sweetalert"

const confirmDeleteInquiry = ({ title, text }) => {
  return Swal({
    title,
    text,
    icon: "warning",
    width: "50px", // set the width to 400px
    className: "swal-modal",
    heightAuto: false, // disable auto height
    buttons: {
      cancel: {
        text: "Cancel",
        value: false,
        visible: true,
        className: "btn btn-secondary btn-sm cancel-button ",
        closeModal: true,
      },
      confirm: {
        text: "Yes",
        value: true,
        visible: true,
        className: "btn btn-primary btn-sm confirm-button",
        closeModal: true,
      },
    },
    dangerMode: false,
  });

}

const DeleteModel = {
  confirmDeleteInquiry,
}

export default DeleteModel
