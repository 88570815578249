import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { useLocation, useNavigate } from "react-router-dom"

//import action
// import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"
import breadcrumbs from "../../common/Breadcrumbs"
import { routes } from "routes/path"
import Earning from "./earning"
import LineBar from "./Linebar"
import Transactions from "./Todo"
import IconComponent from "./IconComponent"

const Dashboard = props => {
  const navigate = useNavigate()
  const { getApi } = useApi()
  let { pathname } = useLocation()
  const state = useLocation()
  const [modal, setmodal] = useState(false)
  const [screeLoad, setscreeLoad] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [dailyActivity, setdailyActivity] = useState(null)
  const [RoleName, setRoleName] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )

  useEffect(() => {
    // setscreeLoad(true)
    if (RoleName.roleName == "Admin" || RoleName.roleName == "Vendor") {
      // getApi(`Booking/dashboard-widget`, "", RoleName).then(
      //   ({ data, status }) => {
      //     if (status === 200) {
      //       setscreeLoad(false)
      //       setdailyActivity(data)
      //     } else {
      //       setscreeLoad(false)
      //       toast.error(data, {
      //         style: {
      //           fontSize: "15px",
      //         },
      //       })
      //     }
      //   }
      // )
    }
  }, [props.sucess, state])

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(onGetChartsData("yearly"))
  // }, [dispatch])

  //meta title
  // document.title = `Dashboard | ${localStorage.getItem("ShopName")}`

  // if (screeLoad) {
  //   return <Loader />
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />
          <Row>
            <Col xl="12">
              <Row>
                {dailyActivity
                  ? dailyActivity.map((report, key) => (
                    <Col md="3" key={"_col_" + key}>
                      {/* <Card className="mini-stats-wid box-shadow"> */}
                      <Card>
                        <CardBody
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            {
                              report.Key == "TodaysBooking"
                                ? navigate(routes.bookingList)
                                : report.Key == "TodaysDelivery"
                                  ? navigate(routes.delivery)
                                  : report.Key == "TodaysReturn"
                                    ? navigate(routes.return)
                                    : report.Key == "TotalProductinCart"
                                      ? navigate(routes.cartlist)
                                      : report.Key == "TodaysInquiry"
                                        ? navigate(routes.inquiries)
                                        : report.Key == "PendingInquiry"
                                          ? navigate(routes.inquiries)
                                          : report.Key == "TotalUsers"
                                            ? navigate(routes.userList)
                                            : navigate(routes.dashboard)
                            }
                          }}
                        >
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.DisplayName}
                              </p>
                              <h4 className="mb-0">{report.Value}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className={`avatar-title rounded-circle`} style={{
                                backgroundColor: `${report.Key == "TodaysBooking"
                                  ? `#27374D`
                                  : report.Key == "TodaysDelivery"
                                    ? `#27374D`
                                    : report.Key == "TodaysReturn"
                                      ? `#27374D`
                                      : `#27374D`}`
                              }}>

                                <IconComponent report={report} />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))
                  : null}
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <LineBar dataColors='["--bs-success","--bs-primary", "--bs-danger"]' />
            </Col>
            <Col md={12}>
              <Earning setscreeLoad={setscreeLoad} />
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
