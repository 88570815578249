import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { Booking_status } from "_mock/Static_data"
import ProductViewTable from "../ProductViewTable"
import BookingTable from "./BookingTable"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import { font } from "./font"
import jsPDF from "jspdf"
import useApi from "common/ApiMiddlewere"
import { InvoiceHtml } from "./invoicePDF"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"

function RBooking() {
  const { postApi } = useApi()
  let { pathname } = useLocation()
  const [tableData, setTableData] = useState([])
  const [loader, setloader] = useState(false)
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productViewId, setProductViewId] = useState()
  const [status, setStatus] = useState(0)
  const [formDate, setFormDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10))
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchText, setSearchText] = useState("")

  const filterData = textFilter => {
    setSearchText(textFilter)
  }

  const pagePerSize = pageSize => {
    setRowsPerPage(pageSize)
  }

  // productView handler
  const handleProductView = bookingViewId => {
    if (bookingViewId) {
      setProductViewId(bookingViewId)
      toggle()
    }
  }

  const [IsPrinting, setIsPrinting] = useState(false)

  const handlePrintPDf = async id => {
    setloader(true)
    const html = await InvoiceHtml(id)

    const doc = new jsPDF({
      format: "a5",
      unit: "px",
      filters: ["ASCIIHexEncode"],
    })
    // var imgData = "data:image/jpeg;base64," + imagebase64
    doc.addFileToVFS("NotoSansGujarati-Regular.ttf", font)
    doc.addFont(
      "NotoSansGujarati-Regular.ttf",
      "NotoSansGujarati-Regular",
      "normal"
    )
    // doc.addImage(imgData, "PNG", 15, 17, 45, 35)
    doc.setFont("NotoSansGujarati-Regular") // set font
    doc.html(html, {
      async callback(doc) {
        await doc.autoPrint()
        setloader(false)
        window.open(doc.output("bloburl"), "_blank")
      },
      margin: [10, 10, 10, 10],
    })
  }

  const handleDownloadPdf = async id => {
    setloader(true)
    const html = await InvoiceHtml(id)

    const doc = new jsPDF({
      format: "a5",
      unit: "px",
      filters: ["ASCIIHexEncode"],
    })
    // var imgData = "data:image/jpeg;base64," + imagebase64
    doc.addFileToVFS("NotoSansGujarati-Regular.ttf", font)
    doc.addFont(
      "NotoSansGujarati-Regular.ttf",
      "NotoSansGujarati-Regular",
      "normal"
    )
    // doc.addImage(imgData, "PNG", 15, 17, 45, 35)
    doc.setFont("NotoSansGujarati-Regular") // set font
    doc.html(html, {
      async callback(doc) {
        setloader(false)
        await doc.save("invode.pdf")
      },
      margin: [10, 10, 10, 10],
    })
  }

  // product item view table toggle
  const toggle = () => {
    setModal(!modal)
  }

  // formDate & toDate
  const dateValue = (deliveryDate, returnDate) => {
    setFormDate(deliveryDate)
    setToDate(returnDate)
  }

  const statusValue = status => {
    if (status) {
      setStatus(status)
    }
  }

  // booking api useEffect
  useEffect(() => {
    bookingApi()
  }, [formDate, toDate, rowsPerPage, searchText, status])

  // bookingList api
  const bookingApi = () => {
    setIsLoading(true)
    postApi("Booking/booking-list", {
      fromDate: formDate ? formDate : new Date().toISOString().slice(0, 10),
      toDate: toDate ? toDate : new Date().toISOString().slice(0, 10),
      displayStart: 1,
      status: parseInt(status),
      pageSize: rowsPerPage,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.BookingList)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // status value get  displayName
  const statusName = id => {
    if (id) {
      const statusValue = Booking_status.filter(item => {
        if (item.Value == id) {
          return item.Value
        }
      })
      return statusValue[0].DisplayName
    }
  }

  // header and accessor
  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "5%",
      },
      {
        Header: "Name",
        accessor: "Name",
        // enableRowSpan: false
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "7%",
      },
      {
        Header: "Rent",
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Rent.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Advance",
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Advance.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks}`}
          >
            {row.original.Remarks.length > 30
              ? `${row.original.Remarks.substr(0, 30)}...`
              : row.original.Remarks == ""
              ? "N/A"
              : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: "Booking",
        accessor: "BookingDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.BookingDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.BookingDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
          </p>
        ),
      },
      {
        Header: "Status",
        accessor: "MinStatus",
        width: "6%",
        Cell: ({ row }) => {
          let statusValue = statusName(row.original.MinStatus)
          return <div>{statusValue}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li>
                <i
                  className=" fs-6 far fa-eye "
                  style={{ color: "#556ee6", cursor: "pointer" }}
                  id={`View${row.original.Id}`}
                  onClick={() => handleProductView(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target={`View${row.original.Id}`}
                >
                  View
                </UncontrolledTooltip>
              </li>

              <li>
                <i
                  className=" fs-6 fas fa-print btn-edit "
                  id={`Print${row.original.Id}`}
                  onClick={() => handlePrintPDf(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target={`Print${row.original.Id}`}
                >
                  Print
                </UncontrolledTooltip>
              </li>

              <li>
                <i
                  className=" fs-6 fas fa-download "
                  style={{ color: "#f1b44c", cursor: "pointer" }}
                  id={`Download${row.original.Id}`}
                  onClick={() => handleDownloadPdf(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  target={`Download${row.original.Id}`}
                >
                  Download
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )
  // if (loader) {
  //   return <Loader />
  // }

  document.title = `Report Booking | ${localStorage.getItem("ShopName")}`

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {loader && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />
          <Card>
            <CardBody>
              <BookingTable
                columns={columns}
                data={tableData}
                isGlobalFilter={true}
                customPageSize={10}
                dateValue={dateValue}
                isLoading={isLoading}
                filterData={filterData}
                pagePerSize={pagePerSize}
                statusValue={statusValue}
                className="custom-header-css"
              />
              <ProductViewTable
                productViewId={productViewId}
                modal={modal}
                toggle={toggle}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

RBooking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default RBooking
