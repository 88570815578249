import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SizeTableContainer from "../../../components/Common/SizeTableContainer"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"

import { useFormik } from "formik"
import DeleteModel from "common/deleteModel/deleteModel"

import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"

function TimeSlot() {
  document.title = `Time Slots | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { deleteApi, getApi, postApi, putApi } = useApi()

  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState(null)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formSumitLoading, setformSumitLoading] = useState(false)

  // time slots list api
  const api = () => {
    setIsLoading(true)
    getApi("TimeSlot/list", "").then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // time slots api useEffect
  useEffect(() => {
    api()
  }, [])

  // Delete timeSlot handler
  const handleDelete = id => {
    DeleteModel.confirmDeleteInquiry({
      title: "Are you sure? ",
      text: "You want to delete this timeSlot permanently? ",
    }).then(response => {
      if (response) {
        deleteApi(`TimeSlot/delete?id=${id}`).then(({ data, status }) => {
          if (status === 200) {
            const updatedArray = tableData
              .map(obj => (obj.Id !== id ? obj : null))
              .filter(Boolean)
            setTableData(updatedArray)
            toast.success("Sucessfully deleted", {
              style: {
                fontSize: "15px",
              },
            })
          } else {
            ErrorModel.errormodel({
              title: "Verify Timeslot",
              text: data,
            })
          }
        })
      }
    })
  }

  // cancel handle
  const handleCancle = () => {
    toggle()
  }

  // Update time slot handler
  const handlEditTimeSlot = editdata => {
    if (editdata) {
      setIsEdit(true)
      setModal(!modal)
      setEditData(editdata)
    }
  }

  // Create time slote handler
  const handleTimeslot = () => {
    setIsEdit(false)
    toggle()
  }

  // time slots Validations
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: isEdit ? editData.Id : "",
      Name: isEdit ? editData.Name : "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("The name is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      const { Name, id } = values

      isEdit
        ? putApi("timeslot/update", {
            id: id,
            name: Name,
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status === 200) {
              api()
              toggle()
              validation.handleReset()
              setIsEdit(false)
              toast.success("Sucessfully updated", {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toggle()
              setToast(true)
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
              setIsEdit(false)
              validation.handleReset()
            }
          })
        : postApi("timeslot/create", {
            name: Name,
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status === 200) {
              api()
              toggle()
              validation.handleReset()
              toast.success("Sucessfully created", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              validation.handleReset()
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
          })
    },
  })

  // popup box handler
  const toggle = () => {
    setModal(!modal)
    validation.handleReset()
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        accessor: "action",
        width: "7%",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li>
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handlEditTimeSlot(row.original)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`edittooltip${row.original.Id}`}
                >
                  Edit
                </UncontrolledTooltip>
              </li>

              <li>
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.Id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SizeTableContainer
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isTimeslotList={true}
              handleTimeslot={handleTimeslot}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="small"
              style={{ width: "350px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {isEdit ? "Update Timeslot" : "Create TimeSlot"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Name
                      </Label>
                      <Input
                        name="Name"
                        placeholder="Time slote"
                        type="text"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Name || ""}
                        invalid={
                          validation.touched.Name && validation.errors.Name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.Name && validation.errors.Name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Name}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <div className="text-end">
                      {isEdit ? (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          onClick={handleCancle}
                        >
                          Cancle
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          type="reset"
                        >
                          Reset
                        </Button>
                      )}
                      <>
                        {formSumitLoading ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary btn-sm "
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
TimeSlot.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TimeSlot
