import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import { dateFormat } from "pages/Common"
import { exportDataExcel } from "pages/ExportData"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SalesTable from "./SalesTable"

function Sales() {
  const { postApi } = useApi()
  const XLSX = require("xlsx")
  let { pathname } = useLocation()
  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formDate, setFormDate] = useState(
    new Date().toISOString().slice(0, 10)
  )
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10))
  const [exportData, setExportData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchText, setSearchText] = useState("")
  const [totalAmount, setTotalAmount] = useState(0.0)
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [TotalDiscount, setTotalDiscount] = useState(0.0)
  const [displayStart, setDisplayStart] = useState(0)
  const filterData = textFilter => {}

  // fromDate & toDate
  const dateValue = (deliveryDate, returnDate) => {
    setFormDate(deliveryDate)
    setToDate(returnDate)
  }

  // sales list api useEffect
  useEffect(() => {
    salesReportApi()
  }, [formDate, toDate, rowsPerPage, searchText, displayStart])

  // sales list api
  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
  }

  const salesReportApi = () => {
    setIsLoading(true)
    postApi("Report/sales-report", {
      fromDate: formDate ? formDate : new Date().toISOString().slice(0, 10),
      toDate: toDate ? toDate : new Date().toISOString().slice(0, 10),
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.SalesReport)
        setTotalAmount(data.TotalAmount)
        setTotalRecords(data.TotalRecords)
        setTotalDiscount(data.TotalDiscount)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  function writeDataToExcel() {
    let header = [
      "Bill No.",
      "Customer Name",
      "No. of Product",
      "Bill Date",
      "Discount",
      "Amount",
    ]
    const mainTitle = "Sales Report"
    const widthObj = [
      {
        Key: "B",
        Value: 18,
      },
      {
        Key: "C",
        Value: 17,
      },
      {
        Key: "D",
        Value: 10,
      },
      {
        Key: "E",
        Value: 12,
      },
      {
        Key: "F",
        Value: 12,
      },
    ]
    const extention = "Sales_Report.xlsx"
    postApi("Report/sales-report", {
      fromDate: formDate ? formDate : new Date().toISOString().slice(0, 10),
      toDate: toDate ? toDate : new Date().toISOString().slice(0, 10),
      displayStart: 1,
      pageSize: 10000,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setExportData(data.SalesReport)
        setTotalAmount(data.TotalAmount)
        setTotalRecords(data.TotalRecords)
        setTotalDiscount(data.TotalDiscount)
        let excelSheetData = []
        for (let index = 0; index < data.SalesReport.length; index++) {
          let dataObj = {
            BillNo: data.SalesReport[index].BillNo,
            CustomerName: data.SalesReport[index].CustomerName,
            NumberOfProduct: data.SalesReport[index].NumberOfProduct,
            BillDate: dateFormat(
              data.SalesReport[index].BillDate.split("T")[0],
              "dd/MM/yyyy"
            ),
            Discount: data.SalesReport[index].Discount,
            Amount: data.SalesReport[index].Amount,
          }
          excelSheetData.push(dataObj)
        }
        var fields = Object.keys(excelSheetData[0])
        var sheetData = excelSheetData.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName]
          })
        })
        sheetData.unshift(header)
        exportDataExcel(
          sheetData,
          mainTitle,
          widthObj,
          extention,
          data.TotalAmount,
          data.TotalDiscount
        )
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "5%",
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      // {
      //     Header: "ProductName",
      //     accessor: "Product Name",
      // },
      {
        Header: "No. of Product ",
        accessor: "NumberOfProduct",
        width: "5%",
      },

      {
        Header: "Bill Date",
        accessor: "BillDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.BillDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.BillDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "Discount",
        accessor: "Discount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Discount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Amount",
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Amount.toFixed(2)}
            </div>
          )
        },
      },
    ],
    [tableData]
  )

  document.title = `sales | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SalesTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              dateValue={dateValue}
              isLoading={isLoading}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              filterData={filterData}
              writeDataToExcel={writeDataToExcel}
              totalAmount={totalAmount}
              TotalDiscount={TotalDiscount}
              TotalRecords={TotalRecords}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Sales.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Sales
