import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  Modal,
  ModalBody,
  FormGroup,
  FormFeedback,
  Button,
  Form,
  Label,
  Input,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/user/profilepic.png"
import useApi from "common/ApiMiddlewere"
import { routes } from "routes/path"
import { toast } from "react-hot-toast"
import { useFormik } from "formik"
import DeleteModel from "common/deleteModel/deleteModel"

const ProfileMenu = () => {
  const navigate = useNavigate()
  const { getApi, putApi } = useApi()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [modal, setModal] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [RoleName, setRoleName] = useState("")
  const [username, setusername] = useState("")
  const [profilePic, setprofilePic] = useState()
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('authUser'))?.id)

  useEffect(() => {
    if (userId) {
      getApi(`api/User/details?id=${userId}`).then(({ data, status }) => {
        if (status === 200) {
          setusername(data?.username)
          setprofilePic(data?.url)
          setRoleName(data?.roleName)
          getDashboardData(data?.roleName)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }, [location.pathname])

  const getDashboardData = roleName => {
    if (roleName == "Vendor" || roleName == "Admin") {
      // getApi(`Booking/dashboard-widget`, "", RoleName).then(
      //   ({ data, status }) => {
      //     if (status === 200) {
      //       data.map(item => {
      //         if (item.Key === "TotalProductinCart") {
      //           setCartCount(item.Value)
      //         }
      //       })
      //     } else {
      //       toast.error(data, {
      //         style: {
      //           fontSize: "15px",
      //         },
      //       })
      //     }
      //   }
      // )
    }
  }

  const handleRefresh = () => {
    getDashboardData(RoleName)
    // navigate(routes.dashboard, {
    //   state: true,
    // })
  }

  const handleUserProfile = () => {
    getApi(`api/User/details?id=${JSON.parse(localStorage.getItem('authUser')).id}`).then(({ data, status }) => {
      if (status === 200) {
        navigate(routes.profile, {
          state: {
            data,
          },
        })
      }
    })
  }

  const handleChangePassword = () => {
    setModal(!modal)
    validation.handleReset()
  }

  const handleLogOut = () => {
    localStorage.clear()
    navigate("/login")
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    validationSchema: Yup.object({
      NewPassword: Yup.string().required("The new password is required"),
      ConfirmPassword: Yup.string()
        .required("The confirm password is required")
        .oneOf([Yup.ref("NewPassword")], "Passwords must match"),
    }),
    onSubmit: values => {
      if (values) {
        DeleteModel.confirmDeleteInquiry({
          title: "Are you sure? ",
          text: "You want to update password this user? ",
        }).then(response => {
          if (response == true) {
            putApi("User/update-password", {
              userId: userId,
              newPassword: values.ConfirmPassword,
            }).then(({ data, status }) => {
              if (status == 400) {
                toast.error(data, {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                toast.success("successfully updated", {
                  style: {
                    fontSize: "15px",
                  },
                })
                setModal(false)
              }
            })
          } else {
            setModal(true)
          }
        })
      }
    },
  })

  return (
    <React.Fragment>
      <div style={{ gap: "15px", display: "flex" }}>
        {(RoleName == "Admin" || RoleName == "Vendor") && (
          <div className="refresh_dashboard" onClick={() => handleRefresh()}>
            <i className="bx bx-sync refresh_icon" title="Refresh dashboard" />
          </div>
        )}
        {/* {RoleName == "Vendor" && (
          <div
            id="page-header-notifications-dropdown"
            className="refresh_dashboard btn header-item noti-icon position-relative"
            onClick={() => handleCart()}
          >
            <i className="bx bx-cart cart_icon" title="Cart" />
            <span className="badge bg-danger rounded-pill bubble_pill">
              {cartCount}
            </span>
          </div>
        )} */}

        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item "
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={profilePic ? profilePic : user1}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ms-2 me-1">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <div onClick={handleUserProfile} style={{ color: "black" }}>
              <DropdownItem>
                <i className="bx bx-user font-size-16 align-middle me-1" />
                <span>{"Profile"}</span>
              </DropdownItem>
            </div>
            {RoleName != "Salesman" && (
              <div style={{ color: "black" }} onClick={handleChangePassword}>
                <DropdownItem>
                  <i className="bx bx-lock-open font-size-16 align-middle me-1" />
                  {"Change Password"}
                </DropdownItem>
              </div>
            )}
            {RoleName == "Admin" && (
              <Link to={routes.setting} style={{ color: "black" }}>
                <DropdownItem>
                  <i className="bx bx-wrench font-size-16 align-middle me-1" />
                  {"Settings"}
                </DropdownItem>
              </Link>
            )}

            <div className="dropdown-divider" />



            {/* <Link to="/login" className="dropdown-item"> */}
            <div style={{ color: "black" }} onClick={() => handleLogOut()}>
              <DropdownItem>
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>{"Logout"}</span>
              </DropdownItem>
            </div>
            {/* </Link> */}
          </DropdownMenu>
        </Dropdown>
      </div>

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="small"
        style={{ width: "350px" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => setModal(!modal)} tag="h5">
            Change Password
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
              onReset={e => {
                e.preventDefault
                validation.handleReset()
                return false
              }}
            >
              <FormGroup className="mb-2">
                <Label htmlFor="validationCustom03" className="required">
                  New Password
                </Label>
                <Input
                  name="NewPassword"
                  placeholder="New Password"
                  type="password"
                  className="form-control-sm"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.NewPassword || ""}
                  invalid={
                    validation.touched.NewPassword &&
                      validation.errors.NewPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.NewPassword &&
                  validation.errors.NewPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.NewPassword}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <Label htmlFor="validationCustom03" className="required">
                Confirm Password
              </Label>
              <InputGroup className="mb-2">
                <Input
                  className="form-control-sm"
                  name="ConfirmPassword"
                  value={validation.values.ConfirmPassword || ""}
                  type={passwordShow ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.ConfirmPassword &&
                    !!validation.errors.ConfirmPassword
                  }
                />
                {/* <InputGroupAddon addonType="append"> */}
                <Button
                  onClick={() => setPasswordShow(!passwordShow)}
                  className="btn btn-sm"
                  style={{ border: "1px solid #ced4da" }}
                  color="light"
                >
                  {passwordShow ? (
                    <i className="mdi mdi-eye-outline" />
                  ) : (
                    <i className="mdi mdi-eye-off-outline" />
                  )}
                </Button>
                {/* </InputGroupAddon> */}
                {validation.touched.ConfirmPassword &&
                  validation.errors.ConfirmPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.ConfirmPassword}
                  </FormFeedback>
                ) : null}
              </InputGroup>

              <div className="text-end">
                <>
                  {/* {formSumitLoading ? ( */}
                  {/* <button
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                      Loading
                    </button> */}
                  {/* ) : ( */}
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm "
                    type="submit"
                  >
                    Submit
                  </Button>
                  {/* )} */}
                </>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment >
  )
}

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any,
// }

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile
//   return { error, success }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// )

export default ProfileMenu
