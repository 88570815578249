import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import * as Yup from "yup"

import { useFormik } from "formik"
import toast, { Toaster } from "react-hot-toast"
import { dateFormat } from "pages/Common"

import SubscriptionViewTable from "./SubscriptionViewTable"

import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SubscriptionModel from "components/Common/SubscriptionModel"

function Subscription() {
  document.title = `Subscriptions | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { getApi, postApi } = useApi()

  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [tableData, setTableData] = useState([])
  const [userId, setUserId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [planControls, setPlanControls] = useState([])
  const [userControls, setUserControls] = useState([])
  const [planData, setPlanData] = useState([])
  const [days, setDays] = useState()
  const [SubscriptionData, setSubscriptionData] = useState({
    userId: "",
    planId: "",
    amount: "",
    discount: '',
    totalAmount: '',
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    remarks: "",
  })
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchText, setSearchText] = useState("")
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [planAmount, setPlanAmount] = useState(0)
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [displayStart, setDisplayStart] = useState(0)

  // filterData for subscription
  const filterData = textFilter => {
    setSearchText(textFilter)
  }

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(((pagePerIndex * pageSize) - pageSize) + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)

  }
  // subscription list api
  const subscriptionApi = () => {
    setIsLoading(true)

    postApi("SubscriptionLogs/subscription-list", {
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.SubscriptionList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // subscription list api useEffect
  useEffect(() => {
    subscriptionApi()
    getApi("Plan/list", "").then(({ data, status }) => {
      if (status === 200) {
        setPlanData(data)
      }
    })
  }, [rowsPerPage, searchText, displayStart])

  // view button : Subscription View handler
  const handleProductView = userId => {
    if (userId) {
      setIsEdit(true)
      setUserId(userId)
      toggle()
    }
  }

  // Create subscription handler
  const handleSubscriptionClick = () => {
    // getApi("User/vendor-lookup").then(({ data, status }) => {
    //   if (status === 200) {
    //     setUserControls(data)
    //   }
    // })

    // getApi("Plan/plan-lookup").then(({ data, status }) => {
    //   if (status === 200) {
    //     setPlanControls(data)
    //   }
    // })

    setIsEdit(false)
    toggle()
  }

  // subscription Validations
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: SubscriptionData,

    validationSchema: Yup.object({
      userId: Yup.string().required("The User is required"),
      planId: Yup.string().required("The Plan is required"),
      amount: Yup.string().required("The Amount is required"),
      startDate: Yup.string().required("The StartDate is required"),
      endDate: Yup.string().required("The endDate is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (values) {
        toggle()
        postApi("SubscriptionLogs/renew-subscription", {
          userId: values.userId,
          planId: values.planId,
          amount: values.amount,
          discount: values.discount,
          startDate: values.startDate,
          endDate: values.endDate,
          remarks: values.remarks,
        }).then(({ data, status }) => {
          setformSumitLoading(false)
          if (status == 400) {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })

            validation.handleReset()
          } else {
            toast.success("Sucessfully created", {
              style: {
                fontSize: "15px",
              },
            })
            subscriptionApi()
            validation.handleReset()
            setSubscriptionData({
              userId: "",
              planId: "",
              amount: "",
              discount: '',
              totalAmount: "",
              startDate: new Date().toISOString().slice(0, 10),
              endDate: new Date().toISOString().slice(0, 10),
              remarks: "",
            })
          }
        })
      }
    },
  })
  // popup box handler
  const toggle = () => {
    setModal(!modal)
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "Username",
      },
      {
        Header: "Full Name",
        accessor: "FullName",
      },
      {
        Header: "Plan Name",
        accessor: "PlanName",
      },
      {
        Header: "Plan Amount",
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Amount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Discount",
        accessor: "Discount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div>
              <i className="mdi mdi-currency-inr"></i>
              {row.original.Discount == null || row.original.Discount == '' ? "0.00" : row.original.Discount.toFixed(2)}
            </div>
          )
        },
      },
      {
        Header: "Activation Date",
        accessor: "ActivationDate",
        width: "9%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ActivationDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.ActivationDate.split("T")[0],
                "dd/MM/yyyy"
              )}
          </p>
        ),
      },
      {
        Header: "Start Date",
        accessor: "StartDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.StartDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.StartDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "End Date",
        accessor: "EndDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.EndDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.EndDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "7%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li>
                <i
                  className=" fs-6 far fa-eye"
                  style={{ color: "#556ee6", cursor: "pointer" }}
                  id={`viewtooltip${row.original.Id}`}
                  onClick={() => handleProductView(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`viewtooltip${row.original.Id}`}
                >
                  View
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )
  // useId handler
  const handleUserId = e => {
    setSubscriptionData({
      ...SubscriptionData,
      userId: e.target.value,
    })
  }

  // start date handler
  const handleDate = e => {
    setSubscriptionData({
      ...SubscriptionData,
      startDate: e.target.value,
    })
    if (e.target.value) {
      setSubscriptionData({
        ...SubscriptionData,
        startDate: e.target.value,
        endDate: new Date(
          new Date(e.target.value).getTime() + days * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, 10),
      })
    }
  }

  const handleEndDate = e => {
    setSubscriptionData({
      ...SubscriptionData,
      startDate: e.target.value,
    })
    if (e.target.value) {
      setSubscriptionData({
        ...SubscriptionData,
        endDate: e.target.value,
        startDate: new Date(
          new Date(e.target.value).getTime() - days * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, 10),
      })
    }
  }

  const autoSelect = (e, name) => {
    if (name == "plan") {
      let TargetValue = e
      const filterData = planData.filter(item => item.Id == TargetValue)
      if (filterData.length) {
        setDays(filterData[0].Days - 1)
        setPlanAmount(filterData[0].Amount)
        setSubscriptionData({
          ...SubscriptionData,
          planId: filterData[0].Id,
          amount: filterData[0].Amount,
          discount: filterData[0].Discount == null ? '0' : filterData[0].Discount,
          totalAmount: filterData[0]?.Amount - filterData[0]?.Discount,
          startDate: new Date().toISOString().slice(0, 10),
          endDate: new Date(
            new Date().getTime() +
            (filterData[0].Days - 1) * 24 * 60 * 60 * 1000
          )
            .toISOString()
            .slice(0, 10),
        })
      }
    }
  }



  const handleDiscountChange = value => {
    value = value == "" ? 0 : value
    const discount = parseInt(value)
    validation.setFieldValue("discount", discount)
    validation.setFieldValue("totalAmount", planAmount - discount)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <TableContainer
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isSubscriptionList={true}
              handleSubscriptionClick={handleSubscriptionClick}
              customPageSize={10}
              isLoading={isLoading}
              filterData={filterData}
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              className="custom-header-css"
            />

            {isEdit ? (
              <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="small"
              >
                <div className="modal-content">
                  <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                    subscriptions
                  </ModalHeader>
                  <ModalBody>
                    <SubscriptionViewTable userId={userId} />
                  </ModalBody>
                </div>
              </Modal>
            ) : (
              <>
                {/* <Modal
                  isOpen={modal}
                  toggle={toggle}
                  centered={true}
                  size="small"
                  style={{ width: "350px" }}
                >
                  <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                      {"Create Subscription"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        onReset={e => {
                          e.preventDefault
                          validation.handleReset()
                          return false
                        }}
                      >
                        <Row>
                          <FormGroup className="mb-2">
                            <Label
                              htmlFor="validationCustom01"
                              className="required"
                            >
                              Users
                            </Label>
                            <Input
                              type="select"
                              bsSize="sm"
                              id="validationCustom01"
                              className="form-control-sm"
                              name="userId"
                              onChange={e => handleUserId(e)}
                              onBlur={validation.handleBlur}
                              value={validation.values.userId || ""}
                              invalid={
                                validation.touched.userId &&
                                  validation.errors.userId
                                  ? true
                                  : false
                              }
                            >
                              <option>Select User</option>
                              {userControls.map((item, index) => {
                                return (
                                  <option key={index} value={item.Key}>
                                    {item.Value}
                                  </option>
                                )
                              })}
                            </Input>
                            {validation.touched.userId &&
                              validation.errors.userId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.userId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup className="mb-2">
                            <Label
                              htmlFor="validationCustom01"
                              className="required"
                            >
                              Plans
                            </Label>
                            <Input
                              type="select"
                              bsSize="sm"
                              id="validationCustom01"
                              className="form-control-sm"
                              name="planId"
                              onChange={e => {
                                autoSelect(e.target.value, "plan")
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.planId || ""}
                              invalid={
                                validation.touched.planId &&
                                  validation.errors.planId
                                  ? true
                                  : false
                              }
                            >
                              <option>Select Plan</option>
                              {planControls.map((item, index) => {
                                return (
                                  <option key={index} value={item.Key}>
                                    {item.Value}
                                  </option>
                                )
                              })}
                            </Input>
                            {validation.touched.planId &&
                              validation.errors.planId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.planId}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom03"
                                className="required"
                              >
                                Amount
                              </Label>
                              <Input
                                name="amount"
                                disabled
                                placeholder="Amount"
                                type="text"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount || ""}
                                invalid={
                                  validation.touched.amount &&
                                    validation.errors.amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.amount &&
                                validation.errors.amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.amount}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom03"
                              >
                                Discount
                              </Label>
                              <Input
                                name="discount"
                                placeholder="Discount"
                                type="text"
                                className="form-control-sm"
                                // onChange={validation.handleChange}
                                onChange={(e) => {
                                  handleDiscountChange(e.target.value)
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.discount || ''}
                                invalid={
                                  validation.touched.discount &&
                                    validation.errors.discount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.discount &&
                                validation.errors.discount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.discount}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom03"
                              >
                                Total
                              </Label>
                              <Input
                                name="totalAmount"
                                placeholder="TotalAmount"
                                type="text"
                                disabled
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.totalAmount || 0}
                                invalid={
                                  validation.touched.totalAmount &&
                                    validation.errors.totalAmount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.totalAmount &&
                                validation.errors.totalAmount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.totalAmount}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="mb-2">
                              <Label
                                htmlFor="validation1Custom01"
                                className="required"
                              >
                                Start Date
                              </Label>
                              <Input
                                name="startDate"
                                placeholder="date placeholder"
                                type="date"
                                min={new Date().toISOString().slice(0, 10)}
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={e => {
                                  handleDate(e)
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.startDate}
                                invalid={
                                  validation.touched.startDate &&
                                    validation.errors.startDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.startDate &&
                                validation.errors.startDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.startDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-2">
                              <Label
                                htmlFor="validation1Custom01"
                                className="required"
                              >
                                End Date
                              </Label>
                              <Input
                                name="endDate"
                                placeholder="date placeholder"
                                min={validation.values.startDate}
                                type="date"
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={e => {
                                  handleEndDate(e)
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.endDate}
                                invalid={
                                  validation.touched.endDate &&
                                    validation.errors.endDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.endDate &&
                                validation.errors.endDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.endDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <FormGroup className="mb-2">
                            <Label htmlFor="validationCustom03">Remark</Label>
                            <Input
                              name="remarks"
                              placeholder="Type your remark here"
                              type="textarea"
                              className="form-control-sm"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.remarks || ""}
                              invalid={
                                validation.touched.remarks &&
                                  validation.errors.remarks
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.remarks &&
                              validation.errors.remarks ? (
                              <FormFeedback type="invalid">
                                {validation.errors.remarks}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Row>
                        <div className="text-end">
                          <Button
                            color="secondary"
                            className="btn btn-primary me-2 btn-sm "
                            type="reset"
                          >
                            Reset
                          </Button>

                          {formSumitLoading ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                            >
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              className="btn btn-primary btn-sm "
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      </Form>
                    </ModalBody>
                  </div>
                </Modal> */}
                <SubscriptionModel
                  modal={modal}
                  toggle={toggle}
                  subscriptionApi={subscriptionApi}
                />
              </>
            )}



          </CardBody>
        </Card>
      </div>
    </div>
  )
}

Subscription.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  onFilterChange: PropTypes.func,
}

export default Subscription
