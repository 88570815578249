import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { dateFormat } from "pages/Common"
import CartTable from "./CartTable"
import { toast } from "react-hot-toast"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import useApi from "common/ApiMiddlewere"

function CartListTable() {
  const navigate = useNavigate()
  const ref = useRef(null)
  let { pathname } = useLocation()
  const { deleteApi, getApi } = useApi()

  // useStates
  const [tableData, setTableData] = useState([])
  const [modal, setModal] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const cartListApi = () => {
    setIsLoading(true)
    getApi(`Booking/cart-list`, "").then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // cart list API useEffect
  useEffect(() => {
    cartListApi()
    // const intervalId = setInterval(cartListApi, 3000);
    // return () => clearInterval(intervalId);
  }, [])

  const handleDelete = id => {
    deleteApi(`Booking/delete-cart?id=${id}`).then(({ data, status }) => {
      if (status === 200) {
        toast.success("Successfully deleted", {
          style: {
            fontSize: "15px",
          },
        })
        const updatedArray = tableData
          .map(obj => (obj.Id !== id ? obj : null))
          .filter(Boolean)
        setTableData(updatedArray)
      }
    })
  }

  // Redirect to create booking
  const handleCreateBooking = customerName => {
    getApi(`Booking/cart-by-name?name=${customerName}`).then(
      ({ data, status }) => {
        if (status === 200) {
          navigate(routes.createbooking, {
            state: {
              data,
              booking: true,
            },
          })
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      }
    )
  }

  // Cart table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "Name",
      },
      {
        Header: "Salesman Name",
        accessor: "SalesmanUsername",
        // width: "8%"
      },
      {
        Header: "Product Code",
        accessor: "ProductCode",
        width: "6%",
      },
      {
        Header: "Delivery Date",
        accessor: "DeliveryDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                  row.original.DeliveryDate.split("T")[0],
                  "dd/MM/yyyy"
                )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return Date",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span>
                <span> {` (${row.original.ReturnTimeSlot})`}</span>
              </span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Create Date",
        accessor: "CreatedOn",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.CreatedOn == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.CreatedOn.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "Action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li>
                <i
                  className=" bx bx-task"
                  style={{
                    color: "#556ee6",
                    cursor: "pointer",
                    fontSize: "15px",
                    marginTop: "3px",
                  }}
                  id={`viewtooltip${row.original.Id}`}
                  onClick={() => handleCreateBooking(row.original.Name)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`viewtooltip${row.original.Id}`}
                >
                  Convert to Bill
                </UncontrolledTooltip>
              </li>
              <li>
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.Id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )
  document.title = `Cart | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <CartTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              className="custom-header-css"
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
CartListTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CartListTable
