import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Compressor from "compressorjs"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ColorsTable from "../../../components/Common/ColorsTable"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import CategoryTable from "./CategoryTable"
import uploadImage from "../../../assets/images/uploadImage.png"
import uploadImage1 from "../../../assets/images/uploadImage1.png"

function Categories() {
  document.title = `Categories | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { deleteApi, getApi, postApi, putApi } = useApi()
  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState("")
  const [tableData, setTableData] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [imageUrl1, setImageUrl1] = useState(uploadImage1)
  const [imageUrl2, setImageUrl2] = useState(uploadImage)
  const [imageUrl3, setImageUrl3] = useState(uploadImage)

  const filterData = textFilter => {
    setSearchText(textFilter == undefined ? '' : textFilter)
  }

  // category list api
  const categoryApi = () => {
    setIsLoading(true)
    getApi("api/Categories/all", searchText).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
        setTotalRecords(data.length)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // category list api useEffect
  useEffect(() => {
    categoryApi()
  }, [])

  // toggle handlers
  const toggle = () => {
    setModal(!modal)
    validation.handleReset()
  }

  // cancle handler
  const handleCancle = () => {
    toggle()
  }

  // Delete category handler
  const handleDelete = categoryId => {
    if (categoryId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this category permanently? ",
      }).then(response => {
        if (response == true) {
          deleteApi(`Category/delete?Id=${categoryId}`).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== categoryId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("successfully deleted", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Category",
                  text: data,
                })
              }
            }
          )
        }
      })
    }
  }

  // Update category handler
  const handleEditCategory = (categoryId) => {
    if (categoryId) {
      setCategoryId(categoryId)
      setIsEdit(true)
      setModal(!modal)
      getApi(`api/Categories/details?id=${categoryId}`).then(({ data, status }) => {
        if (status === 200) {
          setEditData(data)
          setImageUrl1(data.imageUrl1 || uploadImage1)
          setImageUrl2(data.imageUrl2 || uploadImage)
          setImageUrl3(data.imageUrl3 || uploadImage)
        } else {
          setIsLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }

  // Create category handler
  const handleCategoryClick = () => {
    setIsEdit(false)
    toggle()
  }

  // Categories Validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: isEdit ? editData.name : "",
      remark: isEdit ? editData.remarks : "",
      imageUrl1: isEdit ? editData.imageUrl1 : uploadImage1,
      imageUrl2: isEdit ? editData.imageUrl2 : uploadImage,
      imageUrl3: isEdit ? editData.imageUrl3 : uploadImage
    },
    validationSchema: Yup.object({
      name: Yup.string().required("The name is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (values) {
        putApi("api/Categories/edit", {
          id: categoryId,
          name: values.name,
          imageUrl1: isEdit ? values.imageUrl1?.split(",")[1] : '',
          imageUrl2: isEdit ? values.imageUrl2?.split(",")[1] : '',
          imageUrl3: isEdit ? values.imageUrl3?.split(",")[1] : '',
          remarks: values.remark
        }).then(({ data, status }) => {
          setformSumitLoading(false)

          if (status == 400) {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          } else {
            toast.success("successfully updated", {
              style: {
                fontSize: "15px",
              },
            })
            toggle()
            categoryApi()
            validation.handleReset()
          }
        })
      }
    },
  })

  const handleCodeChange = e => {
    validation.setFieldTouched("Code")
    validation.validateForm()
  }

  // Category table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Category",
        accessor: "name",
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip1">
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.id}`}
                  onClick={() => handleEditCategory(row.original.id)}
                >
                  <span className="mytext1">Edit</span>
                </i>
              </li>

              {/* <li>
                <i
                  id={`deletetooltip${row.original.Id}`}
                  className="fs-6 fas fa-trash-alt btn-delete"
                  onClick={() => handleDelete(row.original.id)}
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  autohide={true}
                  target={`deletetooltip${row.original.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </li> */}
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  // image toBase64 convert handle
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  // image1 select handle
  const handleDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0];
    setImageUrl1(URL.createObjectURL(selectedFile));
    validation.setFieldValue("imageUrl1", await toBase64(selectedFile));
  }

  // image2 select handle 
  const handleDrop1 = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setImageUrl2(URL.createObjectURL(selectedFile))
    validation.setFieldValue("imageUrl2", await toBase64(selectedFile))
  }

  // image3 select handle
  const handleDrop2 = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setImageUrl3(URL.createObjectURL(selectedFile))
    validation.setFieldValue("imageUrl3", await toBase64(selectedFile))
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <CategoryTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isCategoryList={false}
              handleCategoryClick={handleCategoryClick}
              customPageSize={10}
              className="custom-header-css"
              filterData={filterData}
              isLoading={isLoading}
              TotalRecords={totalRecords}
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="lg"
            // style={{ width: "1200px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {isEdit ? "Update Category" : "Create Category"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-2">
                          <Label htmlFor="validationCustom03" className="required">
                            Name
                          </Label>
                          <Input
                            name="name"
                            placeholder="Category name"
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Dropzone
                                onDrop={handleDrop}
                                onClick={handleDrop}
                                accept="image/*"
                                multiple={false}
                                id="ImageUrl1"
                                name="ImageUrl1"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <>
                                    <div className="d-flex flex-column align-items-start text-start">
                                      <Label
                                        htmlFor="validationCustom03"
                                        className=""
                                      >
                                        ImageUrl1
                                      </Label>
                                      <>
                                        <div className="position-relative overflow-hidden product-image" style={{
                                          width: "305px",
                                          height: "113px",
                                          border: "1px solid #ced4da",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "3px",
                                        }}>
                                          <input {...getInputProps()} />
                                          <img
                                            className="img-fluid"
                                            // style={{ height: "214px", width: "179px" }}
                                            src={imageUrl1 || uploadImage1}
                                            {...getRootProps()}
                                          />
                                        </div>
                                      </>
                                    </div>
                                  </>
                                )}
                              </Dropzone>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup className="mb-2">
                              <Label htmlFor="validationCustom03" className="">
                                Remark
                              </Label>
                              <Input
                                name="remark"
                                placeholder="Enter your remark"
                                type="textarea"
                                rows={5}
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.remark || ""}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={7}>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Dropzone
                                onDrop={handleDrop1}
                                onClick={handleDrop1}
                                accept="image/*"
                                multiple={false}
                                id="ImageUrl2"
                                name="ImageUrl2"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <>
                                    <div className="d-flex flex-column align-items-center text-center">
                                      <Label
                                        htmlFor="validationCustom03"
                                        className=""
                                      >
                                        ImageUrl2
                                      </Label>
                                      <>
                                        <div className="position-relative overflow-hidden product-image" style={{
                                          width: "173px",
                                          height: "270px",
                                          border: "1px solid #ced4da",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "3px",
                                        }}>
                                          <input {...getInputProps()} />
                                          <img
                                            className="img-fluid"
                                            // style={{ height: "214px", width: "179px" }}
                                            src={imageUrl2}
                                            {...getRootProps()}
                                          />
                                        </div>
                                      </>
                                    </div>
                                  </>
                                )}
                              </Dropzone>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Dropzone
                                onDrop={handleDrop2}
                                onClick={handleDrop2}
                                accept="image/*"
                                multiple={false}
                                id="profilePic"
                                name="profilePic"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <>
                                    <div className="d-flex flex-column align-items-center text-center">
                                      <Label
                                        htmlFor="validationCustom03"
                                        className=""
                                      >
                                        ImageUrl3
                                      </Label>
                                      <>
                                        <div className="position-relative overflow-hidden product-image" style={{
                                          width: "173px",
                                          height: "270px",
                                          border: "1px solid #ced4da",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "3px",
                                        }}>
                                          <input {...getInputProps()} />
                                          <img
                                            className="img-fluid"
                                            // style={{ height: "214px", width: "179px" }}
                                            src={imageUrl3}
                                            {...getRootProps()}
                                          />
                                        </div>
                                      </>
                                    </div>
                                  </>
                                )}
                              </Dropzone>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                    </Row>
                    <div className="text-end">
                      {isEdit ? (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm"
                          onClick={handleCancle}
                        >
                          Cancle
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          type="reset"
                        >
                          Reset
                        </Button>
                      )}

                      <>
                        {formSumitLoading ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary btn-sm "
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Categories.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Categories
