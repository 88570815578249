import React, { useState } from 'react';
import { Button, Card, Col, Container, Input, Label, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import logoDark from '../assets/images/fulllogo1.png';
import logo from '../assets/images/logo/logo.png';
import { routes } from 'routes/path';

const AllPlans = () => {
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState(1);

    const handlePriceChange = (e) => {
        setSelectedPlan(parseInt(e.target.value));
    };
    const handleButtonClick = () => {
        navigate('/inquiry', {
            state: {
                selectedPlan,
            },
        });
    };
    const titleStyle = { color: "black", fontWeight: "bold" }
    const plans = [
        {
            id: 1,
            name: 'Free',
            duration: '3 Month',
            price: 'Free',
            originalPrice: '3000/month',
            description: "Get your trial today it's absolutely free.",
        },
        {
            id: 2,
            name: 'Standard',
            duration: '12 Month',
            price: '14,999',
            originalPrice: '7000/month',
            description: 'Get your first year subscription. It is the most popular.',
        },
        {
            id: 3,
            name: 'Premium',
            duration: '24 Month',
            price: '24,999',
            originalPrice: '7000/month',
            description: "Get a premium plan and save big. It's an offer price for a limited time.",
        },
    ];

    return (
        <>
            <div>
                <nav className="navbar bg-white">
                    <a className="navbar-brand" style={{ marginLeft: '8px' }} href="#">
                        <span className="logo-lg m-2 ">
                            <img src={logoDark} width="200px" alt="" />
                        </span>
                    </a>
                    {/* <div className="navbar-brand" >
                        <spna className="fs-5 me-2" href="#">About</spna>
                        <spna className="fs-5 me-2"
                            href="#">Contact</spna>
                    </div> */}
                </nav>
                <div className="page-content">
                    <Container fluid>
                        <section className="container">
                            <Row className="d-flex  justify-content-center">
                                <Col className="" md={7}>
                                    <div className="d-flex justify-content-start">
                                        <div className="u-text u-text-1 mb-3" style={{ color: '#2c2b2bcf' }}>
                                            Our Plans
                                        </div>
                                    </div>
                                    <div style={{ fontSize: "15px", textAlign: "justify" }}>

                                        <div style={titleStyle}>Separate Logins:</div>
                                        <div className='our-plans-pra'>Effortlessly manage your shop using two distinct logins: one for vendors and the other for salesmen.</div>


                                        <div style={titleStyle}>Efficient Shop Setup:</div>
                                        <div className='our-plans-pra'>Import the necessary masters such as categories, colors, sizes, and products.</div>

                                        <div style={titleStyle}>Product Availability:</div>
                                        <div className='our-plans-pra'>Quickly check the availability of your products.</div>

                                        <div style={titleStyle}>Swift Bookings:</div>
                                        <div className='our-plans-pra'>Efficiently manage your cart for swift bookings.</div>

                                        <div style={titleStyle}>Printable Bills:</div>
                                        <div className='our-plans-pra'>Generate and download printable bills.</div>

                                        <div style={titleStyle}>Insightful Dashboard:</div>
                                        <div className='our-plans-pra'>Access valuable information on your dashboard, including today's bookings, deliveries, returns, and the total number of products in the cart.</div>

                                        <div style={titleStyle}>Washing Product:</div>
                                        <div className='our-plans-pra'>Enjoy the convenience of a washing product functionality.</div>

                                        <div style={titleStyle}>Diverse Data Reports:</div>
                                        <div className='our-plans-pra'>Access a variety of reports based on your data, including delivery, return, product history, sales, and salesman reports.</div>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    {plans.map((plan) => (
                                        <label htmlFor={`specifyColor${plan.id}`} key={plan.id}>
                                            <Card
                                                className={`${selectedPlan === plan.id ? 'card-border' : 'card-border1'}`}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <Row className="p-3">
                                                    <Col md={1}>
                                                        <input
                                                            className="custom-radio mt-2 specifyColor"
                                                            type="radio"
                                                            id={`specifyColor${plan.id}`}
                                                            name="price"
                                                            value={plan.id}
                                                            checked={selectedPlan === plan.id}
                                                            onChange={handlePriceChange}
                                                        />
                                                    </Col>
                                                    <Col md={7}>
                                                        <div>
                                                            <Label
                                                                htmlFor={`specifyColor${plan.id}`}
                                                                className="radio-lable text-black"
                                                                style={{ fontSize: '20px' }}
                                                            >
                                                                {plan.name}
                                                            </Label>
                                                        </div>
                                                        <div>{plan.description}</div>
                                                    </Col>
                                                    <Col md={4} className="mt-1">
                                                        <div className="text-end">
                                                            <h1 style={{ fontSize: '15px', color: 'black', fontWeight: '600', marginBottom: '0px', marginTop: '0px' }}>
                                                                {plan.price} <span style={{ fontSize: '12px', fontWeight: '500' }}>{`/${plan.duration}`}</span>
                                                            </h1>
                                                        </div>
                                                        <div className="text-end">
                                                            <s>
                                                                <i className="mdi mdi-currency-inr" />
                                                                <span style={{ fontSize: '12px', fontWeight: '500' }}>{plan.originalPrice}</span>
                                                            </s>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </label>
                                    ))}
                                    <Row style={{ paddingLeft: '11px', paddingRight: '11px' }}>
                                        <Button className="" style={{ background: '#91499D', border: 'none', fontSize: '15px', fontWeight: '500' }} onClick={handleButtonClick}>
                                            Select Plan
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default AllPlans;
