import { decryptData, encryptData } from "middleware/encryption"
import appConfig from "Api/app.config"
import { useEffect, useState } from "react"

// import { useState } from "react"
// import { decryptData,obj, encryptData } from "middleware/encryption"
// import appConfig from "Api/app.config"

const useApi = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const SkipRouts = ["api/PopularCategory/list", "api/PopularCategory/add", "api/PopularCategory/delete?id", "api/PopularCategory/edit", "api/PopularCategory/details?id", "api/PopularCategory/active-inactive?id"]


  useEffect(() => {
    localStorage.getItem("authuser")
  }, [])

  const getApi = async (route, searchText) => {

    try {
      setLoading(true)
      const obj = localStorage.getItem("authUser")
        ? JSON.parse(localStorage.getItem("authUser"))
        : ""
      const headers = new Headers()
      headers.append("Authorization", `Bearer ${obj.token}`)
      headers.append("accept", "*/*")
      headers.append("searchText", searchText)

      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      }
      const value = route.split("=")[1]

      // const encodedValue =
      //   value == undefined
      //     ? ""
      //     : encodeURIComponent(await encryptData(obj, value))
      const queryParameter = route
      // route.split("=")[0] + (encodedValue == "" ? "" : "=" + encodedValue)

      const response = await fetch(
        `${appConfig.BASE_URL}/${queryParameter}`,
        requestOptions
      )

      if (response.status === 200) {
        // const data = JSON.parse(
        //   await decryptData(obj, await response.text(), obj)
        // )
        const data = JSON.parse(await response.text());
        return { data, status: response.status }
      } else if (response.status === 401) {
        localStorage.clear()
        location.reload()
      } else {
        // const data = await decryptData(obj, await response.text())
        const data = await response.text();
        return { data, status: response.status }
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const postApi = async (route, body) => {

    try {
      setLoading(true)

      const obj = localStorage.getItem("authUser")
        ? JSON.parse(localStorage.getItem("authUser"))
        : ""

      const headers = new Headers()
      headers.append("accept", "*/*")
      headers.append("Authorization", `Bearer ${obj.token}`)
      headers.append("Content-Type", "application/json")

      const raw = JSON.stringify(body)

      const requestOptions = {
        method: "POST",
        headers: headers,
        // body: await encryptData(obj, raw),
        body: JSON.stringify(body),
        redirect: "follow",
      }

      const response = await fetch(
        `${appConfig.BASE_URL}/${route}`,
        requestOptions
      )

      if (response.status === 200) {
        // const data = JSON.parse(
        //   route === "Product/export-products"
        //     ? await response.text()
        //     : await decryptData(obj, await response.text())
        // )
        const data = JSON.parse(await response.text());
        return { data, status: response.status }
      } else if (response.status === 401) {
        localStorage.clear()
        location.reload()
      } else {
        // const data = await decryptData(obj, await response.text())
        const data = await response.text();
        return { data, status: response.status }
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const putApi = async (route, body) => {

    const obj = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : ""
    var headers = new Headers()
    headers.append("accept", "*/*")
    headers.append("Authorization", `Bearer ${obj.token}`)
    headers.append("Content-Type", "application/json")

    var requestOptions = {
      method: "PUT",
      headers: headers,
      body: body == "" || body == undefined ? "" : JSON.stringify(body),
      // body:
      //   body == "" || body == undefined
      //     ? ""
      //     : await encryptData(obj, JSON.stringify(body)),

      redirect: "follow",
    }

    let res = await fetch(
      `${appConfig.BASE_URL}/${route}`,
      requestOptions
    ).then(async response => {
      if (response.status === 200) {
        return {
          // data: JSON.parse(await decryptData(obj, await response.text())),
          data: JSON.parse(await response.text()),
          status: response.status,
        }
      } else if (response.status === 401) {
        localStorage.clear()
        location.reload()
      } else {
        return {
          // data: await decryptData(obj, await response.text()),
          data: await response.text(),
          status: response.status,
        }
      }
    })
    return res
  }

  const deleteApi = async route => {

    const obj = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : ""
    var headers = new Headers()
    headers.append("accept", "*/*")
    headers.append("Authorization", `Bearer ${obj.token}`)

    var requestOptions = {
      method: "DELETE",
      headers: headers,
      redirect: "follow",
    }

    var value = route.split("=")[1]
    value =
      value == undefined
        ? ""
        : encodeURIComponent(await encryptData(obj, value))
    var queryParameter = route // route.split("=")[0] + (value == "" ? "" : "=" + value)

    let res = fetch(
      `${appConfig.BASE_URL}/${queryParameter}`,
      requestOptions
    ).then(async response => {
      if (response.status === 200) {
        return {
          data: JSON.parse(await response.text()),
          // data: JSON.parse(await decryptData(obj, await response.text())),
          status: response.status,
        }
      } else if (response.status === 401) {
        localStorage.clear()
        location.reload()
      } else {
        return {
          data: await response.text(),

          // data: await decryptData(obj, await response.text()),
          status: response.status,
        }
      }
    })
    return res
  }

  const activeInavtiveApi = async route => {
    const obj = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : ""
    var myHeaders = new Headers()
    myHeaders.append("accept", "*/*")
    myHeaders.append("Authorization", `Bearer ${obj.token}`)

    var value = route.split("=")[1]
    value =
      value == undefined
        ? ""
        : encodeURIComponent(await encryptData(obj, value))
    var queryParameter = route.split("=")[0] + (value == "" ? "" : "=" + value)

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }

    let res = fetch(
      `${appConfig.BASE_URL}/${queryParameter}`,
      requestOptions
    ).then(async response => {
      if (response.status === 200) {
        return {
          data: JSON.parse(await decryptData(obj, await response.text())),
          status: response.status,
        }
      } else if (response.status === 401) {
        localStorage.clear()
        location.reload()
      } else {
        return {
          data: await decryptData(obj, await response.text()),
          status: response.status,
        }
      }
    })
    return res
  }

  return {
    loading,
    error,
    getApi,
    postApi,
    putApi,
    deleteApi,
    activeInavtiveApi,
  }
}

export default useApi

// const getApi = async (route, searchText) => {
//   const obj = localStorage.getItem("authUser")
//     ? JSON.parse(localStorage.getItem("authUser"))
//     : ""
//   var headers = new Headers()
//   headers.append("Authorization", `Bearer ${obj.Token}`)
//   headers.append("accept", "*/*")
//   headers.append("searchText", searchText)

//   var requestOptions = {
//     method: "GET",
//     headers: headers,
//     redirect: "follow",
//   }

//   var value = route.split("=")[1]
//   value = value == undefined ? "" : encodeURIComponent(await encryptData(obj,value))
//   var queryParameter = route.split("=")[0] + (value == "" ? "" : "=" + value)

//   let res = await fetch(
//     `${appConfig.BASE_URL}/${queryParameter}`,
//     requestOptions
//   ).then(async response => {
//     if (response.status === 200) {
//       return {
//         data: JSON.parse(await decryptData(obj,await response.text())),
//         status: response.status,
//       }
//     } else {
//       return {
//         data: await decryptData(obj,await response.text()),
//         status: response.status,
//       }
//     }
//   })
//   return res
// }

// const postApi = async (route, body) => {
//   const obj = localStorage.getItem("authUser")
//     ? JSON.parse(localStorage.getItem("authUser"))
//     : ""
//   var headers = new Headers()
//   headers.append("accept", "*/*")
//   headers.append("Authorization", `Bearer ${obj?.Token}`)
//   headers.append("Content-Type", "application/json")

//   var raw = JSON.stringify(body)

//   var requestOptions = {
//     method: "POST",
//     headers: headers,
//     body: await encryptData(obj,raw),
//     redirect: "follow",
//   }

//   let res = await fetch(`${appConfig.BASE_URL}/${route}`, requestOptions).then(
//     async response => {
//       if (response.status === 200) {
//         return {
//           data: JSON.parse(
//             route === "Product/export-products"
//               ? await response.text()
//               : await decryptData(obj,await response.text())
//           ),
//           status: response.status,
//         }
//       } else if (response.status === 401) {
//         localStorage.clear()
//         location.reload()
//       } else {
//         return {
//           data: await decryptData(obj,await response.text()),
//           status: response.status,
//         }
//       }
//     }
//   )
//   return res
// }

// const putApi = async (route, body) => {
//   const obj = localStorage.getItem("authUser")
//     ? JSON.parse(localStorage.getItem("authUser"))
//     : ""
//   var headers = new Headers()
//   headers.append("accept", "*/*")
//   headers.append("Authorization", `Bearer ${obj.Token}`)
//   headers.append("Content-Type", "application/json")

//   var requestOptions = {
//     method: "PUT",
//     headers: headers,
//     body:
//       body == "" || body == undefined
//         ? ""
//         : await encryptData(obj,JSON.stringify(body)),
//     redirect: "follow",
//   }

//   let res = await fetch(`${appConfig.BASE_URL}/${route}`, requestOptions).then(
//     async response => {
//       if (response.status === 200) {
//         return {
//           data: JSON.parse(await decryptData(obj,await response.text())),
//           status: response.status,
//         }
//       } else if (response.status === 401) {
//         localStorage.clear()
//         location.reload()
//       } else {
//         return {
//           data: await decryptData(obj,await response.text()),
//           status: response.status,
//         }
//       }
//     }
//   )
//   return res
// }

// const deleteApi = async route => {
//   const obj = localStorage.getItem("authUser")
//     ? JSON.parse(localStorage.getItem("authUser"))
//     : ""
//   var headers = new Headers()
//   headers.append("accept", "*/*")
//   headers.append("Authorization", `Bearer ${obj.Token}`)

//   var requestOptions = {
//     method: "DELETE",
//     headers: headers,
//     redirect: "follow",
//   }

//   var value = route.split("=")[1]
//   value = value == undefined ? "" : encodeURIComponent(await encryptData(obj,value))
//   var queryParameter = route.split("=")[0] + (value == "" ? "" : "=" + value)

//   let res = fetch(
//     `${appConfig.BASE_URL}/${queryParameter}`,
//     requestOptions
//   ).then(async response => {
//     if (response.status === 200) {
//       return {
//         data: JSON.parse(await decryptData(obj,await response.text())),
//         status: response.status,
//       }
//     } else if (response.status === 401) {
//       localStorage.clear()
//       location.reload()
//     } else {
//       return {
//         data: await decryptData(obj,await response.text()),
//         status: response.status,
//       }
//     }
//   })
//   return res
// }

// const activeInavtiveApi = async route => {
//   const obj = localStorage.getItem("authUser")
//     ? JSON.parse(localStorage.getItem("authUser"))
//     : ""
//   var myHeaders = new Headers()
//   myHeaders.append("accept", "*/*")
//   myHeaders.append("Authorization", `Bearer ${obj.Token}`)

//   var value = route.split("=")[1]
//   value = value == undefined ? "" : encodeURIComponent(await encryptData(obj,value))
//   var queryParameter = route.split("=")[0] + (value == "" ? "" : "=" + value)

//   var requestOptions = {
//     method: "PUT",
//     headers: myHeaders,
//     redirect: "follow",
//   }

//   let res = fetch(
//     `${appConfig.BASE_URL}/${queryParameter}`,
//     requestOptions
//   ).then(async response => {
//     if (response.status === 200) {
//       return {
//         data: JSON.parse(await decryptData(obj,await response.text())),
//         status: response.status,
//       }
//     } else if (response.status === 401) {
//       localStorage.clear()
//       location.reload()
//     } else {
//       return {
//         data: await decryptData(obj,await response.text()),
//         status: response.status,
//       }
//     }
//   })
//   return res
// }

// export { getApi, postApi, putApi, deleteApi, activeInavtiveApi }
